import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilityMethodsService } from '@conpulse-web/conpulse';
import {
  UserService,
  NotificationService,
  ChatService,
  defaultProfilePhoto,
  ChatRoom,
  ChatSoundType,
  TemplateEditorMode,
  BlogEditorMode,
  DeleteConfirmationComponent,
  CONSTANTS,
  ConfigService,
  ASSETS,
} from '@conpulse-web/core';
import { CommonDialogComponent } from 'libs/conpulse/src/lib/components/common-dialog/common-dialog.component';
import { Subscription } from 'rxjs';
import { AddThreadDialogComponent } from './add-thread-dialog/add-thread-dialog.component';
import { I18NextPipe } from 'angular-i18next';
import { trim } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'conpulse-web-chat-room-list',
  templateUrl: './chat-room-list.component.html',
  styleUrls: ['./chat-room-list.component.scss'],
})
export class ChatRoomListComponent implements OnInit, OnDestroy {
  @Output() conversationClickEvent = new EventEmitter();
  @Input() chatRoomId: string;
  conversationList = [];
  conversationPayload = {
    page: 0,
    search: '',
    total: 0,
    limit: 15,
    startDate: '',
    endDate: '',
  };
  conversationId = ''
  @Input() isPrivate = true;
  @Input() projectId = '';
  isLoading: boolean = false;
  isChatListLoading: boolean = false;
  isNewChatOpen: boolean = false;
  newChatPayload = {
    page: 0,
    search: '',
    total: 0,
    limit: 15,
  };
  search: string = '';
  newChatList = [];
  defaultProfilePhoto = defaultProfilePhoto;
  roomUnreadCountSubscription: Subscription | null = null;
  onlineStatusSubscription: Subscription | null = null;
  blockedUserSubscription: Subscription | null = null;
  currentUser;
  currentChatRoom: ChatRoom = {} as ChatRoom;
  CONSTANTS = CONSTANTS;
  notFoundImage: string;
  isSearchEnabled = false;

  constructor(
    private userService: UserService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private utilityService: UtilityMethodsService,
    private dialog: MatDialog,
    private readonly i18nextPipe: I18NextPipe,
    private configService: ConfigService,
    private router: ActivatedRoute
  ) {
    this.router.queryParams.subscribe((params) => {
      if (params?.startDate && params?.endDate) {
        this.conversationPayload = { ...this.conversationPayload, startDate: params?.startDate, endDate: params?.endDate };
      }
      if (params?.conversationId) {
        this.conversationId = params.conversationId
      }
    });
    this.notFoundImage = `${this.configService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
  }

  ngOnInit() {
    this.getConversationList();
    if (!this.userService.currentUserInformation) {
      this.userService.getCurrentUserInformation().subscribe();
    }
    this.currentUser = this.userService.currentUserInformation;
    this.chatService.getChatMessage().subscribe((data) => {
      const conversationDetails = this.conversationList.find((conversation) => conversation._id === data.roomId);
      if (conversationDetails) {
        conversationDetails.messagesInfo.latestMessage = data;
        if (this.currentChatRoom._id !== conversationDetails._id) {
          this.chatService.refreshRoomUnreadCount(conversationDetails._id);
          this.chatService.refreshUnreadCount();
        }
      }
    });
    this.roomUnreadCountSubscription = this.chatService.roomUnreadCount$.subscribe({
      next: ({ roomId, count, latestMessage }) => {
        const conversation = this.conversationList.find((conversation) => conversation._id === roomId);
        if (conversation) {
          conversation.unreadCount = count;
          if (latestMessage) conversation.latestMessage = latestMessage;
          if (roomId !== this.currentChatRoom._id) {
            this.utilityService.playChatSound(ChatSoundType.INCOMING);
          }
        }
      },
    });
    this.onlineStatusSubscription = this.chatService.onlineStatus$.subscribe({
      next: ({ userId, status }) => {
        const conversationIndex = this.conversationList.findIndex((conversation) => conversation.chatProfile._id === userId);
        if (conversationIndex > -1) {
          this.conversationList[conversationIndex].chatProfile.onlineStatus = status;
        }
      },
    });
    this.blockedUserSubscription = this.chatService.getBlockedUserDetails().subscribe((data) => {
      if (data?.chatSettingsId) {
        const foundIndex = this.newChatList.findIndex((member) => member._id === data?.chatProfile?._id);
        if (foundIndex > -1) {
          this.newChatList[foundIndex]['chatSettingId'] = data?.chatSettingsId;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.conversationList.length) this.closeNewChat();
  }

  ngOnDestroy() {
    this.roomUnreadCountSubscription?.unsubscribe();
    this.onlineStatusSubscription?.unsubscribe();
    this.blockedUserSubscription?.unsubscribe();
  }

  /**
   * Fetch Room list
   */
  getConversationList() {
    this.isLoading = true;
    this.chatService.getConversationList({ ...this.conversationPayload, projectId: this.projectId, isPrivate: this.isPrivate }).subscribe(
      (data) => {
        if (data.data[0]?.conversationList) this.conversationList.push(...data.data[0].conversationList);
        else this.conversationList = [];
        this.conversationPayload.page = data.data[0]?.paginationData?.page || 0;
        this.conversationPayload.total = data.data[0]?.paginationData?.total || 0;
        this.isLoading = false;
        if (this.conversationPayload.page === 0 && this.conversationList.length && !this.currentChatRoom?._id) {
          this.currentChatRoom = this.conversationId ? this.conversationList.find((conv) => conv._id === this.conversationId) : this.conversationList[0];
          this.onConversationClick(this.currentChatRoom);
        }
      },
      (error) => {
        this.isLoading = false;
        this.notificationService.openErrorSnackBar(error?.message || error);
      }
    );
  }

  /**
   * Get New chat members List
   */
  getNewMembersChat() {
    this.isLoading = true;
    this.chatService.getNewChatMembers(this.newChatPayload).subscribe({
      next: (data) => {
        this.newChatList.push(...data.data[0].users);
        this.newChatPayload.page = data.data[0].page || 0;
        this.newChatPayload.total = data.data[0].totalCount || 0;
        this.isLoading = false;
      },
      error: (error) => {
        this.notificationService.openErrorSnackBar(error?.message || error);
      },
    });
  }

  /**
   * To fetch next set of list
   */
  onScroll() {
    if (this.isNewChatOpen) {
      if (this.newChatList.length < this.newChatPayload.total) {
        this.newChatPayload.page++;
        this.getNewMembersChat();
      }
    } else {
      if (this.conversationList.length < this.conversationPayload.total) {
        this.conversationPayload.page++;
        this.getConversationList();
      }
    }
  }

  /**
   * Emits the selected conversationId
   * @param conversation Unique Id of the conversation
   */
  onConversationClick(conversation) {
    this.currentChatRoom = conversation;
    if (this.isNewChatOpen) {
      this.userService.getChatRoomByMembers(conversation?._id).subscribe(
        (data) => {
          conversation = {
              chatProfile: { name: conversation?.name, picture: conversation?.picture, _id: conversation?._id },
              isPrivate: data?.data?.isPrivate,
              isBlocked: conversation?.isBlocked,
              chatSettingsId: conversation?.chatSettingId,
              _id: data?.data?._id,
          };
          this.conversationClickEvent.emit(conversation);
          this.closeNewChat();
        },
        (error) => {
          this.notificationService.openErrorSnackBar(error.message);
        }
      );
    } else {
      this.conversationClickEvent.emit(conversation);
    }
  }

  /**
   * Search Event Handler
   */
  applySearch() {
    this.isSearchEnabled = !!this.search;
    if (this.isNewChatOpen) {
      this.newChatPayload.search = trim(this.search);
      this.newChatPayload.page = 0;
      this.newChatList = [];
      this.getNewMembersChat();
    } else {
      this.conversationPayload.search = trim(this.search);
      this.conversationPayload.page = 0;
      this.conversationList = [];
      this.getConversationList();
    }
  }

  /**
   * Opens New chat listing
   */
  openNewChatListing(addThread = false) {
    if (addThread) {
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: '500px',
        disableClose: true,
        data: {
          showFooterButtons: true,
          cancelButtonName: 'Cancel',
          submitButtonName: 'Add',
          dialogTitle: 'Add New Chat',
          dialogBody: AddThreadDialogComponent,
          initialSubmitButtonDisabled: true,
          parentData: {
            projectId: this.projectId,
            conversation: this.currentChatRoom,
            type: BlogEditorMode.ADD,
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.conversationList = [];
          this.conversationPayload.search = '';
          this.conversationPayload.page = 0;
          this.getConversationList();
        }
      });
    } else {
      this.isNewChatOpen = true;
      this.search = '';
      this.newChatPayload.search = '';
      this.newChatPayload.page = 0;
      this.newChatList = [];
      this.getNewMembersChat();
    }
  }

  /**
   * Closes new chat listing
   */
  closeNewChat() {
    this.isNewChatOpen = false;
    this.search = '';
    this.conversationList = [];
    this.conversationPayload.search = '';
    this.conversationPayload.page = 0;
    this.getConversationList();
  }

  /**
   * Refresh conversation List
   */

  refreshConversationList() {
    this.conversationList = [];
    this.conversationPayload.search = '';
    this.conversationPayload.page = 0;
    this.getConversationList();
  }

  clearSearch() {
    this.search = '';
    this.newChatPayload.search = this.search;
    this.newChatPayload.page = 0;
    this.conversationPayload.page = 0;
    this.newChatList = [];
    this.conversationList = [];
    this.conversationPayload.search = this.search;
    this.isNewChatOpen ? this.getNewMembersChat() : this.getConversationList();
  }

  /**
   * Edit/Deletes the thread
   */
  triggerEditDeleteRoom(event, type) {
    event.stopPropagation();
    if (type === BlogEditorMode.EDIT) {
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        width: '500px',
        disableClose: true,
        data: {
          showFooterButtons: true,
          cancelButtonName: 'Cancel',
          submitButtonName: 'Edit',
          dialogTitle: 'Edit Thread Name',
          dialogBody: AddThreadDialogComponent,
          initialSubmitButtonDisabled: true,
          parentData: {
            projectId: this.projectId,
            conversation: this.currentChatRoom,
            type: BlogEditorMode.EDIT,
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.refreshConversationList();
        }
      });
    } else {
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        disableClose: true,
        width: '450px',
      });
      dialogRef.componentInstance.title = 'Discard changes';
      dialogRef.componentInstance.message = 'Are you sure you want to Delete the Thread?';
      dialogRef.componentInstance.acceptanceText = 'Yes';
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.isLoading = true;
          this.chatService.updateWorspaceChatRoom(this.currentChatRoom._id, this.currentChatRoom.roomName, false).subscribe(
            (data) => {
              this.refreshConversationList();
              this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform('Conversation Room deleted successfully'));
            },
            (error) => {
              this.notificationService.openErrorSnackBar(this.i18nextPipe.transform(error.message));
              this.isLoading = false;
            }
          );
        }
      });
    }
  }

  /**
   * Get profile logo color
   */
  getClass(index) {
    return this.utilityService.getClass(index);
  }
}
