import { EventEmitter, Inject, Injectable } from '@angular/core';

import {
  AccountDetails,
  AccountsDrawer,
  CommonTableData,
  ConsultingFirm,
  FirmBillingDrawer,
  RfpMilestone,
  RfpSelectionCriterion,
  Risk,
  TableData,
} from '../interfaces';
import { BaseApiService } from './base.api';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminRoleChangeStatus, clientAccountTypeForQuery } from '../constants';
import { get } from 'lodash-es';

const adminRoutes = {
  // RFP Settings Management
  rfpMilestones: 'admin/rfpSettings/milestones',
  rfpSelectionCriteria: 'admin/rfpSettings/selectionCriteria',
  // Consulting Firms
  consultingFirms: 'admin/consultingFirms',
  suggestFirm: 'admin/market-exploration/suggest-company',
  approveOrReject: 'admin/market-exploration/suggest-company',
  improveoCompanyDetails: 'improveo/company/',
  currencyConversion: 'currency-conversion',
  companyById: 'company/details/{companyId}',
  account: '{clientOrFirm}/account-info/{clientId}',
  updateAccount: '{clientOrFirm}/account-info',
  currencyManagement: 'currency-management',
  projectCurrency: 'project-currency',
  getCurrencyList: 'currency-list',
  demographyChildList: '/industries-capabilites/child/{parentId}',
  invoiceBilling: 'client/invoice/billing-info',
  firmInvoiceBilling: 'firm/invoice/billing-info',
  notificationContentTemplates: 'notification-templates',
  legalEntityList: 'admin/legal-entities/list',
  changeRequestedEntityList: 'admin/change-req/legal-entity',
  billingInfoChangeRequest: 'admin/legal-entity/approveOrReject',
  fileSettings: 'admin/messaging/file-settings',
  risk: 'admin/risk',
  clientOnboardingStatus: '/admin/{clientId}/client-status',
  createCustomerInStripe: '/admin/{clientId}/stripe',
  createLegalEntityInStripe: 'admin/firm/{firmId}/legal-entity/{legalEntityId}/stripe',
  clientAdminSearchRequest: 'client/role-change',
  adminChangeRequestStatus: 'client/{requestId}/role-change/status',
  users: 'active-user/list/{id}',
  roleChangeByAdmin: 'admin/client/{clientId}/role-change',
  notificationCategories: 'admin/notification-categories',
  importConversion: 'admin/sync-conversion',
  addKysQuestionaire: 'admin/customization/questionnaire/template',
  cloneKysQuestionaire: 'admin/customization/questionnaire/duplicate-template',
  addKyesQuestions: 'admin/customization/questionnaire',
  reorderOrDelete: 'admin/customization/questionnaire/{id}',
  templateLanguage: 'admin/questionnaire/template-language',
  templateBasedOnLanguage: 'admin/questionnaire/template',
  linkQuestionnaire: 'admin/legal-entity/questionnaire',
};

const improveoIntegrationRoutes = {
  searchCompanies: 'improveo/companies',
};

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private accountDrawer$ = new BehaviorSubject<AccountsDrawer>({} as AccountsDrawer);
  private accountPageReload$ = new BehaviorSubject<{ reload: boolean; type: string }>({ reload: false, type: '' });
  public stripeBaseUrl: string;
  private contractsEditButton = new EventEmitter(false);
  private openAccountDrawer: EventEmitter<string> = new EventEmitter();
  private reloadBillingDetails$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  firmBillingDrawer$ = new BehaviorSubject<FirmBillingDrawer>({} as FirmBillingDrawer);
  constructor(private baseApi: BaseApiService, @Inject('AppEnvironment') private environmentConfig) {
    this.stripeBaseUrl = environmentConfig?.stripePortalUrl;
  }

  /**
   * Sets Contract Visibility
   * @param  data visibility
   */
  setContractEditVisibility(data: boolean) {
    this.contractsEditButton.emit(data);
  }

  /**
   * To fetch Contract Visibility
   */
  getContractEditVisibility(): Observable<any> {
    return this.contractsEditButton.asObservable();
  }

  setFirmDrawer(data: FirmBillingDrawer) {
    this.firmBillingDrawer$.next(data);
  }

  getFirmDrawer(): Observable<any> {
    return this.firmBillingDrawer$.asObservable();
  }

  /**
   *
   * @param  data visibility
   */
  openAccountDrawerCfProfile(data: string) {
    this.openAccountDrawer.emit(data);
  }

  /**
   * To fetch Contract Visibility
   */
  getCfProfileDrawerState(): Observable<any> {
    return this.openAccountDrawer.asObservable();
  }

  /**
   * Sets CGN List loading to subject variable
   * @param  data loading data for list
   */
  setAccountDrawerState(data: AccountsDrawer) {
    this.accountDrawer$.next(data);
  }

  /**
   * To fetch CGN List Loading status
   */
  getAccountDrawer(): Observable<any> {
    return this.accountDrawer$.asObservable();
  }

  resetAccountDrawer() {
    this.accountDrawer$.next({} as AccountsDrawer);
  }

  reloadTriggerAccountsSection(reload: boolean, type: string) {
    return this.accountPageReload$.next({ reload, type });
  }

  reloadAccountSection() {
    return this.accountPageReload$.asObservable();
  }

  triggerReloadBillingTab(reload: boolean) {
    return this.reloadBillingDetails$.next(reload);
  }

  reloadBillingTab() {
    return this.reloadBillingDetails$.asObservable();
  }

  /**
   * Retrieves list of RFP milestones created by super/client admin
   * @param values Table Data
   * @returns List of RFP milestones created by super admin
   */
  getRfpMilestonesList(values: TableData, isSuperAdmin: boolean) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.rfpMilestones}?${params.toString()}`);
  }

  /**
   * Gets data about a specific RFP milestone
   * @param rfpMilestoneId Unique ID of the RFP milestone
   * @returns RFP milestone data
   */
  getRfpMilestone(rfpMilestoneId: string) {
    return this.baseApi.httpGet(`${adminRoutes.rfpMilestones}/${rfpMilestoneId}`);
  }

  /**
   * Creates/updates a super/client admin RFP milestone
   * @param rfpMilestone RFP milestone data
   * @returns Created/updated super admin RFP milestone
   */
  createOrUpdateSuperAdminRfpMilestone(rfpMilestone: RfpMilestone) {
    return this.baseApi.httpPost(`${adminRoutes.rfpMilestones}`, rfpMilestone);
  }

  /**
   * Deletes an existing super/client admin RFP milestone by its ID
   * @param rfpMilestoneId Unique ID of the RFP milestone
   * @returns
   */
  deleteSuperAdminRfpMilestone(rfpMilestoneId: string) {
    return this.baseApi.httpDelete(`${adminRoutes.rfpMilestones}/${rfpMilestoneId}`);
  }

  /**
   * Retrieves list of RFP selection criteria created by super/client admin
   * @param values Table Data
   * @returns List of RFP selection criteria created by super admin
   */
  getRfpSelectionCriteriaList(values: TableData, isSuperAdmin: boolean) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.rfpSelectionCriteria}?${params.toString()}`);
  }

  /**
   * Gets data about a specific RFP selection criterion
   * @param rfpSelectionCriterionId Unique ID of the RFP selection criterion
   * @returns RFP selection criterion datatune
   */
  getRfpSelectionCriterion(rfpSelectionCriterionId: string) {
    return this.baseApi.httpGet(`${adminRoutes.rfpSelectionCriteria}/${rfpSelectionCriterionId}`);
  }

  /**
   * Creates/updates a super/client admin RFP selection criterion
   * @param rfpSelectionCriterion RFP selection criterion data
   * @returns Created/updated super admin RFP selection criterion
   */
  createOrUpdateSuperAdminRfpSelectionCriterion(rfpSelectionCriterion: RfpSelectionCriterion) {
    return this.baseApi.httpPost(`${adminRoutes.rfpSelectionCriteria}`, rfpSelectionCriterion);
  }

  /**
   * Deletes an existing super/client admin RFP milestone by its ID
   * @param rfpSelectionCriterionId Unique ID of the RFP milestone
   * @returns
   */
  deleteSuperAdminRfpSelectionCriterion(rfpSelectionCriterionId: string) {
    return this.baseApi.httpDelete(`${adminRoutes.rfpSelectionCriteria}/${rfpSelectionCriterionId}`);
  }

  /**
   * Retrieves list of consulting firms for super admin
   * @param values Table Data
   * @returns Admin Consulting Firms List
   */
  getAdminConsultingFirmsList(values: TableData) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.consultingFirms}?${params.toString()}`);
  }

  /**
   * Gets list of Improveo companies for auto-complete list
   * @param search Search key
   * @param limit Page limit (default 10)
   * @returns List of improveo companies for auto-complete list
   */
  getImproveoCompaniesAutoComplete(search: string, page: number, limit: number = 10) {
    const params = new URLSearchParams();
    if (search?.length) params.append('search', search);
    params.append('page', `${page}`);
    params.append('limit', `${limit}`);
    params.append('isAdminAutocomplete', 'true');
    return this.baseApi.httpGet(`${improveoIntegrationRoutes.searchCompanies}?${params.toString()}`);
  }

  /**
   * Creates a new consulting firm with details from Improveo
   * @param firmDetails New consulting firm details
   * @returns Consulting firm creation response
   */
  createConsultingFirm(firmDetails: ConsultingFirm) {
    const payload = {
      referenceId: firmDetails.referenceId,
      firmPartnerFirstName: firmDetails.firmPartnerFirstName,
      firmPartnerLastName: firmDetails.firmPartnerLastName,
      firmPartnerEmail: firmDetails.firmPartnerEmail,
      languageId: firmDetails.languageId,
    };
    return this.baseApi.httpPost(`${adminRoutes.consultingFirms}`, payload);
  }

  /**
   * Deletes an existing consulting firm with it's unique ID
   * @param consultingFirmId Unique ID of the consulting firm
   * @returns Delete response
   */
  deleteConsultingFirm(consultingFirmId: string) {
    return this.baseApi.httpDelete(`${adminRoutes.consultingFirms}/${consultingFirmId}`);
  }

  /**
   * Activate/Deactive an existing consulting firm with it's unique ID
   * @param consultingFirmId Unique ID of the consulting firm
   * @param isActive Active/Deactive status
   * @returns Status response
   */
  activateOrDeactivateFirm(consultingFirmId: string, isActive: boolean, type: number) {
    return this.baseApi.httpPut(`${adminRoutes.consultingFirms}/${consultingFirmId}`, { isActive, type });
  }

  changeOnboardingStatus(consultingFirmId: string, status: string, type: number) {
    return this.baseApi.httpPut(`${adminRoutes.consultingFirms}/${consultingFirmId}`, { status, type });
  }

  /**
   * get admin suggest list
   */
  getAdminSuggestList(values: TableData) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.suggestFirm}?${params.toString()}`);
  }

  /**
   * Approve/reject consulting firm
   */
  approveOrRejectConsultingFirm(values) {
    return this.baseApi.httpPut(`${adminRoutes.approveOrReject}/${values['_id']}`, values);
  }

  /**
   * get company details from improveo
   */
  getCompanyDetails(id) {
    return this.baseApi.httpGet(`${adminRoutes.improveoCompanyDetails}${id}`);
  }

  /**
   * To create currency conversion
   * @param values conversion details
   * @returns conversion status
   */
  saveCurrencyConversion(values) {
    const url = adminRoutes.currencyConversion;
    return this.baseApi.httpPost(url, values);
  }

  /**
   * To get list of conversions
   */
  getCurrencyConversions(values) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('isArchived', `${get(values, 'isArchived', 'false')}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.currencyConversion}?${params.toString()}`);
  }

  /**
   * To get conversion by unique id
   */
  getConversionData(id) {
    return this.baseApi.httpGet(`${adminRoutes.currencyConversion}/${id}`);
  }

  loadCountryList(values) {
    const params = new URLSearchParams();
    if (values?.search) params.append('search', values.search);
    return this.baseApi.httpGet(`${adminRoutes.getCurrencyList}?${params.toString()}`);
  }

  getManagementCountryList(values) {
    const params = new URLSearchParams();
    if (values?.page) params.append('page', `${values?.page}`);
    if (values?.limit) params.append('limit', `${values?.limit}`);
    if (values?.search) params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.currencyManagement}?${params.toString()}`);
  }

  getProjectCurrency({ search }: { search: string }) {
    const params = new URLSearchParams();
    if (search) params.append('search', `${search}`);
    return this.baseApi.httpGet(`${adminRoutes.projectCurrency}?${params.toString()}`);
  }

  addCurrency(values) {
    const params = new URLSearchParams();
    params.append('countryId', values);
    return this.baseApi.httpPost(`${adminRoutes.currencyManagement}?${params.toString()}`);
  }

  deleteCurrency(values) {
    const params = new URLSearchParams();
    params.append('currencyId', values);
    return this.baseApi.httpDelete(`${adminRoutes.currencyManagement}?${params.toString()}`);
  }
  /**
   * Get company by Id
   */
  getCompanyById(companyId: string) {
    const url = adminRoutes.companyById.replace('{companyId}', companyId);
    return this.baseApi.httpGet(url);
  }

  getAccountDetailsForClients(clientId: string, isClient: boolean) {
    const url = adminRoutes.account.replace('{clientId}', clientId).replace('{clientOrFirm}', isClient ? 'client' : 'firm');
    return this.baseApi.httpGet(url);
  }

  updateAccountDetailsForClients(type: string, accountDetails: object, isClient: boolean) {
    const params = new URLSearchParams();
    params.append('tabName', clientAccountTypeForQuery[type]);
    const url = adminRoutes.updateAccount.replace('{clientOrFirm}', isClient ? 'client' : 'firm');
    return this.baseApi.httpPost(`${url}?${params.toString()}`, accountDetails);
  }
  /**
   * To get invoice billing list
   * @param clientId unique Id of the client
   * @returns
   */
  getInvoiceBilling(clientId, page, limit, search) {
    const params = new URLSearchParams();
    params.append('search', search);
    params.append('page', page);
    params.append('limit', limit);
    const url = `${adminRoutes.invoiceBilling}/${clientId}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * To get invoice billing list
   * @param clientId unique Id of the client
   * @returns
   */
  getAllInvoiceBilling(page, limit, search, isClient = false) {
    const params = new URLSearchParams();
    params.append('search', search);
    params.append('page', page);
    params.append('limit', limit);
    params.append('isClient', isClient.toString());
    const url = `${adminRoutes.firmInvoiceBilling}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  createOrUpdateInvoiceBilling(values) {
    const url = adminRoutes.invoiceBilling;
    return this.baseApi.httpPost(url, values);
  }

  getNotificationContentTemplates(values: any) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    if (values?.search) params.append('search', `${values?.search}`);
    if (values?.language) params.append('language', `${values?.language}`);
    const url = `${adminRoutes.notificationContentTemplates}?${params.toString()}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * Gets notification template details based on templateId and languageId
   * @param templateId
   * @param languageId
   * @returns
   */
  getNotificationTemplate(templateId: string, languageId: string): Observable<any> {
    const url = `${adminRoutes.notificationContentTemplates}/${templateId}/${languageId}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * Create when template not available in a language/ update the existing template in a language
   * @param values
   * @returns
   */
  createOrUpdateNotificationTemplate(values): Observable<any> {
    const url = `${adminRoutes.notificationContentTemplates}`;
    return this.baseApi.httpPut(url, values);
  }

  /**
   * Get Firm Details by Id
   * @param firmId Unique Id of firm
   * @returns
   */
  getFirmById(firmId: string): Observable<any> {
    const url = `firm/${firmId}`;
    return this.baseApi.httpGet(url);
  }

  /**
   * get pending legal entity list of all CF
   */
  getLegalEntityList(data) {
    const params = new URLSearchParams();
    params.append('search', data?.search);
    params.append('page', data?.page);
    params.append('limit', data?.limit);
    return this.baseApi.httpGet(`${adminRoutes.legalEntityList}?${params.toString()}`);
  }

  /**
   * Get the changes requested legal entity list
   * @param data - pagination data
   * @returns
   */
  getChangeRequestedLegalEntityList(data) {
    const params = new URLSearchParams();
    params.append('search', data?.search);
    params.append('page', data?.page);
    params.append('limit', data?.limit);
    return this.baseApi.httpGet(`${adminRoutes.changeRequestedEntityList}?${params.toString()}`);
  }

  /**
   * Approve or reject legal entity changes
   * @param legal entity id
   * @param data {firmId, approved}
   * @returns
   */
  approveOrRejectLegalEntityChanges(entityId: string, data: { firmId: string; approved: boolean }): Observable<any> {
    return this.baseApi.httpPost(`${adminRoutes.billingInfoChangeRequest}/${entityId}`, data);
  }

  /**
   * Get File format list
   */
  getFileFormat(projectId = '') {
    return this.baseApi.httpGet(`${adminRoutes.fileSettings}?projectId=${projectId}`);
  }

  /**
   * Add/Update File format
   */
  addOrUpdateFileFormat(values) {
    return this.baseApi.httpPost(adminRoutes.fileSettings, values);
  }

  /**
   * Delete File format
   */
  deleteFileFormat(values) {
    return this.baseApi.httpPut(adminRoutes.fileSettings, values);
  }

  /**
   * Retrieves list of risk created by super/client admin
   * @param values Table Data
   * @returns List of risk created by super admin
   */
  getRiskList(values: TableData) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder}`);
    params.append('search', `${values?.search}`);
    return this.baseApi.httpGet(`${adminRoutes.risk}?${params.toString()}`);
  }

  /**
   * Gets data about a specific risk
   * @param riskId Unique ID of the risk
   * @returns Risk data
   */
  getRisk(riskId: string) {
    return this.baseApi.httpGet(`${adminRoutes.risk}/${riskId}`);
  }

  /**
   * Creates/updates a super/client admin risk
   * @param risk risk data
   * @returns Created/updated super admin risk
   */
  createOrUpdateSuperAdminRisk(risk: Risk) {
    return this.baseApi.httpPost(`${adminRoutes.risk}`, risk);
  }

  /**
   * Deletes an existing super/client admin risk by its ID
   * @param riskId Unique ID of the risk
   * @returns
   */
  deleteSuperAdminRisk(riskId: string) {
    return this.baseApi.httpDelete(`${adminRoutes.risk}/${riskId}`);
  }

  /**
   * Update client onboarding status
   * @param id unique Id of the client
   * @param status unique status of the client
   * @returns Update on status change
   */
  updateClientOnboardingStatus(id: string, status: string) {
    const url = adminRoutes.clientOnboardingStatus.replace('{clientId}', id);
    return this.baseApi.httpPut(url, { status });
  }

  createCustomerInStripe(clientId: string) {
    const url = adminRoutes.createCustomerInStripe.replace('{clientId}', clientId);
    return this.baseApi.httpPut(url);
  }

  /**
   * Creates stripe customer for a legal entity
   * @param firmId
   * @param legalEntityId
   * @returns
   */
  createLegalEntityStripeCustomer(firmId: string, legalEntityId: string) {
    const replacedUrl = adminRoutes.createLegalEntityInStripe.replace('{firmId}', firmId);
    const url = replacedUrl.replace('{legalEntityId}', legalEntityId);
    return this.baseApi.httpPut(url);
  }

  /**
   * Retrieves list of client admin change requests
   * @param values Table Data
   * @returns List of risk created by super admin
   */
  getClientAdminChangeRequests(values: CommonTableData) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', `${values?.sortBy}`);
    params.append('sortOrder', `${values?.sortOrder === 1 ? 'asc' : 'desc'}`);
    params.append('search', `${values?.search}`);
    params.append('isFirm', `${values?.isFirm}`);
    if (values.filter.status) params.append('status', `${values?.filter.status}`);
    return this.baseApi.httpGet(`${adminRoutes.clientAdminSearchRequest}?${params.toString()}`);
  }

  approveOrRejectAdminChange(id: string, body: { status: AdminRoleChangeStatus; reason?: string; isFirm?: boolean }) {
    const url = adminRoutes.adminChangeRequestStatus.replace('{requestId}', id);
    return this.baseApi.httpPut(url, body);
  }

  /**
   * Retrieves list of active users
   * @param values Table Data
   * @returns List of  active users
   */
  getActiveUsers(id: string, isClient: boolean, query?: { page: number; limit: number; search: string }) {
    const url = adminRoutes.users.replace('{id}', id);
    const params = new URLSearchParams();
    params.append('isClient', `${isClient}`);
    if (query?.search) params.append('search', query.search);
    if (query?.page) params.append('page', `${query.page}`);
    if (query?.limit) params.append('limit', `${query.limit}`);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * Updates admin change
   * @param values Table Data
   * @returns Updated change
   */
  updateAdminChange(clientId: string, body: object) {
    const url = adminRoutes.roleChangeByAdmin.replace('{clientId}', clientId);
    return this.baseApi.httpPost(`${url}`, body);
  }

  getDemographyChildList(parentId: string, type: string) {
    const params = new URLSearchParams();
    params.append('type', type);
    const url = adminRoutes.demographyChildList.replace('{parentId}', parentId);
    return this.baseApi.httpGet(`${url}?${params.toString()}`);
  }

  getNotificationCategories(): Observable<any> {
    return this.baseApi.httpGet(adminRoutes.notificationCategories);
  }

  /**
   * To fetch Contract Visibility
   */
  importCurrencyConversion(): Observable<any> {
    return this.baseApi.httpPost(adminRoutes.importConversion);
  }

  /**
   * add Kys template
   * @param {templateName, language}
   */
  addKysTemplate(values: { templateName: string; languageId: string }) {
    return this.baseApi.httpPost(adminRoutes.addKysQuestionaire, values);
  }

  /**
   * update Kys template
   * @param {templateName, language}
   */
  updatKysTemplate(values: { templateName: string; languageId: string; templateId: string, isDefault: Boolean }) {
    return this.baseApi.httpPut(adminRoutes.addKysQuestionaire, values);
  }

  /**
   * delete Kys template
   * @param {templateId}
   */
  deleteKysTemplate(values: { templateId: string }) {
    return this.baseApi.httpDelete(adminRoutes.addKysQuestionaire, values);
  }

  /**
   * clone Kys template
   * @param values {templateId}
   * @returns
   */
  duplicateKysTemplate(values: { templateId: string }) {
    return this.baseApi.httpPost(adminRoutes.cloneKysQuestionaire, values);
  }

  /**
   * Add or Update on Boarding section and questions
   * @param values details of add and update
   * @param isAdd Add or update boolean
   * @returns updated status
   */
  addOrUpdateKysSection(values: object, isAdd: boolean) {
    if (isAdd) {
      return this.baseApi.httpPost(`${adminRoutes.addKyesQuestions}`, values);
    }
    return this.baseApi.httpPut(`${adminRoutes.addKyesQuestions}`, values);
  }

  /**
   * get Kys template
   * @returns
   */
  getKysTemplate() {
    return this.baseApi.httpGet(adminRoutes.addKysQuestionaire);
  }

  /*
   * get Kys details for client customization
   */
  getKysQuestionnaireDetails(templateId) {
    const params = new URLSearchParams();
    params.append('templateId', templateId);
    return this.baseApi.httpGet(`${adminRoutes.addKyesQuestions}?${params.toString()}`);
  }

  /**
   * Delete Kys section and questions
   * @param sectionId Unique Id of section
   * @param questionId Unique Id of questions
   * @returns Deleted status
   */
  deleteKysQuestionnaireDetails(sectionId: string, questionId?: string) {
    const url = adminRoutes.reorderOrDelete.replace('{id}', sectionId);
    const params = new URLSearchParams();
    if (questionId?.length) params.append('questionId', questionId);
    return this.baseApi.httpDelete(`${url}?${params.toString()}`);
  }

  /**
   * Reorder Kys details
   * @param sectionId Unique Id of section
   * @param values reorder PUT details
   * @returns reorder status
   */
  reorderKysQuestionnaire(sectionId: string, values: { isSection: boolean; fromIndex: number; toIndex: number }, templateId) {
    const url = adminRoutes.reorderOrDelete.replace('{id}', sectionId);
    return this.baseApi.httpPut(url, { ...values, templateId: templateId });
  }

  /**
   * get language existing in kys template
   */
  getKysTemplateLanguage() {
    return this.baseApi.httpGet(adminRoutes.templateLanguage);
  }

  /**
   * get kys template based on the language
   */
  getTemplateBasedOnLanguage(languageId: string) {
    return this.baseApi.httpGet(`${adminRoutes.templateBasedOnLanguage}/${languageId}`);
  }

  linkQuestionnaire(values) {
    return this.baseApi.httpPost(`${adminRoutes.linkQuestionnaire}/${values.entityId}`, values);
  }

  /*
   * get client onboarding data list
   */
  getKysData(values) {
    return this.baseApi.httpGet(`${adminRoutes.linkQuestionnaire}/${values.legalEntityId}`);
  }
}
