import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { basicFormValues } from '../../interfaces';

@Component({
  selector: 'app-date-filter-action',
  templateUrl: './date-filter-action.component.html',
  styleUrls: ['./date-filter-action.component.scss']
})
export class DateFilterActionComponent implements OnInit {
  title: string;
  message: string;
  acceptanceText: string;
  type = 'warning';
  names = [];
  sDate: Date;
  eDate: Date;
  basicFormValues = {} as basicFormValues;
  startDatepickerOpened = false;
  endDatepickerOpened = false;
  constructor(
    private dialogRef: MatDialogRef<DateFilterActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data?.start || data?.end){
      this.sDate = new Date(data?.start);
      this.eDate = new Date(data?.end);
    }
   }

  ngOnInit() {
  }

  handleClick(value: boolean) {
    this.dialogRef.close({isAccept: value, startDate: this.sDate, endDate: this.eDate});
  }

  /**
   * Event handler for date select
   * @param startDate The expected start date
   * @returns
   */
  selectDate(startDate: Date) {
    return new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
  }

  /**
   * Calculates max date
   * @param endDate End Date
   * @returns
   */
  maxDate(endDate: Date) {
    return endDate ? new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 1)) : null;
  }

  toggleStartDatepicker() {
    this.startDatepickerOpened = !this.startDatepickerOpened;
  }

  toggleEndDatepicker() {
    this.endDatepickerOpened = !this.endDatepickerOpened;
  }

}
