import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { I18NextModule } from 'angular-i18next';
import { ConpulseMaterialModule } from '../../../material-module';
import { UtilityMethodsService } from '../../../services/utility-methods.service';
import { FilterSelectModule } from '../../filter-select/filter-select.module';
import { InputChipInstantSaveModule } from '../../input-chip-instant-save/input-chip-instant-save.module';
import { TemplateEditorRoutingModule } from './template-editor-routing.module';
import { TemplateEditorComponent } from './template-editor.component';
import { CustomisationDynamicTemplateModule } from '../../customisation-dynamic-template/customisation-dynamic-template.module';
import { DynamicSectionsModule } from '../../dynamic-sections/dynamic-sections.module';
import { ConpulseModule } from '../../../conpulse.module';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [TemplateEditorComponent],
  imports: [
    CommonModule,
    TemplateEditorRoutingModule,
    ConpulseMaterialModule,
    FilterSelectModule,
    InputChipInstantSaveModule,
    QuillModule.forRoot(),
    CustomisationDynamicTemplateModule,
    I18NextModule,
    DynamicSectionsModule,
    ConpulseModule,
    EditorModule
  ],
})
export class TemplateEditorModule {
  constructor(private utilityMethod: UtilityMethodsService) {
    this.utilityMethod.prepareQuillModule();
  }
}
