<div [ngClass]="{ 'chat-container h-100': isProjectManagement }">
  <div *ngIf="conversation?.chatProfile?.name" class="border-bottom-grey-2">
    <ng-container *ngIf="isFloatingChat; else chatHeaderRef">
      <div class="d-flex align-items-center justify-content-between p-12px">
        <div class="d-flex align-items-center flex-1">
          <div class="position-relative">
            <div class="size-36 border-radius-full overflow-hidden">
              <img class="w-100" [src]="conversation?.isPrivate ? conversation?.chatProfile?.picture || defaultProfilePhoto : defaultGroupPhoto" />
            </div>
            <div class="chat-user-status float-chat-status border-radius-full" *ngIf="conversation.isPrivate && conversation.chatProfile.onlineStatus"></div>
          </div>
          <div class="ml-1">
            <p *ngIf="conversation.isPrivate; else groupName" class="text-truncate f-14 f-w-600 w-190px">
              {{ conversation.chatProfile.role === CONSTANTS.role.admin ? 'Consource Team' : conversation.chatProfile.name }}
            </p>
            <ng-template #groupName>
              <p class="f-14 l-h-18 f-w-600 w-190px text-truncate">{{ conversation?.projectName }}</p>
              <p class="f-12 l-h-18 f-w-400 mt-1 text-secondary w-190px text-truncate">{{ conversation?.name }}</p>
            </ng-template>
          </div>
        </div>
        <mat-icon class="mat-20 border-radius-full pointer d-inline-table minimize-icon" (click)="closeOrMinimizeChatRoom(true)">remove</mat-icon>
        <mat-icon class="mat-20 border-radius-full pointer d-inline-table ml-3 minimize-icon" (click)="closeOrMinimizeChatRoom()">close</mat-icon>
      </div>
    </ng-container>
    <ng-template #chatHeaderRef>
      <div class="d-flex align-items-center flex-1 p-3" *ngIf="conversation?.isPrivate">
        <div class="position-relative m-r-6">
          <div class="profile-image border-radius-full overflow-hidden">
            <img class="w-100" [src]="conversation.chatProfile.picture || defaultProfilePhoto" />
          </div>
          <div class="chat-user-status border-radius-full" *ngIf="conversation.chatProfile.onlineStatus"></div>
        </div>
        <div class="m-l-6 d-flex flex-1 text-truncate flex-column">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h3 *ngIf="conversation.isPrivate; else groupName" class="text-truncate f-14 f-w-600 txt-black-primary">
                {{ conversation.chatProfile.role === CONSTANTS.role.admin ? 'Consource Team' : conversation.chatProfile.name }}
              </h3>
              <p class="f-12 f-w-400 txt-grey-teritary mt-1">{{ conversation.messagesInfo?.latestMessage?.createdAt | timeAgo }}</p>
              <ng-template #groupName>
                <p class="t-14-18-600-b-p" [innerHTML]="conversation.name"></p>
              </ng-template>
            </div>
            <div class="block-chat" *ngIf="conversation?._id">
              <button mat-icon-button [matMenuTriggerFor]="blockConversation" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #blockConversation="matMenu">
                <div *ngIf="!conversation.isBlocked; else unBlock" (click)="blockChat(conversation._id)">
                  <button mat-menu-item>
                    <mat-icon class="mat-20">no_accounts</mat-icon>
                    <span>Block user</span>
                  </button>
                </div>
                <ng-template #unBlock>
                  <button (click)="unBlockChat(conversation?.chatRoomId, conversation.chatSettingsId)" mat-menu-item>
                    <mat-icon class="mat-20">account_circle</mat-icon>
                    <span>Unblock user</span>
                  </button>
                </ng-template>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="chat-header d-flex justify-content-between w-100 align-item-center" *ngIf="isProjectManagement">
    <h3 class="ml-2">{{ conversation?.chatProfile?.name || '' }}</h3>
    <div>
      <mat-icon class="f-20 txt-grey-secondary9 pointer" [matMenuTriggerFor]="menu">more_vert</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="triggerEditDeleteRoom.emit('Edit')"><mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}</button>
        <button mat-menu-item class="delete-action" (click)="triggerEditDeleteRoom.emit('Delete')">
          <mat-icon class="delete-action">delete</mat-icon>{{ 'Delete' | i18next }}
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- <ng-template #workspaceChat>
    <div *ngIf="conversation?.members?.length" class="mr-2 border-bottom-grey-2">
      <div class="d-flex flex-1 p-3 mb-2">
        <p class="f-12 mt-2 f-w-400 txt-grey-secondary7">{{ conversation?.members?.length }} members</p>
      </div>
    </div>
  </ng-template> -->

  <div class="position-relative" [ngClass]="{ 'wrap-container h-100': conversation?.isPrivate && !isFloatingChat }">
    <div
      [infiniteScrollDistance]="2"
      #messageContainer
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
      infinite-scroll
      (scrolledUp)="onScroll()"
      class="message-container pointer"
      [ngClass]="{
        'floatchat-container border-bottom-grey-2 py-2 pl-3 pr-1': isFloatingChat,
        'py-4 pl-4 pr-2': !isFloatingChat,
        'message-chat-container': conversation?.isPrivate,
        'h-536': conversation?.projectId,
        'empty-card ': !conversation
      }"
    >
      <ng-container *ngFor="let messageGroup of groupedMessageList | keyvalue: keyValueSort">
        <!-- Date Separator -->
        <div class="d-flex align-items-center mb-3">
          <div class="flex-1 h-1 bg-grey-lighten"></div>
          <p class="f-w-600 f-12 mx-3 txt-black-primary">
            {{ messageGroup.key === todayDate ? 'Today' : messageGroup.key === yesterdayDate ? 'Yesterday' : messageGroup.key }}
          </p>
          <div class="flex-1 h-1 bg-grey-lighten"></div>
        </div>

        <ng-container *ngFor="let chatMessage of messageGroup.value">
          <!-- From Message -->
          <div class="chat-message d-flex align-items-end" *ngIf="chatMessage.senderInfo._id !== currentUser?._id">
            <ng-container *ngIf="!chatMessage?.isDeleted; else deletedMessageRef">
              <div class="profile-image m-b-18 border-radius-full overflow-hidden">
                <img class="w-100" [src]="chatMessage.senderInfo.picture || defaultProfilePhoto" />
              </div>
              <div class="d-flex flex-1 pl-2 flex-column">
                <div class="d-flex message-bubble message-bubble-from text-break-word" [class.pdf-to-container]="chatMessage.type !== type.MESSAGE">
                  <!-- <p class="f-12 mt- mb-1 f-w-600 text-left">{{ chatMessage.senderInfo.name }}</p> -->
                  <p
                    *ngIf="chatMessage.type === type.MESSAGE; else loadDocFrom"
                    class="f-w-400 f-14 text-break-word text-left txt-grey-darken"
                    [innerHTML]="chatMessage.message"
                  ></p>
                  <ng-template #loadDocFrom>
                    <ng-container
                      *ngIf="
                        !imgFormat.includes(
                          chatMessage.documentId.name.substring(chatMessage.documentId.name.lastIndexOf('.'), chatMessage.documentId.name.length)
                        );
                        else showImg
                      "
                    >
                      <div class="image-container">
                        <img [src]="baseUrl + utilityService.getChatAssetsPath(chatMessage.documentId.name)" height="40px" width="40px" />
                      </div>
                      <div class="d-flex align-items-center justify-content-between pdf-message">
                        <div>
                          <h3 class="f-w-400 f-14 text-break-word text-right mw-120 w-100">
                            {{ getFileName(chatMessage.documentId.name) }}
                          </h3>
                          <!-- <p class="f-12 f-w-500">8 Mb</p> -->
                        </div>
                        <button
                          *ngIf="chatMessage.documentId.key && isPreviewAvailable(chatMessage.documentId.key)"
                          mat-icon-button
                          [matTooltip]="'Click to View' | i18next"
                          matTooltipPosition="above"
                          (click)="viewDocument(chatMessage.documentId.key)"
                        >
                          <mat-icon class="material-icons pointer f-20">visibility</mat-icon>
                        </button>
                        <button
                          *ngIf="chatMessage.documentId.key && !isPreviewAvailable(chatMessage.documentId.key)"
                          mat-icon-button
                          [matTooltip]="'Click to Download' | i18next"
                          matTooltipPosition="above"
                          (click)="downloadDocument(chatMessage.documentId.key, chatMessage.documentId.name)"
                        >
                          <mat-icon class="material-icons pointer f-20">download</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                    <ng-template #showImg>
                      <img
                        [src]="imgUrl + chatMessage.documentId.key"
                        class="image-upload w-100 object-contain"
                        (click)="viewDocument(chatMessage.documentId.key)"
                      />
                    </ng-template>
                  </ng-template>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                  <p class="f-10 mt-1 f-w-500 txt-grey-hint">{{ chatMessage.createdAt | date: 'shortTime' }}</p>
                  <p class="f-10 mt-1 f-w-500 txt-grey-hint" *ngIf="chatMessage?.isEdited">&nbsp;&#x2022;&nbsp;{{ 'Edited' | i18next }}</p>
                </div>
              </div>
            </ng-container>
            <ng-template #deletedMessageRef>
              <div class="mt-3 profile-image"></div>
              <div>
                <p class="f-w-500 f-14 text-break-word text-left bg-grey-lighten p-1 border-radius-6 px-2">
                  {{ 'Message was deleted' | i18next }}
                </p>
                <div class="d-flex align-items-center justify-content-start">
                  <p class="f-10 mt-1 f-w-500 txt-grey-hint">{{ chatMessage.createdAt | date: 'shortTime' }}</p>
                </div>
              </div>
            </ng-template>
          </div>

          <!-- To Message -->
          <ng-container *ngIf="chatMessage.senderInfo._id === currentUser?._id">
            <div class="chat-message d-flex align-flex-end justify-content-end">
              <ng-container *ngIf="!chatMessage.isDeleted; else currentUserDeletedRef">
                <div class="message-width">
                  <div class="d-flex align-items-center justify-content-end">
                    <div class="d-flex message-bubble message-bubble-to text-break-word" [class.pdf-container]="chatMessage.type !== type.MESSAGE">
                      <p
                        *ngIf="chatMessage.type === type.MESSAGE; else loadDoc"
                        class="f-w-400 f-14 text-break-word text-left"
                        [innerHTML]="chatMessage.message"
                      ></p>
                      <ng-template #loadDoc>
                        <ng-container
                          *ngIf="
                            !imgFormat.includes(
                              chatMessage.documentId.name.substring(chatMessage.documentId.name.lastIndexOf('.'), chatMessage.documentId.name.length)
                            );
                            else showToImg
                          "
                        >
                          <div class="image-container">
                            <img [src]="baseUrl + utilityService.getChatAssetsPath(chatMessage.documentId.name)" height="40px" width="40px" />
                          </div>
                          <div class="d-flex align-items-center justify-content-between pdf-message">
                            <div>
                              <h3 class="f-w-400 f-14 text-break-word text-right mw-120 w-100">
                                {{ getFileName(chatMessage.documentId.name) }}
                              </h3>
                              <!-- <p class="f-12 f-w-500 txt-grey-hint">8 Mb</p> -->
                            </div>
                            <button
                              class="p-6"
                              *ngIf="chatMessage.documentId.key  && isPreviewAvailable(chatMessage.documentId.key)"
                              mat-icon-button
                              [matTooltip]="'Click to View' | i18next"
                              matTooltipPosition="above"
                              (click)="viewDocument(chatMessage.documentId.key)"
                            >
                              <mat-icon class="material-icons pointer f-20">visibility</mat-icon>
                            </button>
                            <button
                              class="p-6"
                              *ngIf="chatMessage.documentId.key  && !isPreviewAvailable(chatMessage.documentId.key)"
                              mat-icon-button
                              [matTooltip]="'Click to Download' | i18next"
                              matTooltipPosition="above"
                              (click)="downloadDocument(chatMessage.documentId.key, chatMessage.documentId?.name)"
                            >
                              <mat-icon class="material-icons pointer f-20">download</mat-icon>
                            </button>
                          </div>
                        </ng-container>
                        <ng-template #showToImg>
                          <img
                            [src]="imgUrl + chatMessage.documentId.key"
                            class="image-upload w-100 object-contain"
                            (click)="viewDocument(chatMessage.documentId.key)"
                          />
                        </ng-template>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center chat-menu">
                  <mat-icon class="more-action f-20 txt-grey-secondary9" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="chatMessage.type === type.MESSAGE" (click)="triggerEditMessage(chatMessage)">
                      <mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}
                    </button>
                    <button mat-menu-item class="delete-action" (click)="triggerDeleteMessage(chatMessage._id)">
                      <mat-icon>delete</mat-icon>{{ 'Delete' | i18next }}
                    </button>
                  </mat-menu>
                </div>
              </ng-container>
              <ng-template #currentUserDeletedRef>
                <p class="f-w-500 f-14 text-break-word text-left bg-grey-lighten p-1 border-radius-6 px-2 mr-4">{{ 'Message was deleted' | i18next }}</p>
              </ng-template>
            </div>
            <div class="mb-2 d-flex align-items-center justify-content-end mr-4">
              <p class="f-10 mt-1 f-w-500 txt-grey-hint" *ngIf="chatMessage?.isEdited && !chatMessage?.isDeleted">
                {{ 'Edited' | i18next }}&nbsp;&#x2022;&nbsp;
              </p>
              <p class="f-10 mt-1 f-w-500 txt-grey-hint">{{ chatMessage.createdAt | date: 'shortTime' }}</p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #noMessages>
        <div class="no-data mt-5 ml-1 h-360px">
          <p class="subtitle text-center">{{ 'start conversation' | i18next }}</p>
        </div>
      </ng-template>
    </div>
    <div *ngIf="conversation?.isBlocked" class="d-flex text-truncate pr-1 f-14 f-w-600 w-710px justify-content-center">
      {{ 'This user was blocked by you' | i18next }}
    </div>
    <div *ngIf="conversation" class="d-flex justify-content-between align-items-center border-top-grey-light-1 px-3 py-12">
      <mat-form-field class="mr-2 comment-textarea send-message-container full-width mb-0" floatLabel="never">
        <textarea
          [disabled]="conversation.isBlocked"
          matInput
          #chatMessageRef
          [hidden]="document?.name"
          type="text"
          [placeholder]="!document?.name ? ('Type a message' | i18next) : ''"
          name="sendNewMessage"
          [(ngModel)]="chatMessage"
          cdkTextareaAutosize
          (focusout)="editMessageId.length > 0 ? focusout() : ''"
          (keydown.enter)="editMessageId.length > 0 ? editMessage($event) : sendMessage($event)"
        ></textarea>
        <ng-container *ngIf="document?.name">
          <div class="document-box" (keyup.enter)="sendMessage($event)" tabindex="0">
            <div class="d-flex align-items-center justify-content-between">
              <p class="f-12 f-w-500 l-h-16 txt-grey-secondary7 text-truncate mw-200">
                {{ document.name }}
              </p>
              <i class="material-icons txt-grey-secondary pointer" (click)="document = {}">close</i>
            </div>
          </div>
        </ng-container>
        <div [ngClass]="{ 'pointer-none': conversation?.isBlocked }" matSuffix class="d-flex" *ngIf="!document?.name" [class.hide]="document?.name">
          <div
            class="suffix-icon border-radius-full display-inline-table p-1 pointer"
            [matTooltip]="'Add Emoji' | i18next"
            [matTooltipDisabled]="conversation?.isBlocked"
            [matMenuTriggerFor]="emojiMenu"
            #emojiMenuTrigger="matMenuTrigger"
          >
            <mat-icon class="mat-20 m-0">mood</mat-icon>
          </div>
          <div
            class="suffix-icon border-radius-full display-inline-table pointer p-1"
            [matTooltipDisabled]="conversation?.isBlocked"
            [matTooltip]="'Attachment' | i18next"
            (click)="fileUpload.click()"
          >
            <mat-icon class="mat-20 m-0"> attach_file </mat-icon>
          </div>
        </div>
        <input
          [disabled]="conversation?.isBlocked"
          hidden="true"
          accept="{{ documentDetails.allowedFiles }}"
          type="file"
          #fileUpload
          (click)="fileUpload.value = null"
          value=""
          id="fileRef"
          name="DocumentUpload"
          (input)="uploadDocument($event)"
        />
      </mat-form-field>
      <mat-menu #emojiMenu="matMenu" xPosition="before" class="emoji-menu" panelClass="emoji-menu">
        <div (click)="$event.stopPropagation()">
          <emoji-mart set="google" [isNative]="true" [showPreview]="false" (emojiSelect)="addEmoji($event)"></emoji-mart>
        </div>
      </mat-menu>
      <button
        mat-icon-button
        color="secondary"
        class="m-l-10 bg-blue-ligten4 send-message-icon"
        [disabled]="(!trim(chatMessage) && !document?.name) || (editMessageId.length > 0 && isEqual(initialMessage, trim(chatMessage))) || sendMessageInitiated"
        aria-label="Send"
        (click)="editMessageId.length > 0 ? editMessage() : sendMessage()"
      >
        <mat-icon class="fill-icon display-inline-table txt-white l-h-18 ml-1">send</mat-icon>
      </button>
    </div>
  </div>
</div>
