import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { get } from 'lodash-es';
import {
  CONSTANTS,
  GlobalNetworkService,
  NotificationService,
  ClientProviderPageRouteRegex,
  FirmProviderPageRouteRegex,
  AdminProviderPageRouteRegex,
  ProviderPageTitle,
  ProviderPageType,
  UserService,
  user,
  AdminService,
  ClientAccountTypes,
  FirmService,
  DeleteConfirmationComponent,
} from '@conpulse-web/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'conpulse-web-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit, OnDestroy {
  companyId: string = '6295f9beeedab74e16967553';
  isLoading = false;
  companyProfile: { name: string; tagline: string; profilePhoto: string; coverPhoto: string } = {} as {
    name: string;
    tagline: string;
    profilePhoto: string;
    coverPhoto: string;
  };
  tabName: string;
  routerSubscription: Subscription = null;
  isConsultingPanel: boolean = false;
  routerUrl: string;
  baseUrl: string;
  CONSTANTS = CONSTANTS;
  user: user;
  editUrl = '';
  pageTitle = ProviderPageTitle;
  currentPage: ProviderPageType = ProviderPageType.GENERAL_INFO;
  isClient = false;
  isContractEditVisible: boolean = false;
  accountTypes = ClientAccountTypes;
  onBoardingStatus: string = '';
  tabSubscription: Subscription = null;
  isAdminChangeEnabled: boolean = false;

  constructor(
    private cgnService: GlobalNetworkService,
    private route: ActivatedRoute,
    private userService: UserService,
    private adminService: AdminService,
    private firmService: FirmService,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.user = this.userService.currentUserInformation;
    this.isClient = CONSTANTS.CLIENT_USER_ROLES.includes(this.user?.role);

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isConsultingPanel =
          event.url.startsWith('/dashboard/provider/viewProfile') ||
          event.url.startsWith('/dashboard/consulting-firms/viewProfile') ||
          event.urlAfterRedirects.startsWith('/invitation-dashboard/firm-profile');
        const url = this.router.routerState.snapshot.url.split('/');
        const companyIdPos = this.isConsultingPanel ? (this.router.routerState.snapshot.url.includes('kys-question') ? 6 : url.includes('edit') ? 5 : 3) : 2;
        this.companyId = url[url.length - companyIdPos];
        this.routerUrl = url[url.length - 1];
        this.baseUrl = !this.isConsultingPanel
          ? `${url.splice(0, url.length - 2).join('/')}/${this.companyId}`
          : url.includes('edit')
          ? `${url.splice(0, url.length - 5).join('/')}/${this.companyId}/company-profile`
          : `${url.splice(0, url.length - 3).join('/')}/${this.companyId}/company-profile`;
        this.editUrl = url[url.length - 2];
        this.currentPage = this.getCurrentPageType(event.url) as ProviderPageType;
      }
    });

    this.tabSubscription = this.userService.updateTabIndex?.subscribe((data) => {
      this.tabName = data === 0 ? 'accounts' : '';
    });

    this.firmService.geyEntityInfo$.subscribe((data) => {
      this.onBoardingStatus = data?.onBoardingStatus;
    });
  }
  ngOnInit(): void {
    this.getCompanyProfileDetails();
    this.adminService.getContractEditVisibility().subscribe((data) => {
      if (!CONSTANTS.allUserRole.includes(this.user?.role)) {
        this.isContractEditVisible = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.tabSubscription?.unsubscribe();
  }

  /**
   * get the url of current page
   * @param url
   * @returns
   */
  getCurrentPageType(url: string): string | null {
    const regex = CONSTANTS.CF_PROFILE_ROLES.includes(this.user?.role)
      ? FirmProviderPageRouteRegex
      : CONSTANTS.CLIENT_USER_ROLES.includes(this.user?.role)
      ? ClientProviderPageRouteRegex
      : AdminProviderPageRouteRegex;
    const match = url.match(regex);

    if (match) {
      return match[1];
    } else {
      return ProviderPageType.GENERAL_INFO;
    }
  }

  /**
   * Gets Company Profile Details
   */
  getCompanyProfileDetails() {
    this.isLoading = true;
    this.cgnService.getCompanyProfileDetails(this.companyId, this.isConsultingPanel).subscribe({
      next: (response) => {
        this.companyProfile = get(response, 'data', {});
        this.isAdminChangeEnabled = !response?.data?.noActiveusers;
        this.isLoading = false;
      },
      error: (error) => {
        this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to load profile details'));
      },
    });
  }

  openClientAdminDrawer() {
    this.adminService.setAccountDrawerState({
      open: true,
      type: 'change-admin',
    });
  }

  openAddEditDrawer(type: string) {
    this.adminService.openAccountDrawerCfProfile(type);
  }

  updateBoardingStatus() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Onboarding Status';
    dialogRef.componentInstance.message = 'Are you sure you want to change the status to approved?';
    dialogRef.componentInstance.acceptanceText = 'Yes';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.userService.updateEntityOnboardingInfo.next({ firmId: this.companyId });
      }
    });
  }
}
