<div class="bg-white border-radius-6 mb-12 p-x-20 p-y-12" *ngIf="isClient || (!isClient && !isSuperAdmin)">
  <div class="d-flex align-items-center justify-content-between" [ngClass]="{ 'h-36': isEmpty(accountDetails?.plan) && isSuperAdmin && isClient }">
    <h3 class="page-title font-Jakarta">{{ 'Subscription' | i18next }}</h3>
    <div class="d-flex align-items-center">
      <button mat-stroked-button color="primary" *ngIf="isSuperAdmin && !isClient && !isLoading" (click)="openClientAdminDrawer()">
        {{ 'Change Admin' | i18next }}
      </button>
    </div>
  </div>
</div>

<div
  class="subscription-container bg-white border-radius-6"
  [ngClass]="{ client: isClient, firm: !isClient, 'super-admin': isSuperAdmin, 'p-x-20 p-y-12': isClient || (!isClient && !isSuperAdmin) }"
>
  <ng-container *ngIf="isLoading; else planContainerRef">
    <div class="d-flex">
      <div class="flex-1" *ngFor="let j of [1]">
        <div class="mb-3" *ngFor="let i of [1, 2, 3]">
          <p class="f-13 f-w-600 txt-black l-h-16 skeleton-loader skeleton-title h-auto">{{ 'Type' }}</p>
          <p class="f-14 f-w-400 txt-hint l-h-20 skeleton-loader skeleton-title h-auto">{{ 'Subscription' }}</p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #planContainerRef>
    <ng-container *ngIf="!isEmpty(accountDetails?.plan); else emptyPlanContainer">
      <div class="d-flex">
        <div class="flex-one border-radius-xlarge subscription-panel">
          <div class="d-flex align-items-center justify-content-between subscription-header p-x-20 py-3">
            <div>
              <p class="subscription-title mb-8">{{ 'Subscription Plan' | i18next }}</p>
              <p class="plan-active">{{ 'Active Plan' | i18next }}</p>
            </div>
            <div class="mr-3">
              <mat-icon
                class="p-1 pointer border-radius-full mat-20 more-actions"
                *ngIf="!isEmpty(accountDetails?.plan) && isSuperAdmin"
                [matMenuTriggerFor]="subscriptionMenu"
                >more_vert</mat-icon
              >
              <mat-menu #subscriptionMenu="matMenu">
                <button mat-menu-item (click)="openAddEditDrawer(accountTypes.Plan)"><mat-icon>edit</mat-icon>{{ 'Edit Plan' | i18next }}</button>
              </mat-menu>
            </div>
          </div>
          <div class="d-flex p-x-20 py-3">
            <div class="flex-1">
              <div class="mb-3" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Admin Name' | i18next }}</p>
                <p class="p-info">{{ (accountDetails?.clientId?.clientName) || '-' }}</p>
              </div>
              <div class="mb-3" *ngIf="!isClient">
                <p class="p-title m-b-2">{{ 'Admin Name' | i18next }}</p>
                <p class="p-info">{{ (accountDetails?.administrativeInfo?.adminContact.name) || '-' }}</p>
              </div>
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'Start Date' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.startDate; else emptyData">
                  {{ accountDetails?.plan?.startDate | date }}
                </p>
              </div>
              <div class="mb-3" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Client No' | i18next }}</p>
                <p class="p-info">{{ accountDetails?.clientId?.clientNo || '-' }}</p>
              </div>
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'Period' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.timePeriod; else emptyData">
                  {{ accountDetails?.plan?.timePeriod | objectKey: periodOptions }}
                </p>
              </div>
              <div [class.mb-3]="isClient">
                <p class="p-title m-b-2">{{ 'Renewal Type' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.renewalType; else emptyData">
                  {{ accountDetails?.plan?.renewalType | objectKey: renewalOptions | i18next }}
                </p>
              </div>
            </div>
            <div class="flex-1">
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'End Date' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.endDate; else emptyData">{{ accountDetails?.plan?.endDate | date }}</p>
              </div>
              <div class="mb-3" *ngIf="isClient; else consultingFirm">
                <p class="p-title m-b-2">{{ 'PO Number' | i18next }}</p>
                <p class="p-info">{{ accountDetails?.plan?.poNumber || '-' }}</p>
              </div>
              <ng-template #consultingFirm>
                <div class="mb-3">
                  <p class="p-title m-b-2">{{ 'Process Fee' | i18next }}</p>
                  <p class="p-info">{{ accountDetails?.plan?.processFee || '-' }}%</p>
                </div>
              </ng-template>
              <div [class.mb-3]="isClient">
                <p class="p-title">{{ 'Type' | i18next }}</p>
                <p class="p-info">{{ accountDetails?.plan?.planType || 'Subscription' }}</p>
              </div>
              <div class="mb-1" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Nature' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.planNature; else emptyData">
                  {{ accountDetails?.plan?.planNature }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-one"></div>
      </div>
      <div class="d-flex mt-3">
        <div class="flex-one border-radius-xlarge subscription-panel">
          <div class="p-x-20 py-3">
            <p class="subscription-title mb-8">{{ 'Onboarding Status' | i18next }}</p>
            <mat-form-field class="w-26 mb-0" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select
                required
                [(ngModel)]="onboardingStatus"
                ngModel
                name="onboardingStatus"
                id="onboardingStatusSelect"
                placeholder="Select Status"
                (selectionChange)="changeOnboardingStatus()"
                [disabled]="!isSuperAdmin"
              >
                <mat-option *ngFor="let status of OnboardingStatusType" [value]="status.value"> {{ status.label }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-one"></div>
      </div>
    </ng-container>
    <ng-template #emptyPlanContainer>
      <div class="align-items-center d-flex empty-container h-100 justify-content-center" *ngIf="isClient; else firmPlan">
        <div class="p-3 d-flex align-items-center justify-content-center flex-column">
          <img class="vector-image" [src]="notFoundImage" alt="no data image" />
          <p class="nodata-title font-Jakarta m-t-20">{{ 'No Records' | i18next }}</p>
          <p class="nodata-summary mw-400 m-t-12">{{ "SORRY! There Isn't Any Record To Display!" | i18next }}</p>
          <button
            color="secondary"
            type="button"
            class="medium mt-2"
            mat-stroked-button
            [hidden]="!isSuperAdmin"
            (click)="openAddEditDrawer(accountTypes.Plan)"
          >
            Add Plan
          </button>
        </div>
      </div>
      <ng-template #firmPlan>
        <div class="d-flex">
          <div class="flex-one border-radius-xlarge subscription-panel">
            <div class="d-flex align-items-center justify-content-between subscription-header p-x-20 py-3">
              <div>
                <p class="subscription-title mb-8">{{ 'Admin Name' | i18next }}</p>
                <p class="p-info">{{ (accountDetails?.administrativeInfo?.adminContact.name) || '-' }}</p>
              </div>
              <div class="mr-3">
                <button
                  color="secondary"
                  type="button"
                  class="medium mt-2"
                  mat-stroked-button
                  [hidden]="!isSuperAdmin"
                  (click)="openAddEditDrawer(accountTypes.Plan)"
                >
                  Add Plan
                </button>
              </div>
            </div>
          </div>
          <div class="flex-one"></div>
        </div>

        <div class="d-flex mt-3">
          <div class="flex-one border-radius-xlarge subscription-panel">
            <div class="p-x-20 py-3">
              <p class="subscription-title mb-8">{{ 'Onboarding Status' | i18next }}</p>
              <mat-form-field class="w-26 mb-0" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
                <mat-select
                  required
                  [(ngModel)]="onboardingStatus"
                  ngModel
                  name="onboardingStatus"
                  id="onboardingStatusSelect"
                  placeholder="Select Status"
                  (selectionChange)="changeOnboardingStatus()"
                  [disabled]="!isSuperAdmin"
                >
                  <mat-option *ngFor="let status of OnboardingStatusType" [value]="status.value"> {{ status.label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-one"></div>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
<!--TODO: Need to remove this after confirmation for firm account usage removal-->
<!-- <div class="m-b-16 mt-3 section w-100 flex-1">
        <div class="title-container d-flex align-items-center justify-content-between">
          <h3 class="f-20 f-w-600 l-h-28 mb-2">{{ 'Account Usage' | i18next }}</h3>
        </div>
        <ng-container *ngIf="isLoading; else clientAccountUsageRef">
          <div class="d-flex">
            <div class="flex-1" *ngFor="let j of [1, 2]">
              <div class="mb-3" *ngFor="let i of [1, 2]">
                <p class="f-12 f-w-500 l-h-16 skeleton-loader skeleton-title h-auto">{{ 'Type' }}</p>
                <p class="f-14 f-w-400 txt-hint l-h-20 skeleton-loader skeleton-title h-auto">{{ 'Subscription' }}</p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #clientAccountUsageRef>
          <ng-container *ngIf="!isEmpty(clientAccountUsage); else emptyUsageContainer">
            <ng-container *ngIf="isClient; else firmUsageRef">
              <div class="d-flex">
                <div class="flex-1">
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16">{{ 'Total Projects' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.totalProject || 0 }}</p>
                  </div>
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16 mt-2">{{ 'Panel' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.firmsInPanel || 0 }}</p>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16 mt-2">{{ 'Active Users' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.activeUsers || 0 }}</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #firmUsageRef>
              <div class="d-flex">
                <div class="flex-1">
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16">{{ 'Total Projects' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.numberOfProjects || 0 }}</p>
                  </div>
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16 mt-2">{{ 'Clients Linked' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.numberOfClients || 0 }}</p>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16 mt-2">{{ 'Active Users' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.numberOfActiveUsers || 0 }}</p>
                  </div>
                  <div class="mb-3">
                    <p class="f-12 f-w-500 l-h-16 mt-2">{{ 'Legal Entities' | i18next }}</p>
                    <p class="f-14 f-w-400 txt-hint l-h-20">{{ clientAccountUsage?.legalEntities || 0 }}</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #emptyUsageContainer>
            <div class="empty-container">
              <div class="no-data p-3">
                <p class="subtitle mb-2" *ngIf="isClient; else firmEmptyRef">{{ 'The client is not active yet' | i18next }}</p>
                <ng-template #firmEmptyRef
                  ><p class="subtitle mb-2">{{ 'The Firm is not active yet' | i18next }}</p></ng-template
                >
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div> -->
<ng-template #emptyData>
  <p class="f-14 f-w-400 txt-hint l-h-20">-</p>
</ng-template>
