import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Colors,
  ContentStyle,
  DefaultContentStyle,
  DefaultStyleText,
  DynamicDesignModelSpec,
  FontFamily,
  FontSize,
  LayoutData,
  LayoutSections,
  Style,
  StyleType,
  styleSections,
} from '@conpulse-web/core';
import { TinyMceService, UtilityMethodsService } from '../../services';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss'],
})
export class CommonLayoutComponent implements OnInit, OnDestroy {
  @Input() set templateModel(value: LayoutData) {
    if (value) {
      this.parentData = JSON.parse(JSON.stringify(value));
      this.initialParentData = JSON.parse(JSON.stringify(value));
      this.initializeDefaultStyle();
    }
  }
  @Input() isFromChanges = false
  @Input() isLoading: boolean = false;
  @Input() isEditAccess: boolean = false;
  @Output() updateLayout = new EventEmitter();
  @Output() changelayout = new EventEmitter();
  @Output() exitEmitter = new EventEmitter();
  parentData: LayoutData = {
    brandingPreferences: {
      coverPageContent: '',
      headerContent: '',
      footerContent: '',
    },
  } as LayoutData;
  initialParentData: LayoutData = {} as LayoutData;
  RfpSettingsQuillConfig: object = null;
  contentStyle: ContentStyle = DefaultContentStyle;
  section = LayoutSections;
  styleSection: DynamicDesignModelSpec[] = styleSections;
  preview = {
    pageTitle: '',
    sectionTitle: '',
    subSectionTitle: '',
    subTopicTitle: '',
    contentStyle: '',
  };
  styleType = StyleType;
  colorCodes = Colors;
  colorMenuToggle = {
    pageTitle: true,
    sectionTitle: true,
    subSectionTitle: true,
    subTopicTitle: true,
    contentStyle: true,
  };
  fontFamily = FontFamily;
  fontSize = FontSize;
  fontStyle = Style;
  defaultStyleText = DefaultStyleText;

  constructor(private utility: UtilityMethodsService, private i18Next: I18NextPipe,
    private tinyMceService: TinyMceService) {}

  ngOnInit(): void {
    this.contentStyle = this.parentData.contentStyle;
    // this.RfpSettingsQuillConfig = this.utility.getQuillConfig(this.contentStyle);
  }

  // onEditorCreated(quillEditor: any) {
  //   if (this.contentStyle) {
  //     quillEditor.format('font', this.contentStyle.fontFamily);
  //     quillEditor.format('size', this.contentStyle.fontSize);
  //     if (Array.isArray(this.contentStyle.fontStyle)) {
  //       this.contentStyle.fontStyle.forEach((style) => {
  //         quillEditor.format(style, true);
  //       });
  //     }
  //     quillEditor.format('color', this.contentStyle.color);
  //   }
  // }

  ngOnDestroy(): void {
    this.tinyMceService.saveButtonTrigger$.next({ isClicked: false, diffArray: [] })
    this.tinyMceService.reset$.next(true)
  }


  initializeEditor(placeholder){
    return this.tinyMceService.getInitialConfigs(this.contentStyle, this.i18Next.transform(placeholder));
  }

  checkValue() {
    return this.utility.deepEqual(this.parentData, this.initialParentData);
  }

  initializeDefaultStyle() {
    this.styleSection.forEach((style) => {
      this.constructStyle(style['fieldKey']);
    });
  }

  /**
   * update toggle to select color
   * @param key
   */
  updateMenuToggle(keyToExclude) {
    for (const key in this.colorMenuToggle) {
      if (key !== keyToExclude) {
        this.colorMenuToggle[key] = true;
      } else {
        this.colorMenuToggle[key] = !this.colorMenuToggle[key];
      }
    }
  }

  /**
   * select the color for titles
   * @param key
   * @param colorCode
   */
  selectColor(key, colorCode) {
    this.parentData[key]['color'] = colorCode;
    this.colorMenuToggle[key] = !this.colorMenuToggle[key];
    this.constructStyle(key);
  }

  /**
   * constructColor style for preview
   * @param key
   */
  constructStyle(key) {
    let style = '';
    if (this.parentData[key]?.fontStyle?.length) {
      this.parentData[key].fontStyle.forEach((data) => {
        switch (data) {
          case 'bold':
            style += 'font-weight:bold;';
            break;
          case 'underline':
            style += 'text-decoration:underline;';
            break;
          case 'italic':
            style += 'font-style:italic;';
            break;
          default:
            style += `font-style:${data};`;
        }
      });
    }
    this.preview[key] = `font-size:${this.parentData[key]?.fontSize};${style}font-family:${this.parentData[key]?.fontFamily};color:${this.parentData[key]?.color}`;
  }
}
