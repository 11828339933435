import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import { io, Socket } from 'socket.io-client';
import { AuthService } from '@auth0/auth0-angular';
import {
  ApiResponse,
  LoginParams,
  PerformanceFilter,
  RfpInformation,
  SandboxDataSocketMessage,
  Sourcing,
  ProjectTableData,
  InvitedProjectUserResponse,
  PdfSettings,
  SowCommentDetails,
  SoWValidationDetails,
  NdaValidationDetails,
  StrategicPriority,
  ConsultingFirmDrawer,
  MeetingForm,
  MeetingFilter,
  ContractsFilter,
  SuggestCompanyDetails,
  ConsultingFirm,
  LayoutAndTemplateData,
  SelectedFirmData,
  PaginationOptions,
  MarketExplorationFilter,
  EnabledModuleInfo,
  LanguageDrawer,
  SowDynamicFormsValue,
  FirmActionSubject,
  DrawerDetails,
  SourcingSelection,
  ConversionDrawer,
  MessagePreferences,
  CountryDrawer,
  GetActionNature,
  AddActionNature,
  UpdatePreferences,
  ProjectFilter,
  milestone,
} from '../interfaces/common-interfaces';
import { AvailableModulesList, CONSTANTS, ProjectListType, UploadType } from './../constants/common-constants';
import { NotificationService } from '../notification/notification.service';
import { AccordianDrop } from './../interfaces/common-interfaces';
import { map as _map, get, isEmpty, isNaN, isNull, isNumber } from 'lodash-es';
import { PermissionService } from '../services/permission.service';

const auth = {
  login: 'login',
  logout: 'logout',
  register: 'register',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetpassword',
  checkValidInvite: 'checkvalidinvite',
  verify: 'verify',
  checkEmail: 'checkEmail',
  firm: 'firm',
  country: 'country',
  countries: 'countries',
  commonList: 'demography/data',
  organisationTag: 'organisationTag',
  brand: 'brand',
  profile: 'profile',
  changePassword: 'changePassword',
};

const user = {
  user: 'user',
  sourcing: 'sourcing',
  userPasswordReset: 'passwd-reset',
  project: 'project',
  contact: 'contact',
  deleteUser: 'user/delete',
  resendInvite: 'resend/invite',
  getTeamList: 'team',
  notification: 'notification',
  teamMemberDetails: 'team',
  projectScoreCard: 'projectScoreCard',
  embeddableProjectScoreCard: 'embeddableProjectScoreCard',
  consultingScoreData: 'consultingScore',
  clientSatisfactionList: 'clientSatisfaction',
  projectPerformanceData: 'projectPerformance',
  keyDrivers: 'keyDrivers',
  adminDashboard: 'cqAdminDashboard',
  dashboardDetails: 'dashboard',
  surveyAnswer: 'contact/answer',
  firmSettings: 'firm/settings',
  reschedule: 'reschedule',
  clients: 'clients',
  partner: 'partner',
  client: 'client',
  clientDetails: 'clientDetails',
  demographyList: 'demography/list',
  demographyListForProject: 'clients/list',
  userDetails: 'user/details',
  sendSurvey: 'survey/send',
  otherRegion: 'clients/others',
  member: 'member',
  survey: 'survey',
  zohoPlanDetails: 'zohoPlanDetails',
  zohoHostedPage: 'zohoHostedPage',
  deleteSandBoxData: 'deleteSandBoxData',
  businessOverview: 'businessOverview',
  benchmark: 'benchmark',
  activateDeactivateUser: 'activateDeactivateUser',
  changeUserId: 'changeUserAndDeactivate',
  firmAction: 'firm/actions',
  firmMeetingNatures: 'firm/meeting-natures',
  firmActionClose: 'firm/action/close',
  firmActionArchive: 'firm/action/archive',
  companyAction: 'company/actions',
  companyActionClose: 'company/action/close',
  archiveCompanyAction: 'company/action/archive',
  newUploadUrl: 'user/new-upload-url',
  actionAnalytics: 'company/action/analytics',
  firmActionAnalytics: 'firm/task/analytics',
  adminActionAnalytics: 'admin/task/analytics',
  uploadCompanyProfile: 'user/upload/company-profile-url',
  validateEmail: 'validate-email',
  firmUsers: 'user/firms/',
  legalEntity: 'firm/legal-entities',
  deleteLegalEntity: 'firm/legal-entities/delete',
  approveOrRejectLegalEntity: 'approve/legal-entity',
  blogCategory: 'user/blogCategory',
  firmUser: 'firm-user',
  firmUsersListWithPagination: 'firm/:id/users',
  openedTask: 'task/opened-count',
  headerModule: 'module/header'
};

const admin = {
  count: 'count',
  industry: 'industry',
  industries: 'industries',
  capability: 'capability',
  capabilities: 'capabilities',
  region: 'region',
  regions: 'regions',
  clientList: 'admin/client',
  unVerifiedUsers: 'admin/users/unverified',
  language: 'language',
  languageBasedOnLayout: 'language-based-on-layout',
  email: 'email',
  surveyQuestion: 'surveyQuestion',
  localization: 'localization',
  sowSettings: 'sow/general',
  clientEnabledModules: 'clientEnabledModules',
  scsAcademy: 'scsAcademy/blogs',
  scsAcademyCatagory: 'scsAcademy/blog/catagory',
  meetingNatures: 'admin/meeting-natures',
  actionNature: 'admin/action-nature',
  activateOrDeactivateLegalEntity: 'firm/legal-entity/{firmId}/status/{legalEntityId}',
};

const client = {
  company: 'company',
  progress: 'company/progress',
  clientUser: 'companyUser',
  activeUserList: 'active-user/list',
  inviteUser: 'inviteClientAdmin',
  deleteClientUser: 'companyUser/delete',
  resendInviteForClientUser: 'companyUser/resend/invite',
  demographyListForCompany: 'company/demography/list',
  commonListForCompany: 'company/demography/data',
  clientProfile: 'company/profile',
  clientOrganisationTag: 'company/organisationTag',
  clientTiers: 'company/tiers',
  clientBrand: 'company/brand',
  clientSegments: 'company/segment',
  clientFunctions: 'company/function',
  clientCostCenters: 'company/costCenter',
  clientThirdDimension: 'company/thirdDimension',
  companySettings: 'company/settings',
  companyProjectPerformance: 'company/projectPerformance',
  companyConsultingScore: 'company/consultingScore',
  project: 'company/projects',
  updateHomeDetails: 'dashboard/project',
  firm: 'company/firms',
  clientPartner: 'company/partner',
  rescheduleReasonForCompany: 'company/reschedule',
  userDetails: 'companyUserDetails',
  firmDemographyListForProjects: 'firms/list',
  meetingsPartner: 'meetings/partners',
  sendSurvey: 'company/survey/send',
  projectScoreCardCompany: 'company/projectScoreCard',
  clientContact: 'company/contact',
  clientSurveyAnswer: 'company/contact/answer',
  consultingSpendForCompany: 'company/consultingSpend',
  consultingPanelForCompany: 'company/consultingPanel',
  highLowPerformers: 'company/highLowPerformers',
  clientAdminDashboard: 'clientAdminDashboard',
  clientManagerDashboard: 'company/dashboard',
  // Zoho Subscriptions
  zohoPlanDetails: 'company/zohoPlanDetails',
  zohoHostedPage: 'company/zohoHostedPage',
  deleteSandBoxData: 'company/deleteSandBoxData',
  projectRequest: 'company/project/requests',
  projectRequestApproval: 'company/project/requests/approval',
  approvers: 'company/approvers',
  companyWorkflowSettings: 'company/approval-workflow',
  companyWorkflowSettingsDrop: 'company/approval-workflow/drop',
  companyCurrency: 'company/currency',
  spendAnalysis: {
    topSectionCards: 'company/spendAnalysis/topSectionCards',
    companyView: 'company/spendAnalysis/companyView',
    projectView: 'company/spendAnalysis/projectView',
    providersView: 'company/spendAnalysis/providersView',
    panelView: 'company/spendAnalysis/panelView',
  },
  charts: {
    spendProfileChart: 'company/charts/spendProfile',
    spendVolumeByProvidersAndCapability: 'company/charts/spendVolumeByProvidersAndCapability',
  },
  rfpDetails: 'company/project/rfp',
  selectionDetails: 'company/project/proposal',
  rfp: {
    projectDetails: 'company/rfp/project',
    demandDetails: 'company/rfp/demand',
    teamLeadChange: 'company/rfp/demand/LeadChange',
    demandRequest: 'company/rfp/demand/request',
    demandApproval: 'company/rfp/demand/approval',
    milestones: 'company/rfp/milestones',
    selectionCriterion: 'company/rfp/selectionCriterion',
    generatePdf: 'company/project/rfp/generatePdf',
    pdfBrandingSettings: '/company/rfp/pdfBranding',
    deleteExportHistory: '/company/project/rfp/deleteExportHistory',
    exportHistorySettings: '/company/rfp/exportHistory',
    listForCompany: 'company/project/rfp',
    teamMemberList: 'company/rfp/usersList',
  },
  risk: 'company/risk',
  sourcingInvitation: {
    sourcing: 'company/project/sourcing',
    firms: 'company/project/sourcing/firms',
    targetProfileMarkers: 'company/project/sourcing/markers',
    ndaSettings: 'company/sourcing/nda',
    exportHistorySettings: '/company/sourcing/nda/exportHistory',
    invitedProjects: 'company/project/sourcing/invitedProjects',
    inviteEmailPreview: 'company/project/sourcing/inviteEmailPreview',
  },
  questionAndAnswer: {
    question: 'company/project/question',
    answer: 'company/project/answer',
  },
  briefing: 'company/project/briefing',
  proposalAndSelection: {
    proposal: 'company/projects/proposal',
    step: 'company/projects/proposal/step',
    team: 'company/projects/proposal/team',
    result: 'company/projects/proposal/result',
    feedBack: 'company/projects/proposal/feedback',
    firmList: 'company/projects/proposal/firm-list',
    shortlist: 'company/projects/proposal/shortlist',
    uploadUrl: 'company/projects/proposal/upload-url',
    myScoring: 'company/projects/proposal/my-scoring',
    teamScoring: 'company/projects/proposal/team-scoring',
    selectionCriteria: 'company/projects/proposal/criteria',
    editPitchDetails: 'company/projects/proposal/selection/pitch',
    proposalSelection: 'company/projects/${projectId}/proposal/selection-tab'
  },
  sow: {
    sow: 'company/projects/sow',
    pdf: '/company/projects/sow/sign',
    team: 'company/projects/sow/team',
    signature: 'company/projects/sow/sign',
    signatureDocument: 'company/projects/sow/document',
    comments: 'company/projects/sow/comments',
    commentCount: 'company/projects/sow/field/comment-count',
    refDocType: 'company/projects/sow/document',
    management: 'company/projects/sow/management',
    teamMember: 'company/projects/sow/team/member',
    preliminary: 'company/projects/sow/preliminary',
    description: 'company/projects/sow/description',
    refDocument: 'company/projects/sow/refdocument',
    validateField: 'company/projects/sow/validateField',
    governance: 'company/projects/sow/management/governance',
    termsAndCondition: 'company/projects/sow/terms-conditions',
    // generalProvsions: 'company/projects/sow/general-provsions',
    terminology: 'company/projects/sow/preliminary/terminology',
    communicationMail: 'company/projects/sow/communicationMail',
    managementMilestone: 'company/projects/sow/management/milestone',
    termsAndConditionCost: 'company/projects/sow/terms-conditions/cost',
    termsAndConditionMilestone: 'company/projects/sow/terms-conditions/milestone',
    termsAndConditionDetailedFee: 'company/projects/sow/terms-conditions/detailed-fee',
    generatePdf: 'company/project/sow/generatePdf',
    deleteExportHistory: '/company/project/sow/deleteExportHistory',
    updateLayout: '/company/project/sow/layout',
    resetDeleteSow: 'company/project/sow/resetAndDelete',
    consultingFirmFastTracContacts: 'company/project/sow/fastTrack-sow',
    companySowList: 'company/project/sowList/',
    generalProvisions: {
      get: '/general-provisions/fields',
      reorder: '/general-provisions/fields/reorder',
      delete: '/general-provisions/fields/',
      active: '/general-provisions/fields/status',
    },
    otherProvisions: {
      get: '/other-provisions/fields',
      reorder: '/other-provisions/fields/reorder',
      delete: '/other-provisions/fields/',
      active: '/other-provisions/fields/status',
    },
    getSignature: '/signature',
    signatureDocumentUpload: 'company/projects/{projectId}/sow/{sowId}/document',
    markExportHistoryAsFinal: '/company/project/sow/markAsFinal/',
    poNumber: 'projects/sow-poNumber',
  },
  nda: {
    nda: 'company/contract/nda',
    team: 'company/contract/nda/team',
    teamMember: 'company/contract/nda/team/member',
    layout: 'company/contract/nda/layout',
    companyNdaLayoutList: 'company/contract/nda/layout/list',
    terminology: 'company/contract/nda/preliminary/terminology',
    preliminary: 'company/contract/nda/preliminary',
    confidential: 'company/contract/nda/confidential',
    obligations: 'company/contract/nda/obligations',
    miscellaneousAndOtherClause: 'company/contract/nda/miscellaneousAndOtherClause',
    generatePdf: 'company/contract/nda/generatePdf',
    deleteExportHistory: '/company/contract/nda/deleteExportHistory',
    signature: 'company/contract/nda/sign',
    refDocType: 'company/contract/nda/document',
    validateField: 'company/contract/nda/validateField',
    commentCount: 'company/projects/nda/field/comment-count',
    reset: 'company/nda/contract/reset',
    generalProvisions: {
      get: '/general-provisions/fields',
      reorder: '/general-provisions/fields/reorder',
      delete: '/general-provisions/fields/',
      active: '/general-provisions/fields/status',
    },
    otherProvisions: {
      get: '/other-provisions/fields',
      reorder: '/other-provisions/fields/reorder',
      delete: '/other-provisions/fields/',
      active: '/other-provisions/fields/status',
    },
    terminationTerm: {
      get: '/term-termination',
    },
  },
  msa: {
    msa: 'contract/msa',
    layout: 'contract/msa/layout',
    pdf: 'contract/msa/exportHistory',
    reset: 'contract/msa/reset'
  },
  projectManagement: {
    projectManagement: 'company/projects/project-management',
    team: 'company/projects/project-management/team',
    teamMember: 'company/projects/project-management/team/member',
    objectivesAndDeliverables: 'company/projects/project-management/objectives-deliverables',
    milestones: 'company/projects/project-management/milestones',
    approveMilestones: 'company/projects/project-management/milestones/approve',
    requestMilestones: 'company/projects/project-management/milestones/request',
    requestInvoice: 'company/projects/project-management/invoicing/request',
    invoicing: 'company/projects/project-management/invoicing',
    uploadUrl: 'company/projects/project-management/upload-url',
    invoiceUploadUrl: 'company/projects/project-management/invoicing/upload-url',
    approveInvoicingMilestones: 'company/projects/project-management/invoicing/approve',
    updatePayment: 'company/projects/project-management/invoicing/payment',
    changes: 'company/projects/project-management/changes',
    validateAndApproveChanges: 'company/projects/project-management/changes/approve',
    refDocument: 'company/projects/project-management/refdocument',
    refDocType: 'company/projects/project-management/document',
    meetings: 'project-management/{projectId}/meetings',
    changeTitle: 'project-management/changes/{id}',
    latestChange: 'project-management/changes-preparation/{changeId}',
    latestChangeBySectionId: 'project-management/{changeId}/latestChanges',
    milestonAndInvoicingLatestChange: 'project-management/changes-preparation/{changeId}/milestone-and-invoicing/{sectionId}',
    invoicingLatestChange: 'project-management/changes-preparation/{changeId}/preparation-invoicing/{sectionId}'
  },
  // SoW Settings
  sowSettings: { general: 'company/sow/general', pdf: 'company/sow/pdf' },
  ProjectPerformance: {
    performance: 'company/projects/performance',
    visibility: 'company/perofrmance/visibility',
    survey: 'company/projects/performance/survey',
    impact: 'company/projects/performance/impact',
    document: 'company/projects/performance/document',
    summary: '/company/projects/performance/summary',
    scoreCard: 'company/projects/performance/scorecard',
    cost: 'company/projects/performance/cost-evolution',
    expectedImpact: 'company/projects/performance/expected-impact',
    projectTeam: 'company/projects/project-performance/team',
    teamMember: 'company/projects/project-performance/team/member',
    markAsCompleted: 'company/project/mark-as-completed',
  },
  strategicPriorities: 'company/demands/strategicPriorities',
  library: 'knowledge-center/library',
  knowledgeCenter: 'company/knowledge-center/documents',
  contracts: 'contract',
  meetings: 'firm/meetings',
  meeting: 'firm/meeting',
  overallMeetings: 'overallmeetings',
  firmContacts: 'firm/firmContacts',
  firmContact: 'firm/firmContact',
  specificInformation: 'firm/specificInformation',
  consultingFirm: {
    location: 'firm/general-info/location',
    consultingFirm: 'company/consultingFirm',
  },
  improveo: {
    suggestCompany: 'improveo/suggest-company',
    companies: 'improveo/companies',
  },
  fetchImproveoCompany: 'improveo/companies',
  fetchImproveCompanyInfo: 'improveo/company',
  updateSearchCompany: '/company/consultingFirm/link-to-company',
  linkedFirmListForDropdown: 'company/linkedFirmList',
  currency: {
    list: 'clients/currency',
    management: 'management/currency-list',
    project: 'project/currency',
  },
  messagePreferences: 'user/message-preferences',
  uploadSettings: 'admin/uploads/file-limit-settings',
  exportFileSettings: 'admin/uploads/file-export-settings',
  taskCategories: 'admin/task-categories',
  providerPerformance: '/company/provider-performance',
  enableProjectManagement: 'projects/enable-project-management',
  sendHelp: 'contact-support',
  announcement: 'project/announcement',
  updateOnboardingStatus: 'firm/{firmId}/changeOnboardingStatus',
};

const chatRoutes = {
  conversationRoom: 'chat/chat-room',
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  environment;
  private defaultOptions: object;
  private pdfOptions: object;
  private formOptions: object;
  public selectedEmailLanguageId: string = '';
  public selectedNotificationTemplateLanguageId: string = '';
  public selectedQuestionLanguageId: string = '';
  public notificationIntervalTimer: ReturnType<typeof setInterval>;
  private socketUrl: string;
  // For Approval Workflow Scenario Settings
  public settingsToggler: EventEmitter<object> = new EventEmitter<object>();
  public scenariosChangedEvent: EventEmitter<void> = new EventEmitter<void>();
  public scenarioDeleteEvent: EventEmitter<string> = new EventEmitter<string>();
  public viewAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public addAction$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public toggleFilterDrawer$: Subject<void> = new Subject<void>();
  public preparationIconClick$: Subject<{ changeId: string, tabName: string, type: string, firmId: string }> = new Subject<{ changeId: string, tabName: string, type: string, firmId: string }>();
  public toggleConsultingFirmDrawer$: Subject<ConsultingFirmDrawer> = new Subject();
  public toggleMeetingDrawer$: Subject<ConsultingFirmDrawer> = new Subject();
  public refreshFirmProfileInfoComponent$: Subject<string> = new Subject<string>();
  public updateGeneralInfo$: Subject<boolean> = new Subject();
  public filterSelect$: BehaviorSubject<PerformanceFilter> = new BehaviorSubject<PerformanceFilter>({} as PerformanceFilter);
  public filterMeetings$: BehaviorSubject<MeetingFilter> = new BehaviorSubject<MeetingFilter>({} as MeetingFilter);
  public filterContracts$: Subject<ContractsFilter> = new Subject<ContractsFilter>();
  public filterProjects$: Subject<ProjectFilter> = new Subject<ProjectFilter>();
  public refreshSubject: Subject<void> = new Subject<void>();
  public refreshLegalEntities: Subject<void> = new Subject<void>();
  public updateOnboardingInfo: Subject<{ firmId: string }> = new Subject<{ firmId: string }>();
  public refreshMeetingNatureList: Subject<void> = new Subject<void>();
  public updateTabIndex: Subject<number> = new Subject<number>();
  public refreshActionNatureList: Subject<void> = new Subject<void>();
  // Organisational Unit
  public organisationalUnitsChanged$: Subject<boolean> = new Subject();
  public organisationalUnitDelete$: Subject<string> = new Subject<string>();
  public enabledModulesForClient: EnabledModuleInfo = {} as EnabledModuleInfo;
  public currentUserInformation: any; // Need to find modify this wit proper interface
  public toggleLanguageDrawer$: Subject<LanguageDrawer> = new Subject<LanguageDrawer>();
  public toggleCountryDrawer$: Subject<CountryDrawer> = new Subject<CountryDrawer>();
  public toggleFileSettingsDrawer$: Subject<LanguageDrawer> = new Subject<LanguageDrawer>();
  public toggleCurrencyConversionDrawer$: Subject<ConversionDrawer> = new Subject<ConversionDrawer>();
  public refreshLanguageComp: Subject<void> = new Subject<void>();
  public refreshCountryComp: Subject<void> = new Subject<void>();
  public demandSaveButtonTrigger$: Subject<boolean> = new Subject<boolean>();
  public saveDemand$: Subject<boolean> = new Subject<boolean>();
  public rfpMilestone$: Subject<any[]> = new Subject<any[]>();
  public uploadedOtherFiles$: Subject<boolean> = new Subject<boolean>();
  public addFirmAction$: BehaviorSubject<FirmActionSubject> = new BehaviorSubject<FirmActionSubject>({} as FirmActionSubject);
  // Drawer Common Subject
  public openDrawer$: Subject<DrawerDetails> = new Subject<DrawerDetails>();
  public drawerClosed$: Subject<DrawerDetails> = new Subject<DrawerDetails>();
  public emitSelectionData$: BehaviorSubject<SourcingSelection[]> = new BehaviorSubject<SourcingSelection[]>([] as SourcingSelection[]);
  public companyProfileChanged$: Subject<boolean> = new Subject();
  public triggerChatWidget: Subject<string> = new Subject<string>();
  public triggerChatWidget$: Observable<string> = this.triggerChatWidget.asObservable();
  public updateEntityOnboardingInfo: Subject<{ firmId: string }> = new Subject<{ firmId: string }>();
  public s3PublicUrl: string;
  public reloadBriefing$: EventEmitter<boolean> = new EventEmitter();
  public currentProjectStepIndex: BehaviorSubject<number>;
  public currentProjectStepIndex$: Observable<number>;
  public trackProjectStep: BehaviorSubject<number>;
  public trackProjectStep$: Observable<number>;
  public updateInvitaionStatus$: BehaviorSubject<SourcingSelection[]> = new BehaviorSubject<SourcingSelection[]>([] as SourcingSelection[]);

  moduleIndices: object = {};
  allowedUrlList: string[] = [];

  private handleError(error: Response): any {
    if (error.status === 0 || error.statusText === 'Unknown Error') {
      this.notificationService.openErrorSnackBar('Check your Network connection. Please try again');
      return throwError({
        message: 'Check your Network connection. Please try again',
      });
    }
    return throwError(
      { ...get(error, 'error'), code: get(error, 'status') } || {
        message: 'Check your Network connection. Please try again',
      }
    );
  }

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private auth: AuthService,
    private permissionService: PermissionService,
    @Inject(DOCUMENT) private document: any,
    @Inject('AppEnvironment') private environmentConfig
  ) {
    this.currentProjectStepIndex = new BehaviorSubject<number>(0);
    this.currentProjectStepIndex$ = this.currentProjectStepIndex.asObservable();
    this.trackProjectStep = new BehaviorSubject<number>(0);
    this.trackProjectStep$ = this.trackProjectStep.asObservable();
    this.defaultOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      withCredentials: true,
    };
    this.pdfOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/pdf; application/json',
      }),
      withCredentials: true,
      responseType: 'blob' as 'json',
    };
    this.environment = environmentConfig;
    this.formOptions = {
      withCredentials: true,
    };
    this.socketUrl = this.environmentConfig.socketUrl;
    this.s3PublicUrl = this.environmentConfig.S3PublicUrl;
    // this.changeLanguage('ar-AE')
  }

  // Base http APIs
  httpGet(url: string): Observable<any> {
    return this.http.get<ApiResponse>(url, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  pdfHttpGet(url: string): Observable<any> {
    return this.http.get(url, this.pdfOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  httpPost(url: string, data?: object): Observable<any> {
    return this.http.post<ApiResponse>(url, data, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  httpPut(url: string, data?: any): Observable<any> {
    return this.http.put<ApiResponse>(url, data, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  httpDelete(url: string): Observable<any> {
    return this.http.delete<ApiResponse>(url, this.defaultOptions).pipe(
      map((res) => res),
      catchError((err) => this.handleError(err))
    );
  }

  login(values: LoginParams): Observable<ApiResponse> {
    return this.httpPost(auth.login, values);
  }

  checkToken(url: string): Observable<any> {
    return this.httpGet(url).pipe(
      map((res) => {
        this.currentUserInformation = res.data.user;
        if (res.data?.permissions) this.permissionService.setUserPermissionData(res.data.permissions);
        return res.data;
      }),
      catchError((error) => {
        return error.message;
      })
    );
  }

  /**
   * Gets current user information
   */
  getCurrentUserInformation() {
    if (!this.currentUserInformation?.role) {
      return this.checkToken('validateLogin');
    } else {
      return of(this.currentUserInformation);
    }
  }

  forgotpassword(values: any): Observable<ApiResponse> {
    return this.httpPost(auth.forgotPassword, values);
  }

  resetPassword(values): Observable<ApiResponse> {
    const params = new URLSearchParams();
    params.append('role', values.role);
    params.append('email', values.email);
    const url = `${auth.resetPassword}/${values.token}?${params.toString()}`;
    return this.httpGet(url);
  }

  updatePassword(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', values.role);
    params.append('email', values.email);
    const url = `${auth.resetPassword}/${values.token}?${params.toString()}`;
    return this.httpPut(url, { password: values.password });
  }

  checkValidInvite(values): Observable<ApiResponse> {
    const params = new URLSearchParams();
    params.append('firmOrClientToken', values.firmOrClientToken);
    params.append('email', values.email);
    params.append('isClientOrFirm', values.isClientOrFirm);
    const url = `${auth.checkValidInvite}?${params.toString()}`;
    return this.httpGet(url);
  }

  register(values: object): Observable<ApiResponse> {
    return this.httpPost(auth.register, values);
  }

  verifyEmail(token: string): Observable<any> {
    return this.httpGet(`${auth.verify}/${token}`);
  }

  logout(values = {}): Observable<any> {
    let url = auth.logout;
    if (get(values, 'role')) {
      const params = new URLSearchParams();
      params.append('role', get(values, 'role'));
      url = `${auth.logout}?${params.toString()}`;
    }
    return this.httpGet(url);
  }

  checkEmail(values: object): Observable<any> {
    return this.httpPost(auth.checkEmail, values);
  }

  createOrUpdateFirmDetails(values: object): Observable<any> {
    return this.httpPost(auth.firm, values);
  }

  getFirmDetails(): Observable<any> {
    return this.httpGet(auth.firm);
  }

  loadCountries(): Observable<any> {
    return this.httpGet(auth.country);
  }

  loadCountriesAdmin(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    params.append('role', CONSTANTS.admin);
    params.append('parentId', values?.parentId);
    return this.httpGet(`${auth.countries}?${params.toString()}`);
  }

  getCountry(id: string): Observable<any> {
    const url = `${auth.country}/${id}`;
    return this.httpGet(url);
  }

  createCountry(values: object): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${auth.country}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  updateCountry(values: object): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${auth.country}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  deleteCountry(id): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    params.append('id', id);
    const url = `${auth.country}?${params.toString()}`;
    return this.httpDelete(url);
  }

  updateProjectRescheduleDate(values): Observable<any> {
    const url = `${user.project}/${values.projectId}`;
    return this.httpPut(url, values);
  }

  getCommonList(): Observable<any> {
    return this.httpGet(auth.commonList);
  }

  saveOrganisationTags(values): Observable<any> {
    return this.httpPost(auth.organisationTag, values);
  }

  deleteOrganisationTags(values): Observable<any> {
    return this.httpPut(auth.organisationTag, values);
  }

  saveBrands(values): Observable<any> {
    return this.httpPost(auth.brand, values);
  }

  deleteBrands(values): Observable<any> {
    return this.httpPut(auth.brand, values);
  }

  getProfileDetails(role = CONSTANTS.user): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', role);
    const url = `${auth.profile}/?${params.toString()}`;
    return this.httpGet(url);
  }

  getFirmUser(): Observable<any> {
    return this.httpGet(user.firmUser);
  }

  updateFirmUser(userDetails): Observable<any> {
    return this.httpPut(user.firmUser, userDetails);
  }

  getCount(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', values.role);
    const url = `${admin.count}?${params.toString()}`;
    return this.httpGet(url);
  }

  getIndustries(): Observable<any> {
    return this.httpGet(admin.industry);
  }

  getIndustryListWithPagination(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    params.append('parentId', values?.parentId);
    params.append('role', CONSTANTS.admin);
    return this.httpGet(`${admin.industries}?${params.toString()}`);
  }

  getCapabilities(): Observable<any> {
    return this.httpGet(admin.capability);
  }

  getCapabilityListWithPagination(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    params.append('parentId', values?.parentId);
    params.append('role', CONSTANTS.admin);
    return this.httpGet(`${admin.capabilities}?${params.toString()}`);
  }

  getRegions(): Observable<any> {
    return this.httpGet(admin.region);
  }

  getRegionListWithPagination(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    params.append('parentId', values?.parentId);
    params.append('role', CONSTANTS.admin);
    return this.httpGet(`${admin.regions}?${params.toString()}`);
  }

  saveCapabilities(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.capability}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  updateCapabilities(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.capability}/update?${params.toString()}`;
    return this.httpPost(url, values);
  }

  moveSubCapabilities(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.capability}/update?${params.toString()}`;
    return this.httpPut(url, values);
  }

  deleteCapabilities(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.capability}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  saveIndustries(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.industry}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  updateIndustries(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.industry}/update?${params.toString()}`;
    return this.httpPost(url, values);
  }

  moveSubIndustries(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.industry}/update?${params.toString()}`;
    return this.httpPut(url, values);
  }

  deleteIndustries(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.industry}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  saveRegions(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.region}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  updateRegions(values): Observable<any> {
    const id = values?.id;
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.region}/${id}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  deleteRegions(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.region}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  activateOrDeactivateClient(values: object): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.clientList}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  activateOrDeactivateCompanyFirm(values: object): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${auth.firm}/status?${params.toString()}`;
    return this.httpPut(url, values);
  }

  saveConavigoId(firmId: string, data: object): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${auth.firm}/${firmId}?${params.toString()}`;
    return this.httpPut(url, data);
  }

  changepassword(values: object): Observable<any> {
    let url = auth.changePassword;
    if (get(values, 'role')) {
      const params = new URLSearchParams();
      params.append('role', get(values, 'role'));
      url = `${auth.changePassword}?${params.toString()}`;
    }
    return this.httpPut(url, values);
  }

  getUnVerifiedUsers(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('role', CONSTANTS.admin);
    const url = `${admin.unVerifiedUsers}?${params.toString()}`;
    return this.httpGet(url);
  }

  deleteUnVerifiedUsers(values: object): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.unVerifiedUsers}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  getUsersList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    if (!isEmpty(get(values, 'filter.role', []))) {
      _map(values.filter.role, (role) => {
        if (role !== undefined) params.append('roleFilter', role);
      });
    }
    if (!isNull(get(values, 'filter.status', null))) {
      params.append('status', values.filter.status);
    }
    if (!isEmpty(get(values, 'filter.organisationTag', []))) {
      _map(values.filter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(values, 'filter.brand', []))) {
      _map(values.filter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }
    const url = `${user.user}?${params.toString()}`;
    return this.httpGet(url);
  }

  deleteUserList(values: object): Observable<any> {
    return this.httpPut(user.deleteUser, values);
  }

  sendUserInviteRequestToAdminClientCompany(values: object): Observable<any> {
    return this.httpPost(`${user.client}/user/invite`, values);
  }

  getClientUserInviteRequests(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    return this.httpGet(`client/user/invite-requests?${params.toString()}`);
  }

  /**
   * Approves/Rejects the invite request
   * @param values
   * @returns
   */
  reviewUserInviteRequest(values): Observable<any> {
    return this.httpPut(`client/user/invite-requests`, values);
  }

  resendInvite(values: {}): Observable<any> {
    return this.httpPost(user.resendInvite, values);
  }

  getTeamList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('search', get(values, 'search', ''));
    const url = `${user.getTeamList}?${params.toString()}`;
    return this.httpGet(url);
  }

  getNotification(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('isOnlyUnread', values?.isOnlyUnread);
    params.append('category', values?.category);
    if (values?.startDate && values?.endDate) {
      params.append('startDate', values?.startDate);
      params.append('endDate', values?.endDate);
    }
    const url = `${user.notification}?${params.toString()}`;
    return this.httpGet(url);
  }

  readNotification(): Observable<any> {
    return this.httpPut(user.notification, '');
  }

  getTeamMemberDetails(id: string): Observable<any> {
    return this.httpGet(`${user.teamMemberDetails}/${id}`);
  }

  getClientSatisfactionList(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }
    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }
    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }
    if (!isEmpty(get(performanceFilter, 'partner', []))) {
      _map(performanceFilter.partner, (partner) => {
        if (partner !== undefined) params.append('partner', partner);
      });
    }

    if (!isEmpty(get(performanceFilter, 'client', []))) {
      _map(performanceFilter.client, (client) => {
        if (client !== undefined) params.append('client', client);
      });
    }
    const url = `${user.clientSatisfactionList}?${params.toString()}`;
    return this.httpGet(url);
  }

  getConsultingScoreData(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('type', get(values, 'type', 5));
    params.append('clientPriority', get(values, 'clientPriority', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }
    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }
    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }
    if (!isEmpty(get(performanceFilter, 'partner', []))) {
      _map(performanceFilter.partner, (partner) => {
        if (partner !== undefined) params.append('partner', partner);
      });
    }

    if (!isEmpty(get(performanceFilter, 'client', []))) {
      _map(performanceFilter.client, (client) => {
        if (client !== undefined) params.append('client', client);
      });
    }
    const url = `${user.consultingScoreData}?${params.toString()}`;
    return this.httpGet(url);
  }

  getScoreCardData(projectId, isEmbedded: boolean = false, apiKey: string = ''): Observable<any> {
    const params = new URLSearchParams();
    let url;
    params.append('projectId', projectId);
    if (isEmbedded) {
      params.append('apiKey', apiKey);
      params.append('isEmbedded', 'true');
      url = `${user.embeddableProjectScoreCard}?${params.toString()}`;
    } else {
      url = `${user.projectScoreCard}?${params.toString()}`;
    }
    return this.httpGet(url);
  }

  getProjectPerformanceData(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('type', get(values, 'type', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }
    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }
    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }
    if (!isEmpty(get(performanceFilter, 'partner', []))) {
      _map(performanceFilter.partner, (partner) => {
        if (partner !== undefined) params.append('partner', partner);
      });
    }

    if (!isEmpty(get(performanceFilter, 'client', []))) {
      _map(performanceFilter.client, (client) => {
        if (client !== undefined) params.append('client', client);
      });
    }
    const url = `${user.projectPerformanceData}?${params.toString()}`;
    return this.httpGet(url);
  }

  getKeyDriversValue(values: object, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }
    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }
    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }
    if (!isEmpty(get(performanceFilter, 'partner', []))) {
      _map(performanceFilter.partner, (partner) => {
        if (partner !== undefined) params.append('partner', partner);
      });
    }

    if (!isEmpty(get(performanceFilter, 'client', []))) {
      _map(performanceFilter.client, (client) => {
        if (client !== undefined) params.append('client', client);
      });
    }
    const url = `${user.keyDrivers}?${params.toString()}`;
    return this.httpGet(url);
  }

  getContactsList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('projectId', values.projectId);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    const status = get(values, 'contactStatus', 3) === 3 ? '' : get(values, 'contactStatus', 3);
    params.append('status', status);
    const url = `${user.contact}/?${params.toString()}`;
    return this.httpGet(url);
  }

  getFirmContactsList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    const firmId = values.firmId;
    const url = `${client.firmContacts}/${firmId}?${params.toString()}`;
    return this.httpGet(url);
  }

  getFirmContact(firmId: string, contactId: string): Observable<any> {
    const url = `${client.firmContact}/${firmId}?id=${contactId}`;
    return this.httpGet(url);
  }

  createOrUpdateFirmContact(firmId: string, values): Observable<any> {
    return this.httpPost(`${client.firmContact}/${firmId}`, values);
  }

  validateEmailExistance(email: string): Observable<any> {
    return this.httpGet(`${user.validateEmail}/${email}`);
  }

  deleteFirmContact(firmId: string, contactId: string[], confirmDelete = false): Observable<any> {
    return this.httpPut(`${client.firmContact}/${firmId}`, {
      _id: contactId,
      confirmDelete: confirmDelete,
    });
  }

  getSurveyAnswers(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('id', values.id);
    params.append('projectId', values.projectId);
    params.append('contactId', values.contactId);
    const url = `${user.surveyAnswer}?${params.toString()}`;
    return this.httpGet(url);
  }

  getProjectById(id: string, isFromProjectList: boolean = false, isSignature?: boolean): Observable<any> {
    const params = new URLSearchParams();
    params.append('isFromProjectList', isFromProjectList.toString());
    if (isSignature) params.append('isSignature', isSignature?.toString());
    return this.httpGet(`${user.project}\/${id}?${params.toString()}`);
  }

  updateProfileDetails(values: object): Observable<any> {
    return this.httpPut(auth.profile, values);
  }

  updateFirmSettings(values: object): Observable<any> {
    return this.httpPut(user.firmSettings, values);
  }

  getRescheduleReason(id: string): Observable<any> {
    return this.httpGet(`${user.reschedule}\/${id}`);
  }

  // Client List for Add Project
  getClient(): Observable<any> {
    const url = user.clientDetails;
    return this.httpGet(url);
  }

  getPartnerList(): Observable<any> {
    return this.httpGet(user.partner);
  }

  /**
   * Gets clients list for super admin
   * @param values Pagination/Filter Values
   * @returns List of clients in the application
   */
  getClientsListForAdmin(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('role', CONSTANTS.admin);
    if (get(values, 'filter.industry', []).length) {
      values.filter.industry.map((industry) => {
        params.append('industry', industry);
      });
    }
    if (get(values, 'filter.capability', []).length) {
      values.filter.capability.map((capability) => {
        params.append('capability', capability);
      });
    }
    if (get(values, 'filter.region', []).length) {
      values.filter.region.map((region) => {
        params.append('region', region);
      });
    }
    if (get(values, 'filter.country', []).length) {
      values.filter.country.map((country) => {
        params.append('country', country);
      });
    }
    const url = `${admin.clientList}?${params.toString()}`;
    return this.httpGet(url);
  }

  loadDemographyList(): Observable<any> {
    return this.httpGet(user.demographyList).pipe(map((res) => res.data));
  }

  deleteClientsList(values: object): Observable<any> {
    return this.httpPut(user.client, values);
  }

  getUser(id: string): Observable<any> {
    return this.httpGet(`${user.user}\/${id}`);
  }

  createUser(values: object): Observable<any> {
    return this.httpPost(user.user, values);
  }

  updateUser(values: object): Observable<any> {
    return this.httpPut(user.user, values);
  }

  loadDemographyListForProject(id: string): Observable<any> {
    return this.httpGet(`${user.demographyListForProject}/${id}`).pipe(map((res) => res.data));
  }

  createOrUpdateClientDetails(values: object): Observable<any> {
    return this.httpPost(user.client, values);
  }

  rejectClient(id: string, values: object): Observable<any> {
    return this.httpPost(`${user.client}\/${id}`, values);
  }

  getUserDetails(): Observable<any> {
    return this.httpGet(user.userDetails).pipe(map((res) => res.data));
  }

  getClientById(id: string): Observable<any> {
    return this.httpGet(`${user.client}\/${id}`);
  }

  sendSurvey(id): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', id);
    const url = `${user.sendSurvey}?${params.toString()}`;
    return this.httpGet(url);
  }

  getAdminDashboardData(values): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(get(values, 'organisationTagId', []))) {
      _map(values.organisationTagId, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTagsId', organisationTag);
      });
    }

    if (!isEmpty(get(values, 'brandId', []))) {
      _map(values.brandId, (brandsId) => {
        if (brandsId !== undefined) params.append('brandId', brandsId);
      });
    }

    if (!isEmpty(get(values, 'projectName', ''))) {
      params.append('projectName', values.projectName);
    }

    if (!isEmpty(get(values, 'industryId', []))) {
      _map(values.industryId, (industry) => {
        if (industry !== undefined) params.append('industryId', industry);
      });
    }

    if (!isEmpty(get(values, 'capabilityId', []))) {
      _map(values.capabilityId, (capability) => {
        if (capability !== undefined) params.append('capabilityId', capability);
      });
    }

    if (!isEmpty(get(values, 'clientId', []))) {
      _map(values.clientId, (clientId) => {
        if (clientId !== undefined) params.append('clientId', clientId);
      });
    }
    params.append('isFilterType', get(values, 'isFilterType', ''));
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('revenueFilterType', get(values, 'revenueFilterType', 1));
    const url = `${user.adminDashboard}?${params.toString()}`;
    return this.httpGet(url);
  }

  getDashboardData(values, filterValues): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(get(filterValues, 'organisationTagId', []))) {
      _map(filterValues.organisationTagId, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTagsId', organisationTag);
      });
    }

    if (!isEmpty(get(filterValues, 'brandId', []))) {
      _map(filterValues.brandId, (brandsId) => {
        if (brandsId !== undefined) params.append('brandId', brandsId);
      });
    }

    if (!isEmpty(get(filterValues, 'projectName', ''))) {
      params.append('projectName', filterValues.projectName);
    }

    if (!isEmpty(get(filterValues, 'industryId', []))) {
      _map(filterValues.industryId, (industry) => {
        if (industry !== undefined) params.append('industryId', industry);
      });
    }

    if (!isEmpty(get(filterValues, 'capabilityId', []))) {
      _map(filterValues.capabilityId, (capability) => {
        if (capability !== undefined) params.append('capabilityId', capability);
      });
    }

    if (!isEmpty(get(filterValues, 'clientId', []))) {
      _map(filterValues.clientId, (clientId) => {
        if (clientId !== undefined) params.append('clientId', clientId);
      });
    }
    params.append('isFilterType', get(filterValues, 'isFilterType', ''));
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('toggleType', get(values, 'toggleValue', ''));
    params.append('revenueFilterType', get(values, 'revenueFilterType', 1));
    const url = `${user.dashboardDetails}?${params.toString()}`;
    return this.httpGet(url);
  }

  getOtherRegionForClientFilter(): Observable<any> {
    return this.httpGet(user.otherRegion);
  }

  getMemberList(): Observable<any> {
    return this.httpGet(user.member);
  }

  getSurveyQuestion(id): Observable<any> {
    const params = new URLSearchParams();
    params.append('id', id);
    const url = `${user.survey}?${params.toString()}`;
    return this.httpGet(url);
  }

  verifySurveyLink(token: string): Observable<any> {
    const url = `${user.survey}/${token}`;
    return this.httpGet(url);
  }

  verifyOtp(values: object, token: string): Observable<any> {
    const url = `${user.survey}/${token}`;
    return this.httpPost(url, values);
  }

  saveSurveyAnswers(values): Observable<any> {
    const url = `${user.survey}/${values.token}`;
    return this.httpPut(url, values);
  }

  /**
   * To verify and return the JWT Token details
   * @param token JWT Token of invited user
   * @returns
   */
  verifySourcingLink(token: string): Observable<any> {
    return this.httpGet(`${user.sourcing}/${token}`);
  }

  /**
   * Verify password reset link for user
   * @param token JWT Token of user
   * @returns
   */
  verifyResetPasswordLink(token: string): Observable<any> {
    return this.httpGet(`${user.userPasswordReset}/${token}`);
  }

  /**
   * Approve/Reject Project request for Firm
   * @param token JWT Token
   * @param value details
   * @returns
   */
  sourcingApproval(token: string, value): Observable<any> {
    return this.httpPut(`${user.sourcing}/${token}`, value);
  }

  // Client(company) Section Api

  // Client(company)
  getClientDetails(): Observable<any> {
    return this.httpGet(client.company);
  }

  // Add, edit, delete and Get user details
  // To get users list for client
  getClientUsersList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    if (!isEmpty(get(values, 'filter.role', []))) {
      _map(values.filter.role, (role) => {
        params.append('roleFilter', role);
      });
    }
    if (!isNull(get(values, 'filter.status', null))) {
      params.append('status', values.filter.status);
    }
    if (get(values, 'filter.organisationTag', []).length) {
      params.append('organisationTag', values.filter.organisationTag.join(','));
    }
    if (get(values, 'filter.costCenter', []).length) {
      params.append('costCenters', values.filter.costCenter.join(','));
    }
    if (get(values, 'filter.brand', []).length) {
      params.append('brand', values.filter.brand.join(','));
    }
    // Removed Segments and 3rd Dimensions in v/1.30
    // if (get(values, 'filter.segments', []).length) {
    //   params.append('segments', values.filter.segments.join(','));
    // }
    // if (get(values, 'filter.thirdDimensions', []).length) {
    //   params.append('thirdDimensions', values.filter.thirdDimensions.join(','));
    // }
    if (get(values, 'filter.functions', []).length) {
      params.append('functions', values.filter.functions.join(','));
    }
    const url = `${client.clientUser}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get user dtails by Id
  getClientUserById(id: string): Observable<any> {
    return this.httpGet(`${client.clientUser}\/${id}`);
  }

  /**
   * To get User List
   */
  getActiveUserList(page: number, id: string, isClient: boolean, search: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('isClient', isClient.toString());
    params.append('search', search);
    params.append('page', page.toString());
    return this.httpGet(`${client.activeUserList}/${id}?${params.toString()}`);
  }

  // To create user for client
  createClientUser(values: object): Observable<any> {
    return this.httpPost(client.clientUser, values);
  }

  // To update user for client
  updateClientUser(values: object): Observable<any> {
    return this.httpPut(client.clientUser, values);
  }

  // To delete user from userList for client
  deleteClientUserList(values: object): Observable<any> {
    return this.httpPut(client.deleteClientUser, values);
  }

  // To resend Invite for client users
  resendInviteForClientUser(values: {}): Observable<any> {
    return this.httpPost(client.resendInviteForClientUser, values);
  }

  // To get projects about end and get over due actions.
  getCompanyProgress(data: { date: string; startDate: string; endDate: string }): Observable<any> {
    const params = new URLSearchParams();
    params.append('date', data.date);
    params.append('startDate', data.startDate);
    params.append('endDate', data.endDate);
    return this.httpGet(`${client.progress}?${params.toString()}`);
  }

  // To get Client admin profile response
  getClientProfileDetails(role = CONSTANTS.user): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', role);
    const url = `${client.clientProfile}/?${params.toString()}`;
    return this.httpGet(url);
  }

  // To update Client admin Profile
  updateClientProfileDetails(values: object): Observable<any> {
    return this.httpPut(client.clientProfile, values);
  }

  // To get consulting Firm list for company
  getConsultingFirmList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('status', get(values, 'status', ''));
    params.append('toggleType', get(values, 'toggleValue', ''));
    if (!isEmpty(get(values, 'filter.industry', []))) {
      _map(values.filter.industry, (industry) => {
        params.append('industry', industry);
      });
    }
    if (!isEmpty(get(values, 'filter.partner', []))) {
      _map(values.filter.partner, (partner) => {
        params.append('partner', partner);
      });
    }
    if (!isEmpty(get(values, 'filter.capability', []))) {
      _map(values.filter.capability, (capability) => {
        params.append('capability', capability);
      });
    }
    if (!isEmpty(get(values, 'filter.region', []))) {
      _map(values.filter.region, (region) => {
        params.append('region', region);
      });
    }
    if (!isEmpty(get(values, 'filter.state', []))) {
      _map(values.filter.state, (state) => {
        params.append('state', state);
      });
    }
    if (!isEmpty(get(values, 'filter.other', []))) {
      _map(values.filter.other, (other) => {
        params.append('other', other);
      });
    }
    const url = `${client.consultingFirm.consultingFirm}?${params.toString()}`;
    return this.httpGet(url);
  }

  // used to get all demographies list for client(company)
  demographyListForCompany(): Observable<any> {
    return this.httpGet(client.demographyListForCompany).pipe(map((res) => res.data));
  }

  getCompanyCurrency(projectId = ''): Observable<any> {
    const params = new URLSearchParams();
    if (projectId) {
      params.append('projectId', projectId);
    }
    const date = moment(new Date()).hours(0).minutes(0).seconds(0).toDate();
    params.append('date', date.toISOString());
    return this.httpGet(`${client.companyCurrency}?${params.toString()}`);
  }

  // To get common List for company
  getCommonListForCompany(): Observable<any> {
    return this.httpGet(client.commonListForCompany);
  }

  // To get consulting Firm by Id for company
  getConsultingFirmById(id: string): Observable<any> {
    return this.httpGet(`${client.consultingFirm.consultingFirm}\/${id}`);
  }

  getConsultingFirmByIdforProfile(value: any): Observable<any> {
    const params = new URLSearchParams();
    params.append('isConsultingFirmProfile', get(value, 'isConsultingFirmProfile', 'false'));
    return this.httpGet(`${client.consultingFirm.consultingFirm}\/${value.id}?${params.toString()}`);
  }

  // used create and update consulting firm
  createOrUpdateConsultingFirmDetails(values: object, isSpecificInformation: boolean = false): Observable<any> {
    const params = new URLSearchParams();
    params.append('isSpecificInformation', `${isSpecificInformation}`);
    return this.httpPost(`${client.consultingFirm.consultingFirm}?${params.toString()}`, values);
  }

  /**
   * @param companyId string - consultingFirmId
   * @param imageUploadType one among the UploadType enum value
   * Deletes the profile/Cover or any photo of a given company profile
   * @returns Delete Action Response
   */
  public deleteCompanyProfilePhoto(companyId: string, imageUploadType: UploadType) {
    return this.httpDelete(`${client.consultingFirm.consultingFirm}/profile/${companyId}/photo?imageUploadType=${imageUploadType}`);
  }

  // To reject consulting firm by Id for company
  rejectConsultingFirm(id: string, values: object): Observable<any> {
    return this.httpPost(`${client.consultingFirm.consultingFirm}\/${id}`, values);
  }

  // used to delete consulting firm
  deleteConsultingFirmList(values: object): Observable<any> {
    return this.httpPut(client.consultingFirm.consultingFirm, values);
  }
  // To add & delete organisation tag in client
  saveClientOrganisationTags(values): Observable<any> {
    return this.httpPost(client.clientOrganisationTag, values);
  }

  deleteClientOrganisationTags(values): Observable<any> {
    return this.httpPut(client.clientOrganisationTag, values);
  }

  // To add & delete brand in client
  saveClientBusinessUnit(values): Observable<any> {
    return this.httpPost(client.clientBrand, values);
  }

  deleteClientBusinessUnit(values): Observable<any> {
    return this.httpPut(client.clientBrand, values);
  }

  // To add & delete segments in client
  //  Remove segment in v/1.30
  // saveClientSegments(values): Observable<any> {
  //   return this.httpPost(client.clientSegments, values);
  // }

  // deleteClientSegments(values): Observable<any> {
  //   return this.httpPut(client.clientSegments, values);
  // }

  // To add & delete functions in client
  saveClientFunctions(values): Observable<any> {
    return this.httpPost(client.clientFunctions, values);
  }

  deleteClientFunctions(values): Observable<any> {
    return this.httpPut(client.clientFunctions, values);
  }

  // To add & delete cost centers in client
  saveClientCostCenters(values): Observable<any> {
    return this.httpPost(client.clientCostCenters, values);
  }

  deleteClientCostCenters(values): Observable<any> {
    return this.httpPut(client.clientCostCenters, values);
  }

  // To add & delete third dimensions in client
  //  Remove 3rd dimensions in v/1.30
  // saveClientThirdDimensions(values): Observable<any> {
  //   return this.httpPost(client.clientThirdDimension, values);
  // }

  // deleteClientThirdDimensions(values): Observable<any> {
  //   return this.httpPut(client.clientThirdDimension, values);
  // }

  // // Change third dimension name for company
  // changeThirdDimensionNameForCompany(values): Observable<any> {
  //   return this.httpPost(`${client.clientThirdDimension}/name`, values);
  // }

  // To update client other settings
  updateClientSettings(values: object): Observable<any> {
    return this.httpPut(client.companySettings, values);
  }

  // Project Performance for client
  getProjectPerformanceDataForCompany(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('type', get(values, 'type', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }
    const url = `${client.companyProjectPerformance}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get consulting score for client
  getClientConsultingScoreData(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('type', get(values, 'type', 5));
    params.append('clientPriority', get(values, 'clientPriority', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }
    const url = `${client.companyConsultingScore}?${params.toString()}`;
    return this.httpGet(url);
  }

  getProjectRequests(values, userReq): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('userRequest', userReq);

    if (!isEmpty(get(values, 'filter.consultingFirm', []))) {
      _map(values.filter.consultingFirm, (client) => {
        params.append('firmId', client);
      });
    }
    if (!isEmpty(get(values, 'filter.partner', []))) {
      _map(values.filter.partner, (partner) => {
        params.append('partner', partner);
      });
    }
    if (!isEmpty(get(values, 'filter.requestStartDate', null))) {
      params.append('requestStartDate', values.filter.requestStartDate);
    }
    if (!isEmpty(get(values, 'filter.requestEndDate', null))) {
      params.append('requestEndDate', values.filter.requestEndDate);
    }
    if (!isNaN(get(values, 'filter.requestStatus', ''))) {
      params.append('requestStatus', get(values, 'filter.requestStatus', ''));
    }
    if (!isNaN(get(values, 'filter.minBudget', ''))) {
      params.append('minBudget', get(values, 'filter.minBudget', ''));
    }
    if (!isNaN(get(values, 'filter.maxBudget', ''))) {
      params.append('maxBudget', get(values, 'filter.maxBudget', ''));
    }
    if (!isEmpty(get(values, 'filter.capability', []))) {
      _map(values.filter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }
    if (!isEmpty(get(values, 'filter.country', []))) {
      _map(values.filter.country, (country) => {
        if (country !== undefined) params.append('country', country);
      });
    }
    if (!isEmpty(get(values, 'filter.industry', []))) {
      _map(values.filter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }
    if (!isEmpty(get(values, 'filter.brand', []))) {
      _map(values.filter.brand, (brand) => {
        if (brand !== undefined) params.append('businessUnit', brand);
      });
    }
    if (!isEmpty(get(values, 'filter.region', []))) {
      _map(values.filter.region, (region) => {
        if (region !== undefined) params.append('region', region);
      });
    }
    const url = `${client.projectRequest}?${params.toString()}`;
    return this.httpGet(url);
  }

  deleteProjectRequestById(id: Object) {
    const url = `${client.projectRequest}`;
    return this.httpPut(url, id);
  }
  // Create or update project request for client
  createOrUpdateProjectRequestForCompany(values: object): Observable<any> {
    return this.httpPost(client.projectRequest, values);
  }

  // To get company project by ID
  getCompanyProjectRequestById(id: string): Observable<any> {
    return this.httpGet(`${client.projectRequest}\/${id}`);
  }

  getProjectsListCompany(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('toggleType', get(values, 'toggleValue', ''));
    if (!isEmpty(get(values, 'filter.consultingFirm', []))) {
      _map(values.filter.consultingFirm, (client) => {
        params.append('firmId', client);
      });
    }
    if (!isEmpty(get(values, 'filter.partner', []))) {
      _map(values.filter.partner, (partner) => {
        params.append('inchargeId', partner);
      });
    }
    if (!isEmpty(get(values, 'filter.organisationTag', []))) {
      _map(values.filter.organisationTag, (organisationTag) => {
        params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(values, 'filter.brand', []))) {
      _map(values.filter.brand, (brand) => {
        params.append('brand', brand);
      });
    }
    if (!isEmpty(get(values, 'filter.startStartDate', null))) {
      params.append('startStartDate', values.filter.startStartDate);
    }
    if (!isEmpty(get(values, 'filter.startEndDate', null))) {
      params.append('startEndDate', values.filter.startEndDate);
    }
    if (!isEmpty(get(values, 'filter.endStartDate', null))) {
      params.append('endStartDate', values.filter.endStartDate);
    }
    if (!isEmpty(get(values, 'filter.endEndDate', null))) {
      params.append('endEndDate', values.filter.endEndDate);
    }
    if (!isEmpty(get(values, 'filter.step', []))) {
      _map(values.filter.step, (step) => {
        params.append('step', step);
      });
    }
    if (values?.filter?.requestStartDate) {
      params.append('requestStartDate', values.filter.requestStartDate);
    }
    if (values?.filter?.requestEndDate) {
      params.append('requestEndDate', values.filter.requestEndDate);
    }
    const url = `${client.project}?${params.toString()}`;
    return this.httpGet(url);
  }

  // Consulting firm List for Company Project -- FUTURE move to company service
  getConsultingFirm(values, isForFilter = false): Observable<any> {
    return this.httpGet(`${client.linkedFirmListForDropdown}?isForFilter=${isForFilter}`);
  }

  activateOrDeactivate(id: string, isChecked: boolean): Observable<any> {
    return this.httpPut(client.firm, { id: [id], isChecked });
  }

  // To get Company Partner List
  getCompanyPartnerList(): Observable<any> {
    return this.httpGet(client.clientPartner);
  }

  // To get Company reschedule reason
  getCompanyRescheduleReason(id: string): Observable<any> {
    return this.httpGet(`${client.rescheduleReasonForCompany}\/${id}`);
  }

  // To delete company project
  deleteCompanyProjects(values: object): Observable<any> {
    return this.httpPut(client.project, values);
  }

  // To get company user details
  getCompanyUserDetails(): Observable<any> {
    return this.httpGet(client.userDetails).pipe(map((res) => res.data));
  }

  // To get company project by ID
  getCompanyProjectById(id: string, isForFT = '', fastTrackTo = ''): Observable<any> {
    const params = new URLSearchParams();
    params.append('fastTrackTo', fastTrackTo);
    params.append('isForFT', isForFT);
    return this.httpGet(`${client.project}/${id}?${params.toString()}`);
  }

  // To get demography list for company project
  loadDemographyListForCompanyProject(id: string): Observable<any> {
    return this.httpGet(`${client.firmDemographyListForProjects}/${id}`).pipe(map((res) => res.data));
  }

  // To retrive company managers
  getCompanyManagers(): Observable<any> {
    return this.httpGet(`${client.approvers}`);
  }

  // Create or update project for client
  createOrUpdateProjectDetailsForCompany(values: object): Observable<any> {
    return this.httpPost(client.project, values);
  }

  // Create or update project for client
  updateProjectHomeDetails(values: object): Observable<any> {
    return this.httpPut(client.updateHomeDetails, values);
  }

  // Get project key information
  getProjectKeyInformation(id: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', id);
    const url = `${client.updateHomeDetails}?${params.toString()}`;
    return this.httpGet(url);
  }

  // send survey for client projects
  sendSurveyForCompany(id): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', id);
    const url = `${client.sendSurvey}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get score card data for client company
  getScoreCardDataCompany(projectId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', projectId);
    const url = `${client.projectScoreCardCompany}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get contact list for client company
  getCompanyContactsList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('projectId', values.projectId);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    const status = get(values, 'contactStatus', 3) === 3 ? '' : get(values, 'contactStatus', 3);
    params.append('status', status);
    const url = `${client.clientContact}/?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get survey answer for client company
  getCompanySurveyAnswers(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('id', values.id);
    params.append('projectId', values.projectId);
    params.append('contactId', values.contactId);
    const url = `${client.clientSurveyAnswer}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get consulting spend data for client company
  getConsultingSpendDataForCompany(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));

    if (isNumber(get(performanceFilter, 'isPaid', ''))) {
      params.append('isPaid', `${performanceFilter.isPaid}`);
    }
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    const url = `${client.consultingSpendForCompany}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get consulting panel values for client company
  getConsultingPanelValuesForCompany(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('xAxisFilter', get(values, 'xAxisFilter', 1));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }
    const url = `${client.consultingPanelForCompany}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get High/Low performers values for client company
  getHighLowPerformersForCompany(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('xAxisFilter', get(values, 'xAxisFilter', 5));
    params.append('yAxisFilter', get(values, 'yAxisFilter', 5));

    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }
    const url = `${client.highLowPerformers}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get Client Admin Dashboard values for company
  getClientAdminDashboardData(values): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(get(values, 'projectName', ''))) {
      params.append('projectName', values.projectName);
    }
    if (!isEmpty(get(values, 'organisationTagId', []))) {
      _map(values.organisationTagId, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTagsId', organisationTag);
      });
    }

    if (!isEmpty(get(values, 'brandId', []))) {
      _map(values.brandId, (brand) => {
        if (brand !== undefined) params.append('brandId', brand);
      });
    }

    if (!isEmpty(get(values, 'industryId', []))) {
      _map(values.industryId, (industry) => {
        if (industry !== undefined) params.append('industryId', industry);
      });
    }

    if (!isEmpty(get(values, 'capabilityId', []))) {
      _map(values.capabilityId, (capability) => {
        if (capability !== undefined) params.append('capabilityId', capability);
      });
    }

    if (!isEmpty(get(values, 'regions', []))) {
      _map(values.regions, (region) => {
        if (region !== undefined) params.append('regions', region);
      });
    }

    if (!isEmpty(get(values, 'consultingFirmId', []))) {
      _map(values.consultingFirmId, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirmId', consultingFirm);
      });
    }
    params.append('isFilterType', get(values, 'isFilterType', ''));
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('revenueFilterType', get(values, 'revenueFilterType', 1));
    params.append('offset', get(values, 'offset', ''));
    params.append('screenType', get(values, 'type', ''));
    params.append('isFromActivity', get(values, 'isFromActivity', undefined));
    if (get(values, 'chartsType', '') > -1) params.append('chartsType', get(values, 'chartsType', ''));
    const url = `${client.clientAdminDashboard}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get action analytics report
  getActionAnalyticsData(values, filterValues, type): Observable<any> {
    const params = new URLSearchParams();
    // TODO: Uncomment this when it is needed
    // if (!isEmpty(get(values, 'category', []))) {
    //   _map(values.category, (category) => {
    //     params.append('category', category);
    //   });
    // }

    if (!isEmpty(get(values, 'owner', []))) {
      _map(values.owner, (owner) => {
        params.append('owner', owner);
      });
    }

    if (isNumber(get(values, 'status'))) {
      params.append('status', get(values, 'status'));
    }

    if (!isEmpty(get(values, 'createdBy', []))) {
      _map(values.createdBy, (createdBy) => {
        params.append('createdBy', createdBy);
      });
    }

    if (!isEmpty(get(values, 'assignedDateStart'))) {
      params.append('assignedDate.start', get(values, 'assignedDateStart'));
    }

    if (!isEmpty(get(values, 'assignedDateEnd'))) {
      params.append('assignedDate.end', get(values, 'assignedDateEnd'));
    }

    if (!isEmpty(get(values, 'dueDateStart'))) {
      params.append('dueDate.start', get(values, 'dueDateStart'));
    }

    if (!isEmpty(get(values, 'dueDateEnd'))) {
      params.append('dueDate.end', get(values, 'dueDateEnd'));
    }
    params.append('startDate', get(filterValues, 'startDate', ''));
    params.append('endDate', get(filterValues, 'endDate', ''));

    let url = '';
    if (type == 'FIRM') {
      url = `${user.firmActionAnalytics}?${params.toString()}`;
    } else if (type == 'ADMIN') {
      url = `${user.adminActionAnalytics}?${params.toString()}`;
    } else {
      url = `${user.actionAnalytics}?${params.toString()}`;
    }

    return this.httpGet(url);
  }

  // To get client Manager dashboard data values
  getClientManagersDashboardData(values, filterValues): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(get(filterValues, 'projectName', ''))) {
      params.append('projectName', filterValues.projectName);
    }
    if (!isEmpty(get(filterValues, 'organisationTagId', []))) {
      _map(filterValues.organisationTagId, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTagsId', organisationTag);
      });
    }

    if (!isEmpty(get(filterValues, 'brandId', []))) {
      _map(filterValues.brandId, (brand) => {
        if (brand !== undefined) params.append('brandId', brand);
      });
    }

    if (!isEmpty(get(filterValues, 'industryId', []))) {
      _map(filterValues.industryId, (industry) => {
        if (industry !== undefined) params.append('industryId', industry);
      });
    }

    if (!isEmpty(get(filterValues, 'capabilityId', []))) {
      _map(filterValues.capabilityId, (capability) => {
        if (capability !== undefined) params.append('capabilityId', capability);
      });
    }

    if (!isEmpty(get(filterValues, 'consultingFirmId', []))) {
      _map(filterValues.consultingFirmId, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirmId', consultingFirm);
      });
    }
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    const url = `${client.clientManagerDashboard}?${params.toString()}`;
    return this.httpGet(url);
  }

  getZohoPlanDetails(): Observable<any> {
    return this.httpGet(`${user.zohoPlanDetails}`);
  }

  getZohoPlanDetailsForCompany(): Observable<any> {
    return this.httpGet(`${client.zohoPlanDetails}`);
  }

  getSubscriptionDetailByHostedPage(id: string): Observable<any> {
    return this.httpGet(`${user.zohoHostedPage}\/${id}`);
  }

  getSubscriptionDetailByHostedPageForCompany(id: string): Observable<any> {
    return this.httpGet(`${client.zohoHostedPage}\/${id}`);
  }

  deleteSandboxData(values: object): Observable<any> {
    return this.httpPut(user.deleteSandBoxData, values);
  }

  deleteSandboxDataForCompany(values: object): Observable<any> {
    return this.httpPut(client.deleteSandBoxData, values);
  }

  getBusinessOverviewData(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('revenueFilterType', get(values, 'revenueFilterType', 1));
    if (!isEmpty(get(values, 'projectName', ''))) {
      params.append('projectName', values.projectName);
    }
    if (!isEmpty(get(values, 'capability', []))) {
      _map(values.capability, (capability) => {
        params.append('capability', capability);
      });
    }
    if (!isEmpty(get(values, 'organisationTag', []))) {
      _map(values.organisationTag, (organisationTag) => {
        params.append('organisationTag', organisationTag);
      });
    }
    if (!isEmpty(get(values, 'industry', []))) {
      _map(values.industry, (industry) => {
        params.append('industry', industry);
      });
    }
    if (!isEmpty(get(values, 'brand', []))) {
      _map(values.brand, (brand) => {
        params.append('brand', brand);
      });
    }
    if (!isEmpty(get(values, 'client', []))) {
      _map(values.client, (client) => {
        params.append('client', client);
      });
    }
    const url = `${user.businessOverview}?${params.toString()}`;
    return this.httpGet(url);
  }

  activateOrDeactivateUser(values: object): Observable<any> {
    return this.httpPut(user.activateDeactivateUser, values);
  }

  /**
   * replace user with new user Id
   * @param values - {id:string, replaceId:string, actiavate:boolean}
   */
  changeUserId(values: object): Observable<any> {
    return this.httpPut(user.changeUserId, values);
  }

  getLanguages(): Observable<any> {
    return this.httpGet(admin.language);
  }

  saveLanguages(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.language}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  deleteLanguages(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.language}?${params.toString()}`;
    return this.httpPut(url, values);
  }

  getEmailTemplates(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    params.append('role', CONSTANTS.admin);
    if (!isEmpty(get(values, 'filter.language', ''))) {
      let language = get(values, 'filter.language', '');
      params.append('language', language);
    }
    return this.httpGet(`${admin.email}?${params.toString()}`);
  }

  getEmailTemplate(templateName: string, languageId: string): Observable<any> {
    const url = `${admin.email}/${templateName}/${languageId}`;
    return this.httpGet(url);
  }

  createOrUpdateEmailTemplate(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.email}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  getSurveyQuestionList(languageId) {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    params.append('language', languageId);
    const url = `${admin.surveyQuestion}?${params.toString()}`;
    return this.httpGet(url);
  }

  getSurveyQuestionAdmin(questionId: string, languageId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    params.append('language', languageId);
    const url = `${admin.surveyQuestion}/${questionId}?${params.toString()}`;
    return this.httpGet(url);
  }

  createOrUpdateSurveyQuestion(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    const url = `${admin.surveyQuestion}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  getSurveyLocalizationList(languageId) {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    params.append('language', languageId);
    const url = `${admin.localization}?${params.toString()}`;
    return this.httpGet(url);
  }

  editSurveyLocalizationList(values) {
    const url = `${admin.localization}?role=${CONSTANTS.admin}`;
    return this.httpPost(url, values);
  }

  getBenchmarkData(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('type', get(values, 'type', ''));
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'partner', []))) {
      _map(performanceFilter.partner, (partner) => {
        if (partner !== undefined) params.append('partner', partner);
      });
    }

    if (!isEmpty(get(performanceFilter, 'client', []))) {
      _map(performanceFilter.client, (client) => {
        if (client !== undefined) params.append('client', client);
      });
    }
    const url = `${user.benchmark}?${params.toString()}`;
    return this.httpGet(url);
  }

  // Toggles project visibility in Conavigo
  changeConavigoVisibility(values) {
    const url = `${user.project}/${values.id}`;
    return this.httpPost(url, values);
  }

  /* Socket Connection to Backend */
  private socket: Socket;
  public sandboxMessage$: Subject<SandboxDataSocketMessage> = new Subject();

  public async connectSocket() {
    // Get Auth Token from Auth0
    const token = await this.auth.getAccessTokenSilently().toPromise();
    this.socket = io(this.socketUrl, {
      auth: {
        token: token,
      },
      reconnection: false,
      transports: ['websocket'],
      secure: true,
    });
    return this.socket;
  }

  public sendSandboxSocketMessage(message: string) {
    this.socket.emit('sandbox', message);
  }

  public receiveSandboxSocketMessage = () => {
    this.socket.on('sandbox', (message: SandboxDataSocketMessage) => {
      this.sandboxMessage$.next(message);
    });

    return this.sandboxMessage$.asObservable();
  };

  /**
   * Checks if the app running is client or firm portal by using the host address.
   * @returns {boolean}
   */
  public isRunningClientApp(): boolean {
    return true;
  }

  getClientWorkFlowSettings(id?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(id)) {
      params.append('id', id);
    }
    const url = `${client.companyWorkflowSettings}?${params.toString()}`;
    return this.httpGet(url);
  }
  updateClientWorkFlowSettings(data): Observable<any> {
    const url = `${client.companyWorkflowSettings}`;
    return this.httpPut(url, data);
  }

  deleteClientWorkFlowSettings(id: string): Observable<any> {
    const url = `${client.companyWorkflowSettings}/${id.toString()}`;
    return this.httpDelete(url);
  }

  getClientWorkFlowSettingsById(id?: string): Observable<any> {
    const url = `${client.companyWorkflowSettings}/${id.toString()}`;
    return this.httpGet(url);
  }

  // Add new Cliend work flow settings
  addClientWorkFlowSettings(data): Observable<any> {
    const url = `${client.companyWorkflowSettings}`;
    return this.httpPost(url, data);
  }

  droppedClientWorkFlowSettings(data: AccordianDrop): Observable<any> {
    const url = `${client.companyWorkflowSettingsDrop}`;
    return this.httpPut(url, data);
  }

  companyApprovalProjectRequest(data): Observable<any> {
    const url = `${client.projectRequestApproval}`;
    return this.httpPost(url, data);
  }

  /**
   * TODO: Remove function since secrets used
   * This function is responsible for upload the file to s3
   * @param file File to be upload
   * @param keyName name of the key to update
   * @param putObjectUrl URL to upload the object
   * @returns the details of file
   */
  // uploadToS3(file?: File, keyName?: string, putObjectUrl?: string): Observable<any> | any {
  //   const bucket = new S3({
  //     accessKeyId: this.environment.accessKeyId,
  //     secretAccessKey: this.environment.secretAccessKey,
  //     region: this.environment.s3Region,
  //   });

  //   if (putObjectUrl) {
  //     return this.http.put<ApiResponse>(putObjectUrl, file, { headers: { 'Content-Type': file.type } });
  //   }

  //   if (keyName) {
  //     // it is going to update the data
  //     const deleteParams = {
  //       Bucket: this.environment.bucketName,
  //       Key: keyName,
  //     };
  //     bucket.deleteObject(deleteParams, function (err, data) {
  //       if (err) {
  //         return err;
  //       }
  //     });
  //   }

  //   if (file) {
  //     const contentType = file.type;
  //     const fileType = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
  //     const fileName = uuidv4() + '.' + fileType;

  //     const params = {
  //       Bucket: this.environment.bucketName,
  //       Key: fileName,
  //       Body: file,
  //       ACL: 'private',
  //       ContentType: contentType,
  //     };

  //     return new Promise((resolve) => {
  //       bucket.upload(params, function (err, data) {
  //         if (err) {
  //           throw new Error(err);
  //         } else {
  //           resolve(data);
  //         }
  //       });
  //     }).then((data) => {
  //       return data;
  //     });
  //   }
  // }

  /**
   * TODO: Remove function since secrets used
   * This function is responsible for retrive file signed url
   * @param key Key name of the document
   * @param fileName filename
   * @returns
   */
  // downloadFromS3(keyName: string, fileName?: string): string {
  //   const bucket = new S3({
  //     accessKeyId: this.environment.accessKeyId,
  //     secretAccessKey: this.environment.secretAccessKey,
  //     region: this.environment.s3Region,
  //   });
  //   const signedUrlParams = {
  //     Bucket: this.environment.bucketName,
  //     Key: keyName,
  //     Expires: 60,
  //   };
  //   if (fileName) {
  //     //fileName = 'download.' + keyName.slice(keyName.lastIndexOf('.') + 1);
  //     signedUrlParams['ResponseContentDisposition'] = 'attachment; filename ="' + fileName + '"';
  //   }
  //   return bucket.getSignedUrl('getObject', signedUrlParams);
  // }

  getFirmActionList(values, isMyActions?: boolean): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    if (isMyActions) params.append('myActions', '1');

    if (!isEmpty(get(values, 'filter.category', []))) {
      _map(values.filter.category, (category) => {
        params.append('category', category);
      });
    }

    if (!isEmpty(get(values, 'filter.owner', []))) {
      _map(values.filter.owner, (owner) => {
        params.append('owner', owner);
      });
    }

    if (isNumber(get(values, 'filter.status')) && get(values, 'isArchived') !== 1) {
      params.append('status', get(values, 'filter.status'));
    }

    if (!isEmpty(get(values, 'filter.createdBy', []))) {
      _map(values.filter.createdBy, (createdBy) => {
        params.append('createdBy', createdBy);
      });
    }

    if (!isEmpty(get(values, 'filter.assignedDateStart'))) {
      params.append('assignedDate.start', get(values, 'filter.assignedDateStart'));
    }

    if (!isEmpty(get(values, 'filter.assignedDateEnd'))) {
      params.append('assignedDate.end', get(values, 'filter.assignedDateEnd'));
    }

    if (!isEmpty(get(values, 'filter.dueDateStart'))) {
      params.append('dueDate.start', get(values, 'filter.dueDateStart'));
    }

    if (!isEmpty(get(values, 'filter.dueDateEnd'))) {
      params.append('dueDate.end', get(values, 'filter.dueDateEnd'));
    }

    if (isNumber(get(values, 'isArchived', ''))) {
      params.append('isArchived', get(values, 'isArchived'));
    }

    const url = `${user.firmAction}?${params.toString()}`;
    return this.httpGet(url);
  }

  getFirmActionOwners(): Observable<any> {
    const url = `${user.firmAction}/owners`;
    return this.httpGet(url);
  }

  createFirmAction(values, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (firmId) params.append('firmId', firmId);
    return this.httpPost(`${user.firmAction}?${params.toString()}`, values);
  }

  updateFirmAction(values, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (firmId) params.append('firmId', firmId);
    return this.httpPut(`${user.firmAction}?${params.toString()}`, values);
  }

  closeFirmAction(values): Observable<any> {
    return this.httpPut(user.firmActionClose, values);
  }

  archiveFirmAction(values): Observable<any> {
    return this.httpPut(user.firmActionArchive, values);
  }

  deleteFirmAction(id: string): Observable<any> {
    const url = `${user.firmAction}?id=${id}`;
    return this.httpDelete(url);
  }

  getFirmActionById(id: string, firmId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('id', id);
    params.append('firmId', firmId);
    const url = `${user.firmAction}?${params.toString()}`;
    return this.httpGet(url);
  }

  /* ConSource - Spend Analysis */

  // To get top section cards data
  getSpendAnalysisTopSectionData(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));

    if (isNumber(get(performanceFilter, 'isPaid', ''))) {
      params.append('isPaid', `${performanceFilter.isPaid}`);
    }
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    if (!isEmpty(get(performanceFilter, 'costCenter', []))) {
      params.append('costCenter', performanceFilter.costCenter.join(','));
    }

    // if (!isEmpty(get(performanceFilter, 'segment', []))) {
    //   params.append('segment', performanceFilter.segment.join(','));
    // }

    if (!isEmpty(get(performanceFilter, 'functions', []))) {
      params.append('functions', performanceFilter.functions.join(','));
    }

    if (!isEmpty(get(performanceFilter, 'regions', []))) {
      _map(performanceFilter.regions, (region) => {
        if (region !== undefined) params.append('regions', region);
      });
    }

    // if (!isEmpty(get(performanceFilter, 'thirdDimension', []))) {
    //   params.append('thirdDimension', performanceFilter.thirdDimension.join(','));
    // }

    const url = `${client.spendAnalysis.topSectionCards}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get spend analysis data for company view
  getSpendAnalysisCompanyViewDataForCompany(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('xAxisFilter', get(values, 'xAxisFilter', 5));

    if (isNumber(get(performanceFilter, 'isPaid', ''))) {
      params.append('isPaid', `${performanceFilter.isPaid}`);
    }
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'regions', []))) {
      _map(performanceFilter.regions, (region) => {
        if (region !== undefined) params.append('regions', region);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    const url = `${client.spendAnalysis.companyView}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get spend analysis data for project view
  getSpendAnalysisProjectViewDataForCompany(dateFilter, performanceFilter: PerformanceFilter, tab = 0) {
    const params = new URLSearchParams();
    params.append('tab', tab.toString());
    params.append('startDate', get(dateFilter, 'startDate', ''));
    params.append('endDate', get(dateFilter, 'endDate', ''));
    params.append('offset', get(dateFilter, 'offset', ''));
    params.append('type', get(performanceFilter, 'type', 1));
    params.append('clientPriority', get(dateFilter, 'xAxisFilter', ''));

    // if (isNumber(get(performanceFilter, 'isPaid', ''))) {
    //   params.append('isPaid', `${performanceFilter.isPaid}`);
    // }
    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    const url = `${client.spendAnalysis.projectView}?${params.toString()}`;
    return this.httpGet(url);
  }

  getCompanyActionList(values, isMyActions: boolean): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    if (isMyActions) params.append('myActions', '1');

    if (!isEmpty(get(values, 'filter.category', []))) {
      _map(values.filter.category, (nature) => {
        params.append('category', nature);
      });
    }

    if (!isEmpty(get(values, 'filter.owner', []))) {
      _map(values.filter.owner, (owner) => {
        params.append('owner', owner);
      });
    }

    if (isNumber(get(values, 'filter.status')) && get(values, 'isArchived') !== 1) {
      params.append('status', get(values, 'filter.status'));
    }

    if (!isEmpty(get(values, 'filter.createdBy', []))) {
      _map(values.filter.createdBy, (createdBy) => {
        params.append('createdBy', createdBy);
      });
    }

    if (!isEmpty(get(values, 'filter.assignedDateStart'))) {
      params.append('assignedDate.start', get(values, 'filter.assignedDateStart'));
    }

    if (!isEmpty(get(values, 'filter.assignedDateEnd'))) {
      params.append('assignedDate.end', get(values, 'filter.assignedDateEnd'));
    }

    if (!isEmpty(get(values, 'filter.dueDateStart'))) {
      params.append('dueDate.start', get(values, 'filter.dueDateStart'));
    }

    if (!isEmpty(get(values, 'filter.dueDateEnd'))) {
      params.append('dueDate.end', get(values, 'filter.dueDateEnd'));
    }

    if (isNumber(get(values, 'isArchived', ''))) {
      params.append('isArchived', get(values, 'isArchived'));
    }

    const url = `${user.companyAction}?${params.toString()}`;
    return this.httpGet(url);
  }

  getCompanyActionOwners(): Observable<any> {
    const url = `${user.companyAction}/owners`;
    return this.httpGet(url);
  }

  createCompanyAction(values): Observable<any> {
    return this.httpPost(user.companyAction, values);
  }

  updateCompanyAction(values): Observable<any> {
    return this.httpPut(user.companyAction, values);
  }

  closeCompanyAction(values): Observable<any> {
    return this.httpPut(user.companyActionClose, values);
  }

  archiveCompanyAction(values): Observable<any> {
    return this.httpPut(user.archiveCompanyAction, values);
  }

  deleteCompanyAction(id: string): Observable<any> {
    const url = `${user.companyAction}?id=${id}`;
    return this.httpDelete(url);
  }

  getCompanyActionById(id: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('id', id);
    const url = `${user.companyAction}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Adds a tier under a specific company
   * @param values Tier values
   * @returns Tier add response
   */
  saveOrUpdateClientTiers(values): Observable<any> {
    return this.httpPost(client.clientTiers, values);
  }

  /**
   * Deletes a tier under a specific company
   * @param values Tier values
   * @returns Tier delete response
   */
  deleteClientTiers(values): Observable<any> {
    return this.httpPut(client.clientTiers, values);
  }

  getProvidersViewTab(values, performanceFilter: PerformanceFilter, tab = 0): Observable<any> {
    const params = new URLSearchParams();
    params.append('tab', tab.toString());
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('clientPriority', get(values, 'xAxisFilter', ''));

    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    const url = `${client.spendAnalysis.providersView}?${params.toString()}`;
    return this.httpGet(url);
  }

  // To get spend analysis - panel view data
  getSpendAnalysisPanelViewData(values, performanceFilter: PerformanceFilter, tab?: number): Observable<any> {
    const params = new URLSearchParams();
    params.append('tab', tab.toString());
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('xAxisFilter', get(values, 'xAxisFilter', 5));
    params.append('yAxisFilter', get(values, 'yAxisFilter', 5));

    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }
    const url = `${client.spendAnalysis.panelView}?${params.toString()}`;
    return this.httpGet(url);
  }

  getSpendProfileChartData(values, performanceFilter: PerformanceFilter): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('xAxisFilter', get(values, 'xAxisFilter', ''));

    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }
    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    if (!isEmpty(get(performanceFilter, 'regions', []))) {
      _map(performanceFilter.regions, (region) => {
        if (region !== undefined) params.append('regions', region);
      });
    }

    const url = `${client.charts.spendProfileChart}?${params.toString()}`;
    return this.httpGet(url);
  }

  getSpendVolumeByProviderAndCapability(values, performanceFilter: PerformanceFilter, capabilityFilter: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('startDate', get(values, 'startDate', ''));
    params.append('endDate', get(values, 'endDate', ''));
    params.append('offset', get(values, 'offset', ''));
    params.append('xAxisFilter', get(values, 'xAxisFilter', ''));

    if (!isEmpty(get(performanceFilter, 'projectName', ''))) {
      params.append('projectName', performanceFilter.projectName);
    }

    if (!isEmpty(capabilityFilter)) {
      params.append('capabilityFilter', capabilityFilter);
    }

    if (!isEmpty(get(performanceFilter, 'organisationTag', []))) {
      _map(performanceFilter.organisationTag, (organisationTag) => {
        if (organisationTag !== undefined) params.append('organisationTag', organisationTag);
      });
    }

    if (!isEmpty(get(performanceFilter, 'brand', []))) {
      _map(performanceFilter.brand, (brand) => {
        if (brand !== undefined) params.append('brand', brand);
      });
    }

    if (!isEmpty(get(performanceFilter, 'industry', []))) {
      _map(performanceFilter.industry, (industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }

    if (!isEmpty(get(performanceFilter, 'capability', []))) {
      _map(performanceFilter.capability, (capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }

    if (!isEmpty(get(performanceFilter, 'consultingFirm', []))) {
      _map(performanceFilter.consultingFirm, (consultingFirm) => {
        if (consultingFirm !== undefined) params.append('consultingFirm', consultingFirm);
      });
    }

    const url = `${client.charts.spendVolumeByProvidersAndCapability}?${params.toString()}`;
    return this.httpGet(url);
  }

  /* Organisational Unit */

  loadOrganisationalUnitsForCompany(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    return this.httpGet(`${client.clientOrganisationTag}?${params.toString()}`);
  }

  getCompanyOrganisationalUnit(organisationalUnitId: string): Observable<any> {
    return this.httpGet(`${client.clientOrganisationTag}/${organisationalUnitId}`);
  }

  createCompanyOrganisationalUnit(values): Observable<any> {
    return this.httpPost(client.clientOrganisationTag, values);
  }

  updateCompanyOrganisationalUnit(values): Observable<any> {
    return this.httpPut(client.clientOrganisationTag, values);
  }

  deleteCompanyOrganisationalUnit(organisationalUnitId: string): Observable<any> {
    return this.httpDelete(`${client.clientOrganisationTag}/${organisationalUnitId}`);
  }

  // Request for proposal
  // To get company project by ID
  getProjectDetailsForRFP(id: string): Observable<any> {
    return this.httpGet(`${client.rfp.projectDetails}\/${id}`);
  }

  // To get company demand by ID
  getDemandDetailsForRFP(id: string, type): Observable<any> {
    const params = new URLSearchParams();
    params.append('type', type);
    return this.httpGet(`${client.rfp.demandDetails}\/${id}?${params.toString()}`);
  }

  // Gets project details for RFP Wizard
  getRfpDetailsForProject(projectId: string): Observable<any> {
    return this.httpGet(`${client.rfpDetails}\/${projectId}`);
  }

  // Updates RFP information for a given project
  createOrUpdateRfpDetailsForProject(rfpInformation: RfpInformation, rfpWizardStep: number, fromProjectView: boolean = false, isActiveSourcing = false): Observable<any> {
    const queryParams = new URLSearchParams();
    queryParams.append('step', `${rfpWizardStep}`);
    if (rfpInformation._id) {
      return this.httpPut(`${client.rfpDetails}\/${rfpInformation._id}?${queryParams.toString()}`, { ...rfpInformation, fromProjectView, isActiveSourcing });
    } else {
      return this.httpPost(`${client.rfpDetails}\/${rfpInformation.projectId}?${queryParams.toString()}`, { ...rfpInformation, fromProjectView, isActiveSourcing });
    }
  }

  /* RFP - Settings */

  // To add  RFP milestones in client
  saveClientMilestones(values): Observable<any> {
    return this.httpPost(client.rfp.milestones, values);
  }

  // To delete RFP milestones in client
  deleteClientMilestones(values): Observable<any> {
    return this.httpPut(client.rfp.milestones, values);
  }

  // To add  RFP selection criteria in client
  saveClientSelectionCriterion(values): Observable<any> {
    return this.httpPost(client.rfp.selectionCriterion, values);
  }

  // To delete RFP selection criteria in client
  deleteClientSelectionCriterion(values): Observable<any> {
    return this.httpPut(client.rfp.selectionCriterion, values);
  }

  addProjectTeamMembers(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.rfp.projectDetails}/${projectId}/team`, values);
  }

  deleteProjectTeamMember(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.rfp.projectDetails}/${projectId}/team`, values);
  }

  exportRfpPdf(rfpId: string, isPreview: boolean = false) {
    return this.pdfHttpGet(`${client.rfp.generatePdf}/${rfpId}?isPreview=${isPreview}`);
  }

  // To update RFP PDF branding settings in client
  updateRfpPdfBrandingSettings(values): Observable<any> {
    return this.httpPost(client.rfp.pdfBrandingSettings, values);
  }

  // To update RFP PDF branding settings in client
  updateRfpPdfExportHistorySettings(values): Observable<any> {
    return this.httpPost(client.rfp.exportHistorySettings, values);
  }

  // To update NDA PDF branding settings in client
  updateNdaPdfExportHistorySettings(values): Observable<any> {
    return this.httpPost(client.sourcingInvitation.exportHistorySettings, values);
  }

  // Delete an exported RFP PDF from export history
  rfpDeleteExportedPdf(rfpId: string, exportId: string) {
    const params = new URLSearchParams();
    params.append('exportId', exportId);
    return this.httpDelete(`${client.rfp.deleteExportHistory}/${rfpId}?${params.toString()}`);
  }

  addDemandTeamMembers(demandId: string, values): Observable<any> {
    return this.httpPost(`${client.rfp.demandDetails}/${demandId}/team`, values);
  }

  deleteDemandTeamMember(demandId: string, values): Observable<any> {
    return this.httpPut(`${client.rfp.demandDetails}/${demandId}/team`, values);
  }

  rfpUpdateDemandTeamMember(demandId: string, values): Observable<any> {
    return this.httpPut(`${client.rfp.teamLeadChange}/${demandId}`, values);
  }

  rfpDemandRequest(values): Observable<any> {
    return this.httpPut(`${client.rfp.demandRequest}`, values);
  }

  rfpDemandApproval(values): Observable<any> {
    return this.httpPut(`${client.rfp.demandApproval}`, values);
  }

  rfpDemandRenotify(id: string, values): Observable<any> {
    return this.httpPut(`${client.rfp.demandDetails}/${id}/notify`, values);
  }

  addRfpTeamMembers(rfpId: string, values): Observable<any> {
    return this.httpPost(`${client.rfpDetails}/${rfpId}/team`, values);
  }

  deleteRfpTeamMember(rfpId: string, values): Observable<any> {
    return this.httpPut(`${client.rfpDetails}/${rfpId}/team`, values);
  }

  rfpUpdateTeamMember(rfpId: string, values): Observable<any> {
    return this.httpPut(`${client.rfpDetails}/LeadChange/${rfpId}`, values);
  }

  /* Sourcing & Invitation */

  /**
   * Fetches list of markers for creating target profile in sourcing and invitation
   * @returns Target profile DNA markers
   */
  getTargetProfileMarkers() {
    return this.httpGet(`${client.sourcingInvitation.targetProfileMarkers}`);
  }

  /**
   * Gets target profile data (sourcing and invitation flow) for given project ID
   * @param projectId The unique ID of the project
   * @returns
   */
  getSourcing(projectId: string) {
    return this.httpGet(`${client.sourcingInvitation.sourcing}/${projectId}`);
  }

  /**
   * Creates/updates target profile
   * @param targetProfile Target profile object
   * @returns
   */
  saveSourcing(sourcingDetails: Sourcing) {
    return this.httpPost(`${client.sourcingInvitation.sourcing}`, sourcingDetails);
  }

  /**
   * This function is responsible to Trigger FE api for add firms to sourcing
   * @param projectId
   * @param values
   * @returns
   */
  addFirmsForSourcing(projectId: string, values) {
    return this.httpPost(`${client.sourcingInvitation.firms}/${projectId}`, values);
  }

  /**
   * This function is responsible to Trigger FE api for edit firms to sourcing
   * @param projectId
   * @param values
   * @returns
   */
  editFirmsForSourcing(projectId: string, values) {
    return this.httpPut(`${client.sourcingInvitation.firms}/${projectId}`, values);
  }

  /**
   * This function will responsible to Trigger FE api for delete firm for sourcing
   * @param projectId
   * @param values
   */
  deleteFirmForSourcing(projectId: string, values) {
    const params = new URLSearchParams();
    if (!isEmpty(get(values, 'firmId', []))) {
      _map(values.firmId, (firmId) => {
        if (firmId !== undefined) params.append('firmId', firmId);
      });
    }
    return this.httpDelete(`${client.sourcingInvitation.firms}/${projectId}?${params.toString()}`);
  }

  /**
   * This function will responsible to Trigger FE api for shortList firm for sourcing
   * @param firmId
   */
  shortlistFirmForSourcing(projectId: string, values) {
    return this.httpPut(`${client.sourcingInvitation.firms}/shortlist/${projectId}`, values);
  }

  /**
   * Get Firms for Sourcing
   * @param projectId
   * @param isShortListed
   * @returns
   */
  getSourcingSelection(projectId: string, isShortListed = false, firmId = '', isScore = false) {
    const params = new URLSearchParams();
    params.append('isShortListed', isShortListed.toString());
    if (firmId) params.append('firmId', firmId);
    params.append('isScore', isScore.toString());
    return this.httpGet(`${client.sourcingInvitation.firms}/${projectId}?${params.toString()}`);
  }

  // To update Sourcing & Invitation settings in client
  updateSourcingSettings(values): Observable<any> {
    return this.httpPost(client.sourcingInvitation.ndaSettings, values);
  }

  /**
   * This function will responsible to Trigger FE api for shortList firm for invite/reinvite
   * @param firmId
   */
  inviteFirmForSourcing(projectId: string, values) {
    return this.httpPut(`${client.sourcingInvitation.firms}/invite/${projectId}`, values);
  }

  /**
   * Fetches list of invited projects for a firm user (role 6)
   * @param searchText Search text
   * @returns List of invitations received by the firm
   */
  getInvitedProjectsForFirm(values: ProjectTableData, type = ProjectListType.PROJECTS) {
    const params = new URLSearchParams();
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('sortBy', values?.sortBy);
    params.append('sortOrder', values?.sortOrder);
    params.append('search', values?.search);
    params.append('type', type);
    if (values?.companyId) params.append('companyId', values?.companyId);
    return this.httpGet(`${client.sourcingInvitation.invitedProjects}?${params.toString()}`);
  }

  getOpportunity(projectId: string) {
    return this.httpGet(`${client.sourcingInvitation.invitedProjects}/${projectId}`);
  }

  acceptOrRejectProjectInvitation(values: InvitedProjectUserResponse) {
    return this.httpPost(`${client.sourcingInvitation.invitedProjects}`, values);
  }

  /**
   * This function will responsible to Trigger FE api for get registered partners of firm
   * @param values
   */
  getPartnerUsersList(values): Observable<any> {
    const params = new URLSearchParams();
    if (values?.firmId) {
      params.append('firmId', values.firmId);
    }
    if (values?.companyId) {
      params.append('companyId', values.companyId);
    }
    if (values?._id) {
      params.append('userId', values?._id);
    }
    // filter
    if (values?.search) {
      params.append('search', values?.search);
    }
    if (values?.sortBy) {
      params.append('sortBy', values?.sortBy);
    }
    if (values?.sortOrder) {
      params.append('sortOrder', values?.sortOrder);
    }
    const url = `${client.sourcingInvitation.sourcing}/users?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * This function will responsible to Trigger FE api for delete firm user
   * @param id
   */
  deleteFirmUser(id): Observable<any> {
    return this.httpDelete(`${client.sourcingInvitation.sourcing}/users?id=${id}`);
  }

  /**
   * This function will responsible to Trigger FE api for add firm user
   * @param values
   */
  addFirmUser(values): Observable<any> {
    return this.httpPost(`${client.sourcingInvitation.sourcing}/users`, values);
  }

  /**
   * This function will responsible to Trigger FE api for edit firm user
   * @param values
   */
  editFirmUser(values): Observable<any> {
    return this.httpPut(`${client.sourcingInvitation.sourcing}/users`, values);
  }

  // To add or update question for project
  addOrEditRFPQuestion(values): Observable<any> {
    return this.httpPost(client.questionAndAnswer.question, values);
  }

  // To add or update answer for project
  addOrEditRFPAnswer(values): Observable<any> {
    return this.httpPost(client.questionAndAnswer.answer, values);
  }

  // To add or update answer for project
  getQuestion(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', values?.projectId);
    params.append('visibility', values?.visibility);
    if (!isEmpty(get(values, 'firmId', []))) {
      _map(values.firmId, (firmId) => {
        if (firmId !== undefined) params.append('firmId', firmId);
      });
    }
    return this.httpGet(`${client.questionAndAnswer.question}?${params.toString()}`);
  }

  /**
   * Delete question
   * @param questionid
   * @returns
   */
  deleteQuestion(questionid: string): Observable<any> {
    return this.httpDelete(`${client.questionAndAnswer.question}/${questionid}`);
  }

  /**
   * Delete Answer
   * @param questionid
   * @returns
   */
  deleteAnswer(questionid: string): Observable<any> {
    return this.httpDelete(`${client.questionAndAnswer.answer}/${questionid}`);
  }

  /**
   * Get shortListed firms
   * @param projectId
   * @returns
   */
  rfpShortListedFirmList(projectId: string): Observable<any> {
    return this.httpGet(`${client.questionAndAnswer.answer}/${projectId}`);
  }

  /**
   * Get Uploading Document's Step
   * @param projectId
   * @returns
   */
  getProposalStep(projectId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.step}/${projectId}`);
  }

  /**
   * Get Upload URL for Proposal/ Add Uploaded Doc to DB
   * @param values
   * @returns
   */
  uploadFileForProposal(values): Observable<any> {
    const projectId = get(values, 'projectId', '');
    return this.httpPost(`${client.proposalAndSelection.uploadUrl}/${projectId}`, values);
  }

  /**
   * Update Proposal Details - for client users
   * @param values
   * @returns
   */
  updateProposalDocument(values): Observable<any> {
    const projectId = get(values, 'projectId', '');
    return this.httpPut(`${client.proposalAndSelection.uploadUrl}/${projectId}`, values);
  }

  /**
   * Get Poposal List
   * @param values ProjectId and Filter details
   * @returns
   */
  getProposal(values): Observable<any> {
    const params = new URLSearchParams();
    const projectId = get(values, 'projectId', '');
    params.append('page', `${values?.page}`);
    params.append('limit', `${values?.limit}`);
    params.append('init', get(values, 'init', true));
    if (!isEmpty(values.firmId)) {
      _map(values.firmId, (firmId) => {
        if (firmId !== undefined) params.append('firmId', firmId);
      });
    }
    return this.httpGet(`${client.proposalAndSelection.proposal}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Uploading Document's Step
   * @param documentId
   * @returns
   */
  deleteProposal(documentId: string): Observable<any> {
    return this.httpDelete(`${client.proposalAndSelection.proposal}/${documentId}`);
  }

  /**
   * Get Uploading Document's Step
   * @param projectId
   * @returns
   */
  getProposalFirmFilter(projectId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.firmList}/${projectId}`);
  }

  /**
   * Get Proposal Evaluator
   * @param projectId
   * @returns
   */
  getProposalEvaluator(projectId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.team}/${projectId}`);
  }

  /**
   * Update Proposal Evaluator
   * @param projectId
   * @returns
   */
  updateProposalEvaluator(projectId: string, userId: string[]): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.team}/${projectId}`, { userId: userId });
  }

  /**
   * Get Proposal Criteria
   * @param projectId
   * @returns
   */
  getProposalCriteria(projectId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.selectionCriteria}/${projectId}`);
  }

  /**
   * Get Proposal Criteria
   * @param projectId
   * @param values
   * @returns
   */
  updateProposalCriteria(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.selectionCriteria}/${projectId}`, values);
  }

  /**
   * Add Proposal Criteria
   * @param projectId
   * @param values
   * @returns
   */
  addProposalCriteria(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.proposalAndSelection.selectionCriteria}/${projectId}`, values);
  }

  /**
   * Delete Proposal Criteria
   * @param projectId
   * @param criteriaId
   * @returns
   */
  deleteProposalCriteria(projectId: string, criteriaId: string): Observable<any> {
    return this.httpDelete(`${client.proposalAndSelection.selectionCriteria}/${projectId}?id=${criteriaId}`);
  }

  /**
   * Get Criteria scoring
   * @param projectId
   * @param firmId
   * @returns
   */
  getProposalMyScoring(projectId: string, firmId?: string): Observable<any> {
    let url = `${client.proposalAndSelection.myScoring}/${projectId}`;
    firmId && (url += `?firmId=${firmId}`);
    return this.httpGet(url);
  }

  /**
   * Update Criteria scoring
   * @param projectId
   * @param value
   * @returns
   */
  updateProposalMyScoring(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.myScoring}/${projectId}`, value);
  }

  /**
   * Get Criteria scoring
   * @param projectId
   * @param criteriaId
   * @returns
   */
  getProposalTeamScoring(projectId: string, criteriaId?: string): Observable<any> {
    let url = `${client.proposalAndSelection.teamScoring}/${projectId}`;
    criteriaId && (url += `?criteriaId=${criteriaId}`);
    return this.httpGet(url);
  }

  /**
   * Get Proposal ShortList
   * @param projectId
   * @returns
   */
  getProposalShortList(projectId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.shortlist}/${projectId}`);
  }

  /**
   * Proposal Shortlist Action
   * @param projectId
   * @param value
   * @returns
   */
  proposalShortListAction(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.shortlist}/${projectId}`, value);
  }

  /**
   * Proposal Pitch Edit
   * @param value
   * @returns
   */
  proposalPitchEditDetails(value): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.editPitchDetails}`, value);
  }

  /**
   * Proposal Result
   * @param projectId
   * @returns
   */
  proposalResult(projectId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.result}/${projectId}`);
  }

  /**
   * order Proposal Result
   * @param projectId
   * @param value
   * @returns
   */
  orderProposalResult(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.result}/order/${projectId}`, value);
  }

  /**
   * Get FeedBack Details
   * @param projectId
   * @param firmId
   * @returns
   */
  getProposalFeedBack(projectId: string, firmId: string): Observable<any> {
    return this.httpGet(`${client.proposalAndSelection.feedBack}/${projectId}?firmId=${firmId}`);
  }

  /**
   * save FeedBack Details
   * @param projectId
   * @param value
   * @returns
   */
  updateProposalFeedBack(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.feedBack}/${projectId}`, value);
  }

  /**
   * Proposal Result update
   * @param projectId
   * @returns
   */
  proposalResultAction(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.proposalAndSelection.result}/${projectId}`, value);
  }

  /**
   * Get Sow
   * @param projectId
   * @param firmId
   * @returns
   */
  getSow(projectId: string, firmId?: string, isCommunicationTab: boolean = false): Observable<any> {
    const params = new URLSearchParams();
    if (firmId) params.append('firmId', firmId);
    if (isCommunicationTab) params.append('isCommunicationTab', 'true');
    return this.httpGet(`${client.sow.sow}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Sow Fast Track partners contacts
   * @param projectId
   * @param firmId
   * @returns
   */
  getPartnersForSowFastTrack(firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (firmId) params.append('firmId', firmId);
    return this.httpGet(`${client.sow.consultingFirmFastTracContacts}/${firmId}`);
  }

  /**
   * create Sow
   * @param projectId
   * @returns
   */
  postSow(projectId: string, sowDetails?: SelectedFirmData): Observable<any> {
    return this.httpPost(`${client.sow.sow}/${projectId}`, sowDetails);
  }

  /**
   * get Sow team
   * @param projectId
   * @param firmId
   * @returns
   */
  getSowTeam(projectId: string, firmId: string): Observable<any> {
    return this.httpGet(`${client.sow.team}/${projectId}?firmId=${firmId}`);
  }

  /**
   * get Project team
   * @param projectId
   * @param firmId
   * @returns
   */
  getProjectTeam(projectId: string, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isFastTrackTo == '3') params.append('fastTrackTo', isFastTrackTo);
    return this.httpGet(`${client.projectManagement.team}/${projectId}?${params.toString()}`);
  }

  /**
   * Add Project Management Team Member
   * @param projectId
   * @param values
   * @returns
   */
  addProjectManagementTeamMember(projectId: string, values, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isFastTrackTo == '3') params.append('fastTrackTo', isFastTrackTo);
    return this.httpPost(`${client.projectManagement.team}/${projectId}?${params.toString()}`, values);
  }

  /**
   * Remove member from Project management Team
   * @param projectId
   * @param firmId
   * @param userId
   * @returns
   */
  removeProjectManagementTeamMember(projectId: string, firmId: string, userId: string): Observable<any> {
    return this.httpDelete(`${client.projectManagement.team}/${projectId}?firmId=${firmId}&userId=${userId}`);
  }

  /**
   * Get Project Management Objectives
   * @param projectId
   * @returns
   */
  getProjectManagementObjectives(projectId: string, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isFastTrackTo == '3') params.append('fastTrackTo', isFastTrackTo);
    return this.httpGet(`${client.projectManagement.objectivesAndDeliverables}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Project Management Objectives
   * @param projectId
   * @returns
   */
  updateProjectManagementObjectives(projectId: string, values, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isFastTrackTo == '3') params.append('fastTrackTo', isFastTrackTo);
    return this.httpPut(`${client.projectManagement.objectivesAndDeliverables}/${projectId}?${params.toString()}`, values);
  }

  /**
   * Get Project Management
   * @param projectId
   * @returns
   */
  getProjectManagement(projectId: string): Observable<any> {
    return this.httpGet(`${client.projectManagement.projectManagement}/${projectId}`);
  }

  /**
   * Update Project Management
   * @param projectId
   * @returns
   */
  updateProjectManagement(
    projectId: string,
    budget: number,
    firmId: string,
    data,
    legalEntityId: string,
    demandBudget: number = 0,
    sowBudget: number = 0
  ): Observable<any> {
    const params = new URLSearchParams();
    params.append('firmId', firmId);
    return this.httpPut(`${client.projectManagement.projectManagement}/${projectId}?${params.toString()}`, {
      budget: budget,
      data: data,
      legalEntity: legalEntityId,
      demandBudget,
      sowBudget,
    });
  }

  /**
   * Get Project Management Milestones
   * @param projectId
   * @returns
   */
  getProjectManagementMilestones(projectId: string, milestoneId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(milestoneId)) params.append('milestone', milestoneId);
    return this.httpGet(`${client.projectManagement.milestones}/${projectId}?${params.toString()}`);
  }

  /**
   * Create Management Milestone
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  createProjectManagementMilestone(projectId: string, values, fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4') params.append('fastTrackTo', fastTrackTo);
    return this.httpPost(`${client.projectManagement.milestones}/${projectId}?${params.toString()}`, values);
  }

  /**
   * Update Management Milestone
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  updateProjectManagementMilestone(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.projectManagement.milestones}/${projectId}`, values);
  }
  /**
   * Delete Management Milestone
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  deleteProjectManagementMilestone(projectId: string, milestoneId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('milestone', milestoneId);
    return this.httpDelete(`${client.projectManagement.milestones}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Project Management Invoicing
   * @param projectId
   * @returns
   */
  getProjectManagementInvoicing(projectId: string, milestoneId: string, fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(fastTrackTo)) params.append('fastTrackTo', fastTrackTo);
    if (!isEmpty(milestoneId)) params.append('milestone', milestoneId);
    return this.httpGet(`${client.projectManagement.invoicing}/${projectId}?${params.toString()}`);
  }

  /**
   * Delete Project Management Invoicing
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  deleteProjectManagementInvoicing(projectId: string, milestoneId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('milestone', milestoneId);
    return this.httpDelete(`${client.projectManagement.invoicing}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Project Management Invoicing
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  updateProjectManagementInvoicing(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.projectManagement.invoicing}/${projectId}`, values);
  }

  /**
   * Create Project Management Invoicing
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  createProjectManagementInvoicing(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.projectManagement.invoicing}/${projectId}`, values);
  }

  /**
   * get Team Member
   * @param projectId
   * @param firmId
   * @returns
   */
  getSowTeamMember(projectId: string, firmId: string): Observable<any> {
    return this.httpGet(`${client.sow.teamMember}/${projectId}?firmId=${firmId}`);
  }

  /**
   * get PM Team Member
   * @param projectId
   * @param firmId
   * @returns
   */
  getPmTeamMember(projectId: String, firmId: string): Observable<any> {
    return this.httpGet(`${client.projectManagement.teamMember}/${projectId}?firmId=${firmId}`);
  }

  /**
   * get Performance Team Member
   * @param projectId
   * @param firmId
   * @returns
   */
  getPerformanceTeamMember(projectId: String, firmId: string): Observable<any> {
    return this.httpGet(`${client.ProjectPerformance.teamMember}/${projectId}?firmId=${firmId}`);
  }

  /**
   * get Team Member
   * @param projectId
   * @param values
   * @returns
   */
  addSowTeamMember(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.teamMember}/${projectId}`, values);
  }

  /**
   * Remove member from Team
   * @param projectId
   * @param firmId
   * @param userId
   * @returns
   */
  removeSowTeamMember(projectId: string, firmId: string, userId: string): Observable<any> {
    return this.httpDelete(`${client.sow.teamMember}/${projectId}?firmId=${firmId}&userId=${userId}`);
  }

  /**
   * get Team Member
   * @param projectId
   * @param values
   * @returns
   */
  changeSowMemberRole(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.teamMember}/${projectId}`, values);
  }

  /**
   * get Team Member
   * @param projectId
   * @param values
   * @returns
   */
  changeProjectManagementMemberRole(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.projectManagement.team}/${projectId}`, values);
  }

  /**
   * Get Project Management Changes
   * @param projectId
   * @returns
   */
  getProjectManagementChanges(projectId: string): Observable<any> {
    return this.httpGet(`${client.projectManagement.changes}/${projectId}`);
  }

  /**
   * Get SOW preliminary
   * @param projectId
   * @param firmId
   * @returns
   */
  getSowPreliminary(projectId: string, firmId?: string): Observable<any> {
    let url = `${client.sow.preliminary}/${projectId}`;
    if (!isEmpty(firmId)) url += `?firmId=${firmId}`;
    return this.httpGet(url);
  }

  /**
   * Update SOW preliminary
   * @param projectId
   * @param values
   * @returns
   */
  updateSowPreliminary(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.preliminary}/${projectId}`, values);
  }

  /**
   * Gets super admin SoW settings
   * @returns SoW settings for super admin
   */
  getSoWSettingsForAdmin() {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    return this.httpGet(`${admin.sowSettings}?${params.toString()}`);
  }

  /**
   * Saves super admin SoW settings
   * @returns SoW settings for super admin
   */
  saveSoWSettingsForAdmin(data: LayoutAndTemplateData) {
    const params = new URLSearchParams();
    params.append('role', CONSTANTS.admin);
    return this.httpPost(`${admin.sowSettings}?${params.toString()}`, data);
  }

  /**
   * Saves client admin SoW general settings
   * @returns SoW general settings for client admin
   */
  saveSoWGeneralSettingsForClient(updatedSoWSettings: object) {
    return this.httpPost(`${client.sowSettings.general}`, updatedSoWSettings);
  }

  /**
   * Saves client admin SoW PDF settings
   * @returns SoW PDF settings for client admin
   */
  saveSoWPdfSettingsForClient(updatedPdfSettings: PdfSettings) {
    return this.httpPost(`${client.sowSettings.pdf}`, updatedPdfSettings);
  }

  /**
   * Get SOW Project Description
   * @param projectId
   * @param firmId
   * @returns
   */
  getSowProjectDescription(projectId: string, firmId?: string, isFastTrackTo?: string): Observable<any> {
    let url = `${client.sow.description}/${projectId}`;
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);
    if (isFastTrackTo == '2') params.append('fastTrackTo', isFastTrackTo);
    return this.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * Update SOW Project Description
   * @param projectId
   * @param values
   * @returns
   */
  updateSowProjectDescription(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.description}/${projectId}`, values);
  }

  /**
   * Get SOW Project management
   * @param projectId
   * @param firmId
   * @returns
   */
  getSowProjectManagement(projectId: string, firmId?: string, isFastTrackTo?: string): Observable<any> {
    let url = `${client.sow.management}/${projectId}`;
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);
    if (isFastTrackTo == '2') params.append('fastTrackTo', isFastTrackTo);
    return this.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * Update SOW Project management
   * @param projectId
   * @param values
   * @returns
   */
  updateSowProjectManagement(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.management}/${projectId}`, values);
  }

  /**
   * Get SOW Terms & Conditions
   * @param projectId
   * @param firmId
   * @returns
   */
  getProjectTermsConditions(projectId: string, firmId?: string, fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '1' || fastTrackTo == '2') {
      params.append('fastTrackTo', fastTrackTo);
    }
    if (!isEmpty(firmId)) {
      params.append('firmId', firmId);
    }
    let url = `${client.sow.termsAndCondition}/${projectId}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Update SOW Terms & Conditions
   * @param projectId
   * @param values
   * @returns
   */
  updateProjectTermsConditions(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.termsAndCondition}/${projectId}`, values);
  }

  updateProjectTermsConditionsFees(projectId: string, values, key): Observable<any> {
    const params = new URLSearchParams();
    params.append('fieldKey', key);
    return this.httpPut(`${client.sow.termsAndCondition}/fees/${projectId}?${params.toString()}`, values);
  }

  /* SoW Comments API */

  /**
   * Gets list of comments posted for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @returns List of comments and replies for the provided details
   */
  getSoWComments(sowCommentDetails: SowCommentDetails) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    return this.httpGet(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`);
  }

  /**
   * Gets list of comments posted for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @returns List of comments and replies for the provided details
   */
  getSoWDynamicComments(sowCommentDetails: SowCommentDetails) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    return this.httpGet(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`);
  }

  /**
   * Saves a comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param comment Reply comment text
   * @returns Comment creation response
   */
  saveSoWComment(sowCommentDetails: SowCommentDetails, comment: string, isInternal: boolean) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    return this.httpPost(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`, { comment, isInternal });
  }

  /**
   * Saves a comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param comment Reply comment text
   * @returns Comment creation response
   */
  saveSoWDynamicComment(sowCommentDetails: SowCommentDetails, comment: string, isInternal) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    return this.httpPost(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`, {
      comment,
      isInternal,
    });
  }

  /**
   * Updates a comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId Unique ID of the comment
   * @param comment Reply comment text
   * @returns Comment creation response
   */
  updateSoWComment(sowCommentDetails: SowCommentDetails, commentId: string, comment: string, isInternal) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    return this.httpPut(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`, { commentId, comment, isInternal });
  }

  /**
   * Updates a comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId Unique ID of the comment
   * @param comment Reply comment text
   * @returns Comment creation response
   */
  updateSoWDynamicComment(sowCommentDetails: SowCommentDetails, commentId: string, comment: string, isInternal) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    return this.httpPut(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`, {
      commentId,
      comment,
      isInternal,
    });
  }

  /**
   * Saves a reply comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId The comment to which the reply is posted
   * @param comment Reply comment text
   * @returns Reply comment creation response
   */
  saveSoWReplyComment(sowCommentDetails: SowCommentDetails, commentId: string, comment: string, isResolved: boolean) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    const payload = { commentId, comment };
    if (isResolved) payload['isResolved'] = true;
    return this.httpPost(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`, payload);
  }

  /**
   * Saves a reply comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId The comment to which the reply is posted
   * @param comment Reply comment text
   * @returns Reply comment creation response
   */
  saveSoWDynamicReplyComment(sowCommentDetails: SowCommentDetails, commentId: string, comment: string, isResolved: boolean) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    const payload = { commentId, comment };
    if (isResolved) payload['isResolved'] = true;
    return this.httpPost(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`, payload);
  }

  /**
   * Updates a reply comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId The comment to which the reply is posted
   * @param comment Reply comment text
   * @returns Reply comment creation response
   */
  updateSoWReplyComment(sowCommentDetails: SowCommentDetails, commentId: string, replyCommentId: string, comment: string, isResolved: boolean) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    const payload = { commentId, replyCommentId, comment };
    if (isResolved) payload['isResolved'] = true;
    return this.httpPut(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`, payload);
  }

  /**
   * Updates a reply comment for given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId The comment to which the reply is posted
   * @param comment Reply comment text
   * @returns Reply comment creation response
   */
  updateSoWDynamicReplyComment(sowCommentDetails: SowCommentDetails, commentId: string, replyCommentId: string, comment: string, isResolved: boolean) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    const payload = { commentId, replyCommentId, comment };
    if (isResolved) payload['isResolved'] = true;
    return this.httpPut(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`, payload);
  }

  /**
   * Deletes a comment with given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId Unique ID of the comment
   * @returns Comment deletion response
   */
  deleteSoWComment(sowCommentDetails: SowCommentDetails, commentId: string) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    params.append('commentId', commentId);
    return this.httpDelete(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`);
  }

  /**
   * Deletes a comment with given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId Unique ID of the comment
   * @returns Comment deletion response
   */
  deleteSoWDynamicComment(sowCommentDetails: SowCommentDetails, commentId: string) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    params.append('commentId', commentId);
    return this.httpDelete(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`);
  }

  /**
   * Deletes a reply comment with given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId Unique ID of the comment
   * @param replyCommentId Unique ID of the reply comment
   * @returns Reply comment deletion response
   */
  deleteSoWReplyComment(sowCommentDetails: SowCommentDetails, commentId: string, replyCommentId: string) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    params.append('commentId', commentId);
    params.append('replyCommentId', replyCommentId);
    return this.httpDelete(`${client.sow.comments}/${sowCommentDetails.sowId}?${params.toString()}`);
  }

  /**
   * Deletes a reply comment with given SoW details
   * @param sowCommentDetails SoW Comment Details (#ID, tab and field name)
   * @param commentId Unique ID of the comment
   * @param replyCommentId Unique ID of the reply comment
   * @returns Reply comment deletion response
   */
  deleteSoWDynamicReplyComment(sowCommentDetails: SowCommentDetails, commentId: string, replyCommentId: string) {
    const params = new URLSearchParams();
    params.append('sowTab', sowCommentDetails.sowTabName);
    params.append('sowField', sowCommentDetails.sowFieldName);
    params.append('commentId', commentId);
    params.append('replyCommentId', replyCommentId);
    return this.httpDelete(`${client.sow.sow}/${sowCommentDetails.sowId}/comments/field/${sowCommentDetails.fieldId}?${params.toString()}`);
  }

  /**
   * Get SOW Ref Document
   * @param projectId
   * @param value
   * @returns
   */
  getSowProjectRefDocument(projectId: string, value): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(value?.firmId)) params.append('firmId', value.firmId);
    if (!isEmpty(value?.companyId)) params.append('companyId', value.companyId);
    if (isNumber(value?.page)) params.append('page', value.page);
    if (isNumber(value?.limit)) params.append('limit', value.limit);
    if (isNumber(value?.type)) params.append('type', value.type);

    return this.httpGet(`${client.sow.refDocument}/${projectId}?${params.toString()}`);
  }

  /**
   * Get ProjectManagement Ref Document
   * @param projectId
   * @param value
   * @returns
   */
  getProjectManagementRefDocument(projectId: string, value, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isNumber(value?.page)) params.append('page', value.page);
    if (isNumber(value?.type)) params.append('type', value.type);
    if (isFastTrackTo == '3') params.append('fastTrackTo', isFastTrackTo);
    return this.httpGet(`${client.projectManagement.refDocument}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Document based on type
   * @param projectId
   * @param type
   * @param firmId
   * @returns
   */
  getSowProjectTypeDocument(projectId: string, type: number, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);
    params.append('type', type.toString());
    return this.httpGet(`${client.sow.refDocType}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Document based on type
   * @param projectId
   * @param type
   * @returns
   */
  getProjectManagementTypeDocument(projectId: string, type: number): Observable<any> {
    const params = new URLSearchParams();
    params.append('type', type.toString());
    return this.httpGet(`${client.projectManagement.refDocType}/${projectId}?${params.toString()}`);
  }

  /**
   * Add Document to sow ref
   * @param projectId
   * @param values
   * @returns
   */
  addSowProjectRefDocument(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.refDocument}/${projectId}`, values);
  }

  /**
   * Add Document to project management ref
   * @param projectId
   * @param values
   * @returns
   */
  addProjectManagementRefDocument(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.projectManagement.refDocument}/${projectId}`, values);
  }

  /**
   * Remove Document from sow ref
   * @param projectId
   * @param values
   * @returns
   */
  removeSowProjectRefDocument(projectId: string, type: number, document: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('document', document);
    params.append('type', type.toString());
    return this.httpDelete(`${client.sow.refDocument}/${projectId}?${params.toString()}`);
  }

  /**
   * Remove Document from project management ref
   * @param projectId
   * @param values
   * @returns
   */
  removeProjectManagementRefDocument(projectId: string, type: number, document: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('document', document);
    params.append('type', type.toString());
    return this.httpDelete(`${client.projectManagement.refDocument}/${projectId}?${params.toString()}`);
  }

  /**
   * Upload final Sow document
   * @param projectId
   * @param value
   * @returns
   */
  getUploadUrlSowFinalDocument(projectId: string, sowId: string, value: object): Observable<any> {
    const requestUrl = client.sow.signatureDocumentUpload.replace('{projectId}', projectId).replace('{sowId}', sowId);
    return this.httpPost(requestUrl, value);
  }

  /**
   * Signing API for SoW by client/firm
   * @param projectId Unique ID of the project
   * @param values Signature data
   * @returns
   */
  signSow(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.signature}/${projectId}`, values);
  }

  updatePoNumber(value): Observable<any> {
    return this.httpPut(client.sow.poNumber, value);
  }

  /**
   * Communication Mail API for SoW
   * @param projectId Unique ID of the project
   * @param values Signature data
   * @returns
   */
  sendSoWCommunicationMail(projectId: string, firmId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('firmId', firmId);
    return this.httpPost(`${client.sow.communicationMail}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Document Upload url
   * @param projectId
   * @param value
   * @returns
   */
  getSowProjectDocumentUploadUrl(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.sow.refDocType}/${projectId}`, value);
  }

  /**
   * Get Document Upload url
   * @param projectId
   * @param value
   * @returns
   */
  getProjectManagementRefDocumentUploadUrl(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.projectManagement.refDocType}/${projectId}`, value);
  }

  /**
   * Get Milestone Document Upload url
   * @param projectId
   * @param value
   * @returns
   */
  getProjectManagementDocumentUploadUrl(projectId: string, milestoneId: string, value): Observable<any> {
    return this.httpPut(`${client.projectManagement.uploadUrl}/${projectId}/${milestoneId}`, value);
  }

  /**
   * Get Invoice Document Upload url
   * @param projectId
   * @param value
   * @returns
   */
  getInvoicingDocumentUploadUrl(projectId: string, invoiceId: string, value): Observable<any> {
    return this.httpPut(`${client.projectManagement.invoiceUploadUrl}/${projectId}/${invoiceId}`, value);
  }

  /**
   * Add Milestone Document
   * @param projectId
   * @param value
   * @returns
   */
  addProjectManagementInvoicingDocument(projectId: string, invoiceId: string, value): Observable<any> {
    return this.httpPost(`${client.projectManagement.invoiceUploadUrl}/${projectId}/${invoiceId}`, value);
  }

  /**
   * Approve Milestone
   * @param projectId
   * @param value
   * @returns
   */
  approveProjectManagementMilestone(projectId: string, milestoneId: string, value): Observable<any> {
    return this.httpPut(`${client.projectManagement.approveMilestones}/${projectId}/${milestoneId}`, value);
  }

  /**
   * Request To Approve Milestone
   * @param projectId
   * @param value
   * @returns
   */
  requestToapproveProjectManagementMilestone(projectId: string, milestoneId: string): Observable<any> {
    return this.httpPut(`${client.projectManagement.requestMilestones}/${projectId}/${milestoneId}`);
  }

  /**
   * Request To Approve Invoice
   * @param projectId
   * @param value
   * @returns
   */
  requestToapproveProjectManagementInvoice(projectId: string, invoiceId: string): Observable<any> {
    return this.httpPut(`${client.projectManagement.requestInvoice}/${projectId}/${invoiceId}`);
  }

  /**
   * Approve Invoicing Milestone
   * @param projectId
   * @param value
   * @returns
   */
  approveProjectManagementInvoicingMilestone(projectId: string, invoiceId: string, value): Observable<any> {
    return this.httpPut(`${client.projectManagement.approveInvoicingMilestones}/${projectId}/${invoiceId}`, value);
  }

  /**
   * Updates the payment status for project management invoice
   * @param data
   * @returns
   */
  updatePaymentStatusProjectManagementInvoice(data): Observable<any> {
    return this.httpPut(`${client.projectManagement.updatePayment}`, data);
  }

  /**
   * Approve/ Submit for validation
   * @param projectId
   * @param value
   * @returns
   */
  validateAndApproveChanges(projectId: string, changeId: string, value): Observable<any> {
    return this.httpPut(`${client.projectManagement.validateAndApproveChanges}/${projectId}/${changeId}`, value);
  }
  /**
   * Add Invoice Document
   * @param projectId
   * @param value
   * @returns
   */
  addProjectManagementMilestoneDocument(projectId: string, milestoneId: string, value): Observable<any> {
    return this.httpPost(`${client.projectManagement.uploadUrl}/${projectId}/${milestoneId}`, value);
  }

  /**
   * Get Document Upload url
   * @param projectId
   * @param value
   * @returns
   */
  getProjectManagementMilestoneDocument(projectId: string, milestoneId: string): Observable<any> {
    return this.httpGet(`${client.projectManagement.uploadUrl}/${projectId}/${milestoneId}`);
  }

  /**
   * Get Document Upload url
   * @param projectId
   * @param value
   * @returns
   */
  getProjectManagementInvoicingDocument(projectId: string, invoiceId: string): Observable<any> {
    return this.httpGet(`${client.projectManagement.invoiceUploadUrl}/${projectId}/${invoiceId}`);
  }

  /**
   * Get Terminology Term
   * @param projectId selected project id
   * @param termId selected terminology id
   * @param firmId selected firm id
   * @returns
   */
  getSowProjectTerminologyTerm(projectId: string, firmId?: string, termId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(termId)) params.append('term', termId);
    return this.httpGet(`${client.sow.terminology}/${projectId}?${params.toString()}`);
  }

  /**
   * Delete Terminology Term
   * @param projectId selected project id
   * @param termId selected terminology id
   * @param firmId selected firm id
   * @returns
   */
  deleteSowProjectTerminologyTerm(projectId: string, termId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('term', termId);
    return this.httpDelete(`${client.sow.terminology}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Terminology Term
   * @param projectId selected project id
   * @param values contains firm id and update details
   * @returns
   */
  updateSowProjectTerminologyTerm(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.terminology}/${projectId}`, values);
  }

  /**
   * Create Terminology Term
   * @param projectId selected project id
   * @param values contains firm id and terminology details
   * @returns
   */
  createSowProjectTerminologyTerm(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.terminology}/${projectId}`, values);
  }

  /**
   * Get Management Milestone
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  getSowProjectManagementMilestone(projectId: string, firmId?: string, milestoneId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(milestoneId)) params.append('milestone', milestoneId);
    return this.httpGet(`${client.sow.managementMilestone}/${projectId}?${params.toString()}`);
  }

  /**
   * Delete Management Milestone
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  deleteSowProjectManagementMilestone(projectId: string, milestoneId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('milestone', milestoneId);
    return this.httpDelete(`${client.sow.managementMilestone}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Management Milestone
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  updateSowProjectManagementMilestone(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.managementMilestone}/${projectId}`, values);
  }

  /**
   * Create Management Milestone
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  createSowProjectManagementMilestone(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.managementMilestone}/${projectId}`, values);
  }

  /**
   * Get Nature of cost
   * @param projectId selected project id
   * @param natureId selected nature id
   * @param firmId selected firm id
   * @returns
   */
  getSowProjectNatureOfCost(projectId: string, firmId?: string, natureId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(natureId)) params.append('nature', natureId);
    return this.httpGet(`${client.sow.termsAndConditionCost}/${projectId}?${params.toString()}`);
  }

  /**
   * Delete Nature of cost
   * @param projectId selected project id
   * @param natureId selected nature id
   * @param firmId selected firm id
   * @returns
   */
  deleteSowProjectNatureOfCost(projectId: string, natureId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('nature', natureId);
    return this.httpDelete(`${client.sow.termsAndConditionCost}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Nature of cost
   * @param projectId selected project id
   * @param values contains firm id and nature details
   * @returns
   */
  updateSowProjectNatureOfCost(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.termsAndConditionCost}/${projectId}`, values);
  }

  /**
   * Create Nature of cost
   * @param projectId selected project id
   * @param values contains firm id and nature details
   * @returns
   */
  createSowProjectNatureOfCost(projectId: string, values, fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4') {
      params.append('fastTrackTo', fastTrackTo);
    }
    return this.httpPost(`${client.sow.termsAndConditionCost}/${projectId}?${params.toString()}`, values);
  }

  /**
   * Get Invoicing Milestone
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  getSowProjectInvoicingMilestone(projectId: string, firmId?: string, milestoneId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(milestoneId)) params.append('milestone', milestoneId);
    return this.httpGet(`${client.sow.termsAndConditionMilestone}/${projectId}?${params.toString()}`);
  }

  /**
   * Delete Invoicing Milestone
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  deleteSowProjectInvoicingMilestone(projectId: string, milestoneId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('milestone', milestoneId);
    return this.httpDelete(`${client.sow.termsAndConditionMilestone}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Invoicing Milestone
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  updateSowProjectInvoicingMilestone(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.termsAndConditionMilestone}/${projectId}`, values);
  }

  /**
   * Create Invoicing Milestone
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  createSowProjectInvoicingMilestone(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.termsAndConditionMilestone}/${projectId}`, values);
  }

  /**
   * Get All Sow Team member
   * @param projectId selected project id
   * @param firmId selected firm id
   * @returns
   */
  getSowAllTeam(projectId: string, firmId?: string): Observable<any> {
    let url = `${client.sow.team}/all-member/${projectId}`;
    if (!isEmpty(firmId)) url += `?firmId=${firmId}`;
    return this.httpGet(url);
  }

  /**
   * Get Governance
   * @param projectId selected project id
   * @param governanceId selected governance id
   * @param firmId selected firm id
   * @returns
   */
  getSowProjectGovernance(projectId: string, firmId?: string, governanceId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(governanceId)) params.append('governance', governanceId);
    return this.httpGet(`${client.sow.governance}/${projectId}?${params.toString()}`);
  }

  /**
   * Delete Governance
   * @param projectId selected project id
   * @param governanceId selected governance id
   * @param firmId selected firm id
   * @returns
   */
  deleteSowProjectGovernance(projectId: string, governanceId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('governance', governanceId);
    return this.httpDelete(`${client.sow.governance}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Governance
   * @param projectId selected project id
   * @param values contains firm id and governance details
   * @returns
   */
  updateSowProjectGovernance(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.governance}/${projectId}`, values);
  }

  /**
   * Create Governance
   * @param projectId selected project id
   * @param values contains firm id and governance details
   * @returns
   */
  createSowProjectGovernance(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.governance}/${projectId}`, values);
  }

  /**
   * Marks a given SoW field as validated by client company
   * @param projectId Selected Project ID
   * @param values Object containing validation details
   * @returns Validation API Response Data
   */
  validateSowField(projectId: string, payload: SoWValidationDetails): Observable<any> {
    return this.httpPost(`${client.sow.validateField}/${projectId}`, payload);
  }

  /**
   * Marks a given SoW field as validated by client company
   * @param sowId SoW Id
   * @param values Object containing validation details
   * @returns Validation API Response Data
   */
  validateGeneralProvisionSowField(sowId: string, fieldId: string): Observable<any> {
    return this.httpPost(`${client.sow.sow}/${sowId}/general-provisions/field/${fieldId}/validate`);
  }

  /**
   * Marks a given SoW field as validated by client company
   * @param sowId SoW Id
   * @param values Object containing validation details
   * @returns Validation API Response Data
   */
  validateOtherProvisionSowField(sowId: string, fieldId: string): Observable<any> {
    return this.httpPost(`${client.sow.sow}/${sowId}/other-provisions/field/${fieldId}/validate`);
  }

  /**
   * Get Project Performance team
   * @param projectId selected project id
   * @returns
   */
  getProjectPerformanceTeam(projectId: string): Observable<any> {
    return this.httpGet(`${client.ProjectPerformance.performance}/team/${projectId}`);
  }

  /**
   * Get Project Performance
   * @param projectId selected project id
   * @returns
   */
  getProjectPerformance(projectId: string, fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4') {
      params.append('fastTrackTo', fastTrackTo);
    }
    return this.httpGet(`${client.ProjectPerformance.performance}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Project Performance
   * @param projectId selected project id
   * @returns
   */
  createProjectPerformance(
    projectId: string,
    fastTrackTo?: string,
    budget?: number,
    selectedFirm?: string,
    data?,
    selectedLegalEntity?: string,
    demandBudget: number = 0,
    sowBudget: number = 0
  ): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4') {
      params.append('selectedFirm', selectedFirm);
    }
    params.append('fastTrackTo', fastTrackTo);
    return this.httpPost(`${client.ProjectPerformance.performance}/${projectId}?${params.toString()}`, {
      budget: budget,
      data: data,
      demandBudget,
      sowBudget,
      legalEntity: selectedLegalEntity,
    });
  }

  /**
   * Add member to project performance
   * @param projectId selected project id
   * @param userId selected users
   * @returns
   */
  addProjectPerformanceMember(projectId: string, userId: string[], fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4') {
      params.append('fastTrackTo', fastTrackTo);
    }
    return this.httpPost(`${client.ProjectPerformance.performance}/team/${projectId}?${params.toString()}`, { userId });
  }

  /**
   * Remove member to project performance
   * @param projectId selected project id
   * @param userId selected users
   * @returns
   */
  removeProjectPerformanceMember(projectId: string, userId: string): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.performance}/team/${projectId}`, { userId });
  }

  /**
   * Send survey to particular user in project performance
   * @param projectId selected project id
   * @param userId selected users
   * @returns
   */
  projectPerformanceSendSurvey(projectId: string, userId: string): Observable<any> {
    return this.httpPost(`${client.ProjectPerformance.survey}/${projectId}`, { userId });
  }

  /**
   * get user survey in project performance
   * @param projectId selected project id
   * @param userId selected users
   * @returns
   */
  getProjectPerformanceSurvey(projectId: string, userId?: string): Observable<any> {
    let url = `${client.ProjectPerformance.survey}/${projectId}`;
    if (userId) url += `?userId=${userId}`;
    return this.httpGet(url);
  }

  /**
   * Save survey answer
   * @param projectId selected project id
   * @param values selected users
   * @returns
   */
  saveProjectPerformanceSurvey(projectId: string, values, fastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4') {
      params.append('fastTrackTo', fastTrackTo);
    }
    return this.httpPut(`${client.ProjectPerformance.survey}/${projectId}?${params.toString()}`, values);
  }

  /**
   * get Project team
   * @param projectId
   * @param firmId
   * @returns
   */
  getPerformanceTeam(projectId: string, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isFastTrackTo == '3') params.append('fastTrackTo', isFastTrackTo);
    return this.httpGet(`${client.ProjectPerformance.projectTeam}/${projectId}?${params.toString()}`);
  }

  /**
   * get Team Member
   * @param projectId
   * @param values
   * @returns
   */
  changePerformanceMemberRole(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.projectTeam}/${projectId}`, values);
  }

  /**
   * Remove member from Project management Team
   * @param projectId
   * @param firmId
   * @param userId
   * @returns
   */
  removePerformanceTeamMember(projectId: string, firmId: string, userId: string): Observable<any> {
    return this.httpDelete(`${client.ProjectPerformance.projectTeam}/${projectId}?firmId=${firmId}&userId=${userId}`);
  }

  /**
   * Add Project Management Team Member
   * @param projectId
   * @param values
   * @returns
   */
  addPerformanceTeamMember(projectId: string, values, isFastTrackTo?: string): Observable<any> {
    const params = new URLSearchParams();
    if (isFastTrackTo == '4') params.append('fastTrackTo', isFastTrackTo);
    return this.httpPost(`${client.ProjectPerformance.projectTeam}/${projectId}?${params.toString()}`, values);
  }

  /**
   * Generates PDF with SoW content
   * @param sowId Unique ID of SoW
   * @returns API Response
   */
  exportSowPdf(sowId: string, isPreview: boolean = false) {
    return this.pdfHttpGet(`${client.sow.generatePdf}/${sowId}?isPreview=${isPreview}`);
  }

  /**
   * Deletes an SoW exported PDF from export history
   * @param sowId Unique ID of the SoW
   * @param exportId Unique ID of the export history item
   * @returns
   */
  sowDeleteExportedPdf(sowId: string, exportId: string) {
    const params = new URLSearchParams();
    params.append('exportId', exportId);
    return this.httpDelete(`${client.sow.deleteExportHistory}/${sowId}?${params.toString()}`);
  }
  /**
   * get scorecard detail of survey
   * @param projectId selected project id
   * @returns
   */
  getProjectPerformanceScorecard(projectId: string): Observable<any> {
    return this.httpGet(`${client.ProjectPerformance.scoreCard}/${projectId}`);
  }

  /**
   * Update project performance visibility
   * @param projectId selected project id
   * @param values value
   * @returns
   */
  updateProjectPerformanceVisibility(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.visibility}/${projectId}`, values);
  }

  /**
   * Gets list of strategic priorities
   * @param values Table Listing Parameters
   * @returns
   */
  getStrategicPrioritiesList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    const url = `${client.strategicPriorities}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Gets information about a specific strategic priority
   * @param strategicPriorityId Unique ID of strategic priority
   * @returns
   */
  getStrategicPriorityById(strategicPriorityId: string): Observable<any> {
    const url = `${client.strategicPriorities}/${strategicPriorityId}`;
    return this.httpGet(url);
  }

  /**
   * Creates/updates a specific strategic priority
   * @param updatedInfo Updated strategic priority information
   * @returns
   */
  createOrUpdateStrategicPriority(updatedInfo: StrategicPriority): Observable<any> {
    return this.httpPost(client.strategicPriorities, updatedInfo);
  }

  /**
   * Deletes one or more strategic priorities
   * @param values Object containing information of records to be deleted
   * @returns Delete response
   */
  deleteStrategicPriorities(values: object) {
    return this.httpPut(client.strategicPriorities, values);
  }

  /**
   * Get Milestones, deliverables and document for project performance
   * @param projectId selected projectid
   * @param values options
   * @returns
   */
  getProjectPerformanceDocument(projectId: string, values?): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.document}/${projectId}`, values);
  }

  /**
   * Add document to project performance
   * @param projectId selected projectid
   * @param values options
   * @returns
   */
  addProjectPerformanceDocument(projectId: string, values?): Observable<any> {
    return this.httpPost(`${client.ProjectPerformance.document}/${projectId}`, values);
  }

  /**
   * Get performance docuemnts
   * @param projectId selected projectid
   * @returns
   */
  getProjectPerformanceMilestoneDocument(projectId: string): Observable<any> {
    return this.httpGet(`${client.ProjectPerformance.document}/${projectId}`);
  }

  /**
   * remove performance docuemnts
   * @param projectId selected projectid
   * @param milestoneId selected milestone
   * @param documentId selected document
   * @returns
   */
  removeProjectPerformanceMilestoneDocument(projectId: string, milestoneId: string, documentId: string): Observable<any> {
    return this.httpDelete(`${client.ProjectPerformance.document}/${projectId}?id=${milestoneId}&documentId=${documentId}`);
  }

  /**
   * Gets list of contracts
   * @param values Table Listing Parameters
   * @returns
   */
  getContractsList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('status', values.contractStatus);
    if (!isEmpty(get(values, 'filter.consultingFirm', []))) {
      _map(values.filter.consultingFirm, (client) => {
        params.append('firmId', client);
      });
    }
    if (!isEmpty(get(values, 'filter.procurementLeader', []))) {
      _map(values.filter.procurementLeader, (leader) => {
        params.append('procurementLeaderId', leader);
      });
    }
    if (!isEmpty(get(values, 'filter.projectLeader', []))) {
      _map(values.filter.projectLeader, (leader) => {
        params.append('projectLeaderId', leader);
      });
    }
    if (!isEmpty(get(values, 'filter.type', []))) {
      _map(values.filter.type, (type) => {
        params.append('type', type);
      });
    }
    if (!isEmpty(get(values, 'filter.creationStartDate', null))) {
      params.append('creationStartDate', values.filter.creationStartDate);
    }
    if (!isEmpty(get(values, 'filter.creationEndDate', null))) {
      params.append('creationEndDate', values.filter.creationEndDate);
    }
    if (!isEmpty(get(values, 'filter.validityEndDate', null))) {
      params.append('validityEndDate', values.filter.validityEndDate);
    }
    if (!isEmpty(get(values, 'filter.validityStartDate', null))) {
      params.append('validityStartDate', values.filter.validityStartDate);
    }
    if (!isEmpty(get(values, 'filter.renewalStartDate', null))) {
      params.append('renewalStartDate', values.filter.renewalStartDate);
    }
    if (!isEmpty(get(values, 'filter.renewalEndDate', null))) {
      params.append('renewalEndDate', values.filter.renewalEndDate);
    }
    const url = `${client.contracts}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Get performance Impact details
   * @param projectId selected projectid
   * @returns
   */
  getProjectPerformanceImpact(projectId: string, fastTrackTo: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(fastTrackTo)) params.append('fastTrackTo', fastTrackTo);
    return this.httpGet(`${client.ProjectPerformance.impact}/${projectId}?${params.toString()}`);
  }

  /**
   * Get Cost Evolution
   * @param projectId selected projectid
   * @returns
   */
  getProjectPerformanceCostEvolution(projectId: string, fastTrackTo: string): Observable<any> {
    const params = new URLSearchParams();
    if (fastTrackTo == '4' || fastTrackTo == '3') params.append('fastTrackTo', fastTrackTo);
    return this.httpGet(`${client.ProjectPerformance.cost}/${projectId}?${params.toString()}`);
  }

  /**
   * Update project performance cost evolution comment
   * @param projectId selected projectid
   * @param values
   * @returns
   */
  updateProjectPerformanceCostEvolution(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.cost}/${projectId}`, values);
  }

  /**
   * Update project performance Impact details
   * @param projectId selected projectid
   * @param values
   * @returns
   */
  updateProjectPerformanceImpact(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.impact}/${projectId}`, values);
  }

  /**
   * Update Company impact details
   * @param values
   * @returns
   */
  updateCompanyImpactDetail(values): Observable<any> {
    return this.httpPost(`${client.company}/performance`, values);
  }

  /**
   * TODO: Unused API - remove once checked from BE and FE
   * New upload url
   * @param mimeType file type
   * @param uploadPath path of upload
   * @returns
   */
  getNewUploadUrl(mimeType: string, uploadPath: string) {
    return this.httpPost(user.newUploadUrl, { mimeType, uploadPath });
  }

  /**
   * Creates or Updates contract
   * @param values
   * @returns
   */
  createOrUpdateContract(values): Observable<any> {
    return this.httpPost(`${client.contracts}`, values);
  }

  /**
   * Gets information about a specific contracts
   * @param contractId Unique ID of contracts
   * @returns
   */
  getContractById(contractId: string): Observable<any> {
    const url = `${client.contracts}/${contractId}`;
    return this.httpGet(url);
  }

  /**
   * Deletes one or more contracts
   * @param values Object containing information of records to be deleted
   * @returns Delete response
   */
  deleteContracts(values: object) {
    return this.httpPut(client.contracts, values);
  }
  /**
   * Get expected impact dcf by id
   * @param projectId
   * @param id
   * @returns
   */
  getProjectPerformanceExpectedImpact(projectId: string, id: string): Observable<any> {
    return this.httpGet(`${client.ProjectPerformance.expectedImpact}/${projectId}?id=${id}`);
  }
  /**
   * Update project performance Impact details
   * @param projectId selected projectid
   * @param values
   * @returns
   */
  updateProjectPerformanceExpectedImpact(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.expectedImpact}/${projectId}`, values);
  }

  /**
   * Add project performance Impact details
   * @param projectId selected projectid
   * @param values
   * @returns
   */
  addProjectPerformanceExpectedImpact(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.ProjectPerformance.expectedImpact}/${projectId}`, values);
  }

  /**
   * Remove project performance Impact details
   * @param projectId selected projectid
   * @param values
   * @returns
   */
  removeProjectPerformanceExpectedImpact(projectId: string, id: string): Observable<any> {
    return this.httpDelete(`${client.ProjectPerformance.expectedImpact}/${projectId}?id=${id}`);
  }

  /**
   * Gets list of strategic priorities
   * @param values Table Listing Parameters
   * @returns
   */
  getLibraryList(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    if (!isEmpty(get(values, 'filter.consultingFirm', []))) {
      _map(values.filter.consultingFirm, (client) => {
        params.append('firmId', client);
      });
    }
    if (!isEmpty(get(values, 'filter.capabilityIds', []))) {
      _map(values.filter.capabilityIds, (capabiltiy) => {
        params.append('capabilityIds', capabiltiy);
      });
    }
    if (!isEmpty(get(values, 'filter.industryIds', []))) {
      _map(values.filter.industryIds, (industry) => {
        params.append('industryIds', industry);
      });
    }
    if (!isEmpty(get(values, 'filter.businessUnitIds', []))) {
      _map(values.filter.businessUnitIds, (unit) => {
        params.append('businessUnitIds', unit);
      });
    }
    if (!isEmpty(get(values, 'filter.projectIds', []))) {
      _map(values.filter.projectIds, (unit) => {
        params.append('projectIds', unit);
      });
    }
    if (!isEmpty(get(values, 'filter.confidentiality', ''))) {
      params.append('confidentiality', values.filter.confidentiality);
    }
    const url = `${client.library}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Delete the document from key docuemnt in performance
   */
  removeDocument(projectId: string, documentId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', projectId);
    params.append('documentId', documentId);
    const url = `${client.knowledgeCenter}?${params.toString()}`;
    return this.httpDelete(url);
  }

  /**
   * Delete the document from key docuemnt in performance
   */
  changeStatus(values): Observable<any> {
    return this.httpPut(client.knowledgeCenter, values);
  }

  /**
   * Gets list of meetings
   * @param values Table Listing Parameters
   * @returns
   */
  getMeetingsList(values, firmOrProjectId: string, isWorkspace: boolean, isoverallMeetingList: boolean, meetingType = 0): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    if (isWorkspace) params.append('isWorkspace', JSON.stringify(isWorkspace));
    params.append('isoverallMeetingList', JSON.stringify(isoverallMeetingList));
    params.append('meetingType', JSON.stringify(meetingType));

    if (!isEmpty(get(values, 'filter.startDate', null))) {
      params.append('startDate', values.filter.startDate);
    }
    if (!isEmpty(get(values, 'filter.endDate', null))) {
      params.append('endDate', values.filter.endDate);
    }
    if (!isEmpty(get(values, 'filter.nature', null))) {
      _map(values.filter.nature, (nature) => {
        params.append('nature', nature);
      });
    }
    if (!isEmpty(get(values, 'filter.companyAttendees', []))) {
      _map(values.filter.companyAttendees, (attendees) => {
        params.append('companyAttendees', attendees);
      });
    }
    if (!isEmpty(get(values, 'filter.consultingFirmAttendees', []))) {
      _map(values.filter.consultingFirmAttendees, (attendees) => {
        params.append('consultingFirmAttendees', attendees);
      });
    }
    const url = isoverallMeetingList ? `${client.overallMeetings}?${params.toString()}` : `${client.meetings}/${firmOrProjectId}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Creates or Updates meetings
   * @param values
   * @returns
   */
  createOrUpdateMeetings(values: MeetingForm, isWorkspace: boolean, firmOrProjectId: string): Observable<any> {
    const url = isWorkspace ? client.projectManagement.meetings.replace('{projectId}', firmOrProjectId) : `${client.meetings}/${firmOrProjectId}`;
    return this.httpPost(url, values);
  }

  /**
   * Gets information about a specific meeting
   * @param meetingId Unique ID of meeting
   * @returns
   */
  getMeetingById(meetingId: string): Observable<any> {
    const url = `${client.meeting}/${meetingId}`;
    return this.httpGet(url);
  }

  /**
   * Deletes one or more meeting
   * @param values Object containing information of records to be deleted
   * @returns Delete response
   */
  deleteMeetings(values: object, firmId: string) {
    return this.httpPut(`${client.meetings}/${firmId}`, values);
  }

  // Fetch actions of the selected firm
  getFirmActionListById(values): Observable<any> {
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    params.append('sortBy', values.sortBy);
    params.append('sortOrder', values.sortOrder);
    params.append('search', values.search);
    params.append('firmId', values.firmId);
    params.append('isPanelAction', 'true');

    if (!isEmpty(get(values, 'filter.category', []))) {
      _map(values.filter.category, (nature) => {
        params.append('category', nature);
      });
    }

    if (!isEmpty(get(values, 'filter.owner', []))) {
      _map(values.filter.owner, (owner) => {
        params.append('owner', owner);
      });
    }

    if (isNumber(get(values, 'filter.status'))) {
      params.append('status', get(values, 'filter.status'));
    }

    if (!isEmpty(get(values, 'filter.createdBy', []))) {
      _map(values.filter.createdBy, (createdBy) => {
        params.append('createdBy', createdBy);
      });
    }

    if (!isEmpty(get(values, 'filter.assignedDateStart'))) {
      params.append('assignedDate.start', get(values, 'filter.assignedDateStart'));
    }

    if (!isEmpty(get(values, 'filter.assignedDateEnd'))) {
      params.append('assignedDate.end', get(values, 'filter.assignedDateEnd'));
    }

    if (!isEmpty(get(values, 'filter.dueDateStart'))) {
      params.append('dueDate.start', get(values, 'filter.dueDateStart'));
    }

    if (!isEmpty(get(values, 'filter.dueDateEnd'))) {
      params.append('dueDate.end', get(values, 'filter.dueDateEnd'));
    }
    const url = `${user.firmAction}/${values.firmId}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Update project performance summary details
   * @param projectId Selected Project Id
   * @param values Update details
   * @returns
   */
  upadteProjectPerformanceSummary(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.summary}/${projectId}`, values);
  }

  /**
   * Gets specific information for given firm ID
   * @param firmId Unique ID of the firm
   * @returns Specific Information to be displayed in CF Profile
   */
  getSpecificInformationForFirm(firmId: string) {
    return this.httpGet(`${client.specificInformation}/${firmId}`);
  }

  /**
   * Adds specific information documents for given firm ID
   * @param data documents to be uploaded with firmId
   * @returns
   */
  addSpecificInformationDocumentsForFirm(data) {
    return this.httpPost(`${client.specificInformation}/documents`, data);
  }

  /**
   * Deletes the specific information document of the firm
   * @param firmId
   * @param documentId
   * @param type
   * @returns
   */
  deleteSpecificInformationDocumentForFirm(firmId: string, documentId: string, type: string) {
    return this.httpDelete(`firm/${firmId}/specificInformation/documents/${documentId}?typeOfDoc=${type}`);
  }

  /**
   * Get firm's location
   * @param firmId selected firmId
   * @param locationId Option value selected location id
   * @returns
   */
  getFirmLocation(firmId: string, locationId?: string): Observable<any> {
    let url = `${client.consultingFirm.location}/${firmId}`;
    locationId && (url += `?id=${locationId}`);
    return this.httpGet(url);
  }

  /**
   * Get firm's locations and legal entities with pagination
   * @param firmId selected firmId
   * @param locationId Option value selected location id
   * @returns
   */
  getFirmLocationsWithPagination(firmId: string, values): Observable<any> {
    let url = `${client.consultingFirm.location}/${firmId}`;
    const params = new URLSearchParams();
    params.append('page', values.page);
    params.append('limit', values.limit);
    return this.httpGet(`${url}?${params.toString()}`);
  }

  /**
   * Add new location for Firm
   * @param firmId selected firmId
   * @param values Address values
   * @returns
   */
  addLocationforFirm(firmId: string, values): Observable<any> {
    return this.httpPost(`${client.consultingFirm.location}/${firmId}`, values);
  }

  /**
   * Update  location for Firm
   * @param firmId selected firmId
   * @param values Address values
   * @returns
   */
  updateLocationforFirm(firmId: string, values): Observable<any> {
    return this.httpPut(`${client.consultingFirm.location}/${firmId}`, values);
  }

  /**
   * Delete  location for Firm
   * @param firmId selected firmId
   * @param locationId Removing location id
   * @returns
   */
  removeLocationforFirm(firmId: string, locationId: string): Observable<any> {
    return this.httpDelete(`${client.consultingFirm.location}/${firmId}?id=${locationId}`);
  }

  /**
   *
   * @param fileName The filename of the file to be uploaded
   * @param mimeType The MIME type of the file to be uploaded
   * @param uploadType The type of upload being done (cover/profile)
   * @returns
   */
  // getUploadUrl(fileName: string, mimeType: string, uploadType: UploadType) {
  //   return this.httpPost(user.uploadCompanyProfile, { fileName, mimeType, uploadType: uploadType.toString() });
  // }
  /**
   *
   * @param operationType The type of operation done on the profile/cover photo (upload/crop)
   * @param suffix The suffix string which will be appended to the filename of the uploaded image
   * @param image The image in File format
   * @param imageType The type of image being uploaded (cover/profile)
   * @returns Promise
   */
  // async uploadImage(profileId: string, operationType: string, suffix: string, image: File, imageType: UploadType) {
  //   const imageFileName = `${profileId}-${Date.now()}-${suffix}`;
  //   const imageUrls = get(await this.getUploadUrl(`${imageFileName}-${operationType}.png`, 'image/png', imageType).toPromise(), 'data', null);
  //   const imageUploadUrl: string = imageUrls?.uploadUrl;
  //   const imagePublicUrl: string = imageUrls?.publicUrl;
  //   if (imageUploadUrl) {
  //     try {
  //       await this.http
  //         .put<ApiResponse>(imageUploadUrl, image, { headers: { 'Content-Type': 'image/png' } })
  //         .toPromise();
  //       return imagePublicUrl;
  //     } catch (err) {
  //       this.notificationService.openErrorSnackBar('Could not upload the image');
  //       return false;
  //     }
  //   } else {
  //     this.notificationService.openErrorSnackBar('Could not upload the image');
  //     return false;
  //   }
  // }

  /**
   * Updates a suggest company details with it's unique ID
   * @param suggestCompanyDetails The updated suggest company details
   * @returns Updated Suggest Company Details object
   */
  updateSuggestCompany(suggestCompanyDetails: SuggestCompanyDetails): Observable<any> {
    return this.httpPost(`${client.improveo.suggestCompany}`, suggestCompanyDetails);
  }

  // Fetch companies to link
  fetchImproveoCompany(search: string) {
    return this.httpGet(`${client.fetchImproveoCompany}?search=${search}`);
  }

  /**
   * Gets specific information for given company ID
   * @param companyId Unique ID of the firm
   * @returns Specific Information to be displayed in CF Profile
   */
  fetchImproveoCompanyById(companyId: string): Observable<any> {
    return this.httpGet(`${client.fetchImproveCompanyInfo}/${companyId}`);
  }

  /**
   * Updates a suggest company details with it's unique ID
   * @param suggestCompanyDetails The updated suggest company details
   * @returns Updated Suggest Company Details object
   */
  updateSearchCompany(consultingFirmData: ConsultingFirm, firmId: string): Observable<any> {
    return this.httpPost(`${client.updateSearchCompany}/${firmId}`, consultingFirmData);
  }

  // Fetch company
  fetchCompanyLists(search?: string, limit?: number, page?: number, companiesFilter?: MarketExplorationFilter) {
    const params = new URLSearchParams();
    limit = limit ?? 32;
    page = page ?? 0;
    params.append('page', page.toString());
    params.append('limit', limit.toString());
    params.append('isCgn', 'true');
    if (!isEmpty(get(companiesFilter, 'industry', []))) {
      companiesFilter.industry.map((industry) => {
        if (industry !== undefined) params.append('industry', industry);
      });
    }
    if (!isEmpty(get(companiesFilter, 'capability', []))) {
      companiesFilter.capability.map((capability) => {
        if (capability !== undefined) params.append('capability', capability);
      });
    }
    if (!isEmpty(get(companiesFilter, 'region', []))) {
      companiesFilter.region.map((region) => {
        if (region !== undefined) params.append('region', region);
      });
    }
    if (!isEmpty(get(companiesFilter, 'country', []))) {
      companiesFilter.country.map((country) => {
        if (country !== undefined) params.append('country', country);
      });
    }
    if (!isEmpty(get(companiesFilter, 'city', []))) {
      params.append('city', companiesFilter?.city.join(','));
    }
    if (!isEmpty(get(companiesFilter, 'companySize', []))) {
      params.append('range', companiesFilter.companySize);
    }
    if (!isEmpty(get(companiesFilter, 'improveoDiversity', []))) {
      companiesFilter.improveoDiversity.map((diversity) => {
        if (diversity !== undefined) params.append('improveoDiversity', diversity);
      });
    }
    if (!isEmpty(get(companiesFilter, 'improveoCommunityImpact', []))) {
      companiesFilter.improveoCommunityImpact.map((communityImpact) => {
        if (communityImpact !== undefined) params.append('improveoCommunityImpact', communityImpact);
      });
    }
    return this.httpGet(`${client.improveo.companies}?search=${search}&${params.toString()}`);
  }

  //Update sow layout
  updateSowLayout(firmId, projectId, values): Observable<any> {
    const url = `${client.sow.updateLayout}/${projectId}?firmId=${firmId}`;
    return this.httpPut(url, values);
  }

  /**
   * Gets list of RFP done in user's client company
   * @param paginationValues Pagination Options
   * @param search Search text
   * @returns List of RFPs for Company
   */
  getRfpListForCompany(paginationValues: PaginationOptions, category: string, search?: string, fromLib: boolean = false) {
    const params = new URLSearchParams();
    params.append('page', `${paginationValues.page}`);
    params.append('limit', `${paginationValues.limit}`);
    params.append('fromLib', fromLib?.toString())
    params.append('category', category)
    if (search) params.append('search', search);
    const url = `${client.rfp.listForCompany}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Gets list of SOW done in user's client company
   * @param paginationValues Pagination Options
   * @param search Search text
   * @returns List of SOWs for Company
   */
  getSowListForCompany(paginationValues: PaginationOptions, category: string, search?: string, fromLib: boolean = false) {
    const params = new URLSearchParams();
    params.append('page', `${paginationValues.page}`);
    params.append('limit', `${paginationValues.limit}`);
    if (search) params.append('search', search);
    params.append('fromLib', fromLib?.toString())
    params.append('category', category)
    const url = `${client.sow.companySowList}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Determines whether the user can delete or Reset a particular SoW record
   * @param sowId Unique ID of the SoW
   * @returns Delete/Reset Permissions
   */
  canResetOrDeleteSow(projectId: string, firmId: string): Observable<any> {
    return this.httpGet(`${client.sow.resetDeleteSow}/${projectId}?firmId=${firmId}`);
  }

  /**
   * Deletes or Resets particular SOW record
   * @param sowId Unique Id
   * @returns Status of the delete operation
   */
  resetDeleteSow(projectId, firmId, type): Observable<any> {
    return this.httpDelete(`${client.sow.resetDeleteSow}/${projectId}?firmId=${firmId}&type=${type}`);
  }

  /**
   * Updates the legal entity of the firm
   * @param firmId Consulting firm Id
   * @param data Data to be updated on the legal entities
   * @returns
   */
  updateLegalEntity(firmId, data): Observable<any> {
    return this.httpPut(`/firm/${firmId}/office-location/legal-entity`, data);
  }

  createNda(ndaDetails?: SelectedFirmData): Observable<any> {
    return this.httpPost(client.nda.nda, ndaDetails);
  }

  getNdaDetails(ndaId: string) {
    const params = new URLSearchParams();
    params.append('ndaId', `${ndaId}`);
    const url = `${client.nda.nda}?${params.toString()}`;
    return this.httpGet(url);
  }

  getNdaPermission(ndaId: string) {
    const url = `${client.nda.reset}/${ndaId}`;
    return this.httpGet(url);
  }

  restNda(ndaId: string) {
    const url = `${client.nda.reset}/${ndaId}`;
    return this.httpPut(url);
  }

  getNdaTeamMember(ndaId: string, projectId: string): Observable<any> {
    return this.httpGet(`${client.nda.teamMember}/${ndaId}?firmId=${projectId}`);
  }

  getNdaTeam(ndaId: string, firmId: string): Observable<any> {
    return this.httpGet(`${client.nda.team}/${ndaId}?firmId=${firmId}`);
  }

  addNdaTeamMember(ndaId: string, values): Observable<any> {
    return this.httpPost(`${client.nda.teamMember}/${ndaId}`, values);
  }

  changeNdaMemberRole(ndaId: string, values): Observable<any> {
    return this.httpPut(`${client.nda.teamMember}/${ndaId}`, values);
  }

  removeNdaTeamMember(ndaId: string, firmId: string, userId: string): Observable<any> {
    return this.httpDelete(`${client.nda.teamMember}/${ndaId}?firmId=${firmId}&userId=${userId}`);
  }

  updateNdaLayout(ndaId, values): Observable<any> {
    const url = `${client.nda.layout}/${ndaId}`;
    return this.httpPut(url, values);
  }

  getNdaContractTerminologyTerm(ndaId: string): Observable<any> {
    return this.httpGet(`${client.nda.terminology}/${ndaId}}`);
  }

  /**
   * Gets enabled modules information for given client ID
   * @param clientId Unique ID of the client
   * @returns Enabled Modules Information
   */
  getEnabledModulesForClient(clientId: string, isAdmin = false, forceRefresh = false) {
    if (!isEmpty(this.enabledModulesForClient) && !forceRefresh) return of(this.enabledModulesForClient);
    const url = `${admin.clientEnabledModules}/${clientId}`;
    return this.httpGet(url).pipe(
      map((response) => {
        if (!isAdmin) {
          this.moduleIndices = Object.assign(
            {},
            ...AvailableModulesList.map((module, moduleIndex) => {
              return { [module.moduleId]: moduleIndex };
            })
          );
          this.enabledModulesForClient = response?.data?.moduleInfo || {};
          const allowedModuleIds = [];
          this.enabledModulesForClient.enabledModules.map((module) => {
            allowedModuleIds.push(module.moduleId);
            if (module?.children) allowedModuleIds.push(...module.children.map((childModuleId) => childModuleId));
          });
          AvailableModulesList.map((module) => {
            if (module?.path && allowedModuleIds.includes(module.moduleId)) this.allowedUrlList.push(module?.path);
            if (module?.children) {
              module.children.map((childModule) => {
                if (childModule?.path && allowedModuleIds.includes(childModule.moduleId)) this.allowedUrlList.push(childModule?.path);
              });
            }
          });
        }
        return response?.data?.moduleInfo as EnabledModuleInfo;
      })
    );
  }

  /**
   * Saves enabled modules information for given client ID
   * @param enabledModules Enabled Modules Information
   * @returns Save Response
   */
  saveEnabledModulesForClient(clientEnabledModuleInfo: EnabledModuleInfo) {
    const url = `${admin.clientEnabledModules}/${clientEnabledModuleInfo.clientId?._id}`;
    return this.httpPost(url, { enabledModules: clientEnabledModuleInfo.enabledModules });
  }

  /**
   * Determines whether a module is enabled/disabled
   * @param moduleId Parent Module Identifier
   * @param childModuleId Child Module Identifier
   */
  isModuleEnabled(moduleId: string, childModuleId?: string) {
    // check parent module enabled
    const enabledParentModule = this.enabledModulesForClient?.enabledModules?.find((module) => module.moduleId === moduleId) || null;
    if (!childModuleId) return enabledParentModule !== null;
    if (enabledParentModule) {
      const enabledChildModule = enabledParentModule?.children?.find((module) => module === childModuleId) || null;
      return enabledChildModule !== null;
    }
    return false;
  }

  createNdaMiscellaneousAndOtherClauses(ndaId, values, firmId, type): Observable<any> {
    const params = new URLSearchParams();
    params.append('firmId', firmId);
    params.append('type', type);
    const url = `${client.nda.miscellaneousAndOtherClause}/${ndaId}?${params.toString()}`;
    return this.httpPost(url, values);
  }

  updateNdaPreliminary(ndaId: string, values): Observable<any> {
    return this.httpPut(`${client.nda.preliminary}/${ndaId}`, values);
  }

  getNadPreliminary(ndaId: string, firmId?: string): Observable<any> {
    let url = `${client.nda.preliminary}/${ndaId}`;
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);
    return this.httpGet(`${url}?${params.toString()}`);
  }

  createNdaTerminologyTerm(ndaId: string, values): Observable<any> {
    return this.httpPost(`${client.nda.terminology}/${ndaId}`, values);
  }

  getNdaTerminologyTerm(ndaId: string, firmId?: string, termId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(termId)) params.append('term', termId);
    return this.httpGet(`${client.nda.terminology}/${ndaId}?${params.toString()}`);
  }

  deleteNdaTerminologyTerm(ndaId: string, termId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('term', termId);
    return this.httpDelete(`${client.nda.terminology}/${ndaId}?${params.toString()}`);
  }

  updateNdaTerminologyTerm(ndaId: string, values): Observable<any> {
    return this.httpPut(`${client.nda.terminology}/${ndaId}`, values);
  }

  /**
   * update NDA confidential information
   * @param ndaId
   * @param values
   */
  updateNdaConfidential(ndaId: string, values): Observable<any> {
    return this.httpPut(`${client.nda.confidential}/${ndaId}`, values);
  }

  /**
   * Get NDA confidential
   * @param projectId
   * @param firmId
   * @returns
   */
  getNdaConfidential(ndaId: string, firmId?: string): Observable<any> {
    let url = `${client.nda.confidential}/${ndaId}`;
    if (!isEmpty(firmId)) url += `?firmId=${firmId}`;
    return this.httpGet(url);
  }

  /**
   * update NDA obligations information
   * @param ndaId
   * @param values
   */
  updateNdaObligations(ndaId: string, values): Observable<any> {
    return this.httpPut(`${client.nda.obligations}/${ndaId}`, values);
  }

  /**
   * Get NDA obligations
   * @param projectId
   * @param firmId
   * @returns
   */
  getNdaObligations(ndaId: string, firmId?: string): Observable<any> {
    let url = `${client.nda.obligations}/${ndaId}`;
    if (!isEmpty(firmId)) url += `?firmId=${firmId}`;
    return this.httpGet(url);
  }

  /**
   * Exports/Previews NDA PDF
   * @param ndaId Unique ID of NDA
   * @param isPreview Is Preview?
   * @returns PDF Response
   */
  exportNdaPdf(ndaId: string, isPreview: boolean = false) {
    return this.pdfHttpGet(`${client.nda.generatePdf}/${ndaId}?isPreview=${isPreview}`);
  }

  /**
   * Deletes exported NDA PDF from history
   * @param ndaId Unique ID of NDA
   * @param exportId Unique ID of the export
   * @returns Delete Response
   */
  ndaDeleteExportedPdf(ndaId: string, exportId: string) {
    const params = new URLSearchParams();
    params.append('exportId', exportId);
    return this.httpDelete(`${client.nda.deleteExportHistory}/${ndaId}?${params.toString()}`);
  }

  /**
   * Signing API for NDA by client/firm
   * @param projectId Unique ID of the project
   * @param values Signature data
   * @returns
   */
  signNda(ndaId: string, values): Observable<any> {
    return this.httpPost(`${client.nda.signature}/${ndaId}`, values);
  }

  /**
   * Upload final NDA document
   * @param projectId
   * @param value
   * @returns
   */
  getUploadUrlNdaFinalDocument(ndaId: string, value: object): Observable<any> {
    return this.httpPost(`${client.nda.refDocType}/${ndaId}`, value);
  }

  /**
   * get the language based on given layout type
   */
  getLanguagesBasedOnLayout(type: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('type', type);
    return this.httpGet(`${admin.languageBasedOnLayout}?${params.toString()}`);
  }

  /**
   * Marks a given nda field as validated by client company
   * @param ndaId Selected nda ID
   * @param values Object containing validation details
   * @returns Validation API Response Data
   */
  validateNDAField(ndaId: string, payload: NdaValidationDetails): Observable<any> {
    return this.httpPost(`${client.nda.validateField}/${ndaId}`, payload);
  }

  /**
   * Marks a given nda field as validated by client company - Dynamic fields
   * @param ndaId Selected nda ID
   * @param values Object containing validation details
   * @returns Validation API Response Data
   */
  validateNdaDynamicField(ndaId: string, fieldId: string): Observable<any> {
    return this.httpPost(`${client.nda.nda}/${ndaId}/general-provisions/field/${fieldId}/validate`);
  }

  /**
   * Marks a given nda field as validated by client company - Dynamic fields
   * @param ndaId Selected nda ID
   * @param values Object containing validation details
   * @returns Validation API Response Data
   */
  validateNdaOtherProvisionsDynamicField(ndaId: string, fieldId: string): Observable<any> {
    return this.httpPost(`${client.nda.nda}/${ndaId}/other-provisions/field/${fieldId}/validate`);
  }

  /**
   * get the language details by Id
   */
  getLanguageById(id: string): Observable<any> {
    return this.httpGet(`${admin.language}/${id}`);
  }

  /**
   * Get SOW General Provisions
   * @param sowId
   * @returns
   */
  getSowProjectGeneralProvisions(sowId: string): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.generalProvisions.get}`;
    return this.httpGet(url);
  }

  /**
   * Add/Update General Provisions
   * @param sowId
   * @returns
   */
  updateGeneralProvisions(sowId: string, body: { added: SowDynamicFormsValue[]; edited: SowDynamicFormsValue[] }): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.generalProvisions.get}`;
    return this.httpPut(url, body);
  }

  /**
   * Gets SoW field comment count
   */
  getSowTabFieldComment(sowDetails) {
    const params = new URLSearchParams();
    params.append('sowTab', sowDetails.sowTabName);
    params.append('isDynamic', sowDetails.isDynamic);
    return this.httpGet(`${client.sow.commentCount}/${sowDetails.sowId}?${params.toString()}`);
  }

  /**
   * Switch isActive status
   * @param sowId
   * @returns
   */
  updateIsActiveStatus(sowId: string, body: { isActive: boolean; fieldId: string }): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.generalProvisions.active}`;
    return this.httpPut(url, body);
  }

  /**
   * Delete SOW general provision
   * @param sowId
   * @param fieldId
   * @returns
   */
  deleteGeneralProvisions(sowId: string, fieldId: string): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.generalProvisions.delete}${fieldId}`;
    return this.httpDelete(url);
  }

  /**
   * Re-order SOW general provision
   * @param sowId
   * @returns
   */
  reorderGeneralProvisions(sowId: string, body: { currentIndex: number; moveToIndex: number }): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.generalProvisions.reorder}`;
    return this.httpPut(url, body);
  }

  /**
   * Get SOW Other Provisions
   * @param sowId
   * @returns
   */
  getSowProjectOtherProvisions(sowId: string): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.otherProvisions.get}`;
    return this.httpGet(url);
  }

  /**
   * Add/Update Other Provisions
   * @param sowId
   * @returns
   */
  updateOtherProvisions(sowId: string, body: { added: SowDynamicFormsValue[]; edited: SowDynamicFormsValue[] }): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.otherProvisions.get}`;
    return this.httpPut(url, body);
  }

  /**
   * Switch isActive status
   * @param sowId
   * @returns
   */
  updateOtherProvisionsIsActiveStatus(sowId: string, body: { isActive: boolean; fieldId: string }): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.otherProvisions.active}`;
    return this.httpPut(url, body);
  }

  /**
   * Delete SOW Other provision
   * @param sowId
   * @param fieldId
   * @returns
   */
  deleteOtherProvisions(sowId: string, fieldId: string): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.otherProvisions.delete}${fieldId}`;
    return this.httpDelete(url);
  }

  /**
   * Re-order SOW Other provision
   * @param sowId
   * @returns
   */
  reorderOtherProvisions(sowId: string, body: { currentIndex: number; moveToIndex: number }): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.otherProvisions.reorder}`;
    return this.httpPut(url, body);
  }

  /**
   * Get Nda General Provisions
   * @param ndaId
   * @returns
   */
  getNDAGeneralProvisions(ndaId: string): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.generalProvisions.get}`;
    return this.httpGet(url);
  }

  /**
   * Add/Update General Provisions
   * @param ndaId
   * @returns
   */
  updateNDAGeneralProvisions(ndaId: string, body: { added: SowDynamicFormsValue[]; edited: SowDynamicFormsValue[] }): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.generalProvisions.get}`;
    return this.httpPut(url, body);
  }

  /**
   * Switch isActive status
   * @param ndaId
   * @returns
   */
  updateNDAGeneralProvisionsStatus(ndaId: string, body: { isActive: boolean; fieldId: string }): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.generalProvisions.active}`;
    return this.httpPut(url, body);
  }

  /**
   * Delete NDA general provision
   * @param ndaId
   * @param fieldId
   * @returns
   */
  deleteNDAGeneralProvisions(ndaId: string, fieldId: string): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.generalProvisions.delete}${fieldId}`;
    return this.httpDelete(url);
  }

  /**
   * Re-order NDA general provision
   * @param ndaId
   * @returns
   */
  reorderNDAGeneralProvisions(ndaId: string, body: { currentIndex: number; moveToIndex: number }): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.generalProvisions.reorder}`;
    return this.httpPut(url, body);
  }

  /**
   * Get Nda Other Provisions
   * @param ndaId
   * @returns
   */
  getNDAOtherProvisions(ndaId: string): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.otherProvisions.get}`;
    return this.httpGet(url);
  }

  /**
   * Add/Update Other Provisions
   * @param ndaId
   * @returns
   */
  updateNDAOtherProvisions(ndaId: string, body: { added: SowDynamicFormsValue[]; edited: SowDynamicFormsValue[] }): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.otherProvisions.get}`;
    return this.httpPut(url, body);
  }

  /**
   * Switch isActive status
   * @param ndaId
   * @returns
   */
  updateNDAOtherProvisionsStatus(ndaId: string, body: { isActive: boolean; fieldId: string }): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.otherProvisions.active}`;
    return this.httpPut(url, body);
  }

  /**
   * Delete NDA Other provision
   * @param ndaId
   * @param fieldId
   * @returns
   */
  deleteNDAOtherProvisions(ndaId: string, fieldId: string): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.otherProvisions.delete}${fieldId}`;
    return this.httpDelete(url);
  }

  /**
   * Re-order NDA Other provision
   * @param ndaId
   * @returns
   */
  reorderNDAOtherProvisions(ndaId: string, body: { currentIndex: number; moveToIndex: number }): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.otherProvisions.reorder}`;
    return this.httpPut(url, body);
  }

  /**
   * Fetch User List To Add as RFP Team Members
   * @returns
   */
  getRfpTeamMembers(): Observable<any> {
    return this.httpGet(client.rfp.teamMemberList);
  }

  /**
   * Gets an email preview of the sourcing invitation sent to firms
   * @param projectId Unique ID of the project
   */
  previewInviteEmail(projectId: string, data) {
    const params = new URLSearchParams();
    params.append('projectOverview', data.includedElements.projectOverview);
    params.append('projectCapability', data.includedElements.projectCapability);
    params.append('projectIndustry', data.includedElements.projectIndustry);
    params.append('projectLocation', data.includedElements.projectLocation);
    params.append('projectDates', data.includedElements.projectDates);
    return this.httpGet(`${client.sourcingInvitation.inviteEmailPreview}/${projectId}?${params.toString()}`);
  }
  /**
   * Get Nda term and terminology
   * @param ndaId
   * @returns
   */
  getTermTerminology(ndaId: string): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.nda.terminationTerm.get}`;
    return this.httpGet(url);
  }

  /**
   * Update Nda term and terminology
   * @param ndaId
   * @returns
   */
  updateTermTerminology(ndaId: string, values): Observable<any> {
    return this.httpPost(`${client.nda.nda}/${ndaId}${client.nda.terminationTerm.get}`, values);
  }

  /**
   * Get Sow Signature
   * @param sowId
   * @returns
   */
  getSignatureDetails(sowId: string): Observable<any> {
    let url = `${client.sow.sow}/${sowId}${client.sow.getSignature}`;
    return this.httpGet(url);
  }

  /**
   * Get NDA Signature
   * @param ndaId
   * @returns
   */
  getNDASignatureDetails(ndaId: string): Observable<any> {
    let url = `${client.nda.nda}/${ndaId}${client.sow.getSignature}`;
    return this.httpGet(url);
  }

  /*
   * Updates NDA information for shortlisted firm in Sourcing
   * @param projectId Project ID
   * @param firmId Firm ID
   * @param ndaId NDA ID
   * @returns Updated Sourcing Record
   */
  updateSourcingNda(projectId: string, firmId: string, contractId: string) {
    return this.httpPut(`${client.sourcingInvitation.sourcing}/updateNda/${projectId}`, { firmId, contractId });
  }

  getFirmOwnersList(firmId: string) {
    return this.httpGet(`${user.firmUsers}${firmId}`);
  }

  createOrUpdateLegalEntity(firmId: string, data) {
    return this.httpPost(`${user.legalEntity}/${firmId}`, data);
  }

  deleteLegalEntity(entityId: string) {
    return this.httpDelete(`${user.deleteLegalEntity}/${entityId}`);
  }

  approveOrRejectLegalEntity(values: object): Observable<any> {
    return this.httpPut(user.approveOrRejectLegalEntity, values);
  }

  getLegalEntityList(firmId, data) {
    const params = new URLSearchParams();
    if (data?.search) params.append('search', data?.search);
    if (data?.page) params.append('page', data?.page);
    if (data?.type) params.append('type', data?.type);
    if (data?.limit) params.append('limit', data?.limit);
    if (data?.status) params.append('status', get(data, 'status', ''));
    if (data?.clientId) params.append('companyId', data.clientId);
    return this.httpGet(`${user.legalEntity}/${firmId}?${params.toString()}`);
  }

  /**
   * Add/Update scs blog details
   * @param values blog deatils to add
   * @param isUpdate boolean value to indicate update or add blog details
   * @returns
   */
  addOrUpdateBlog(values, isUpdate = false): Observable<any> {
    return this.httpPost(`${admin.scsAcademy}?isUpdate=${isUpdate}`, values);
  }

  /**
   * Add/Update scs blog catagory details
   * @param values blog deatils to add
   * @param isUpdate boolean value to indicate update or add blog details
   * @returns
   */
  addOrUpdateCatagory(values, type = 'Add'): Observable<any> {
    return this.httpPost(`${admin.scsAcademyCatagory}?type=${type}`, values);
  }

  /**
   * Get blog catagory List
   * @param fetchQueryValues contains page and search query value
   */
  getBlogCatagoryList(fetchQueryValues): Observable<any> {
    const params = new URLSearchParams();
    params.append('search', fetchQueryValues?.search);
    params.append('page', fetchQueryValues?.page);
    params.append('type', fetchQueryValues?.type);
    params.append('limit', fetchQueryValues?.limit);

    return this.httpGet(`${admin.scsAcademyCatagory}?${params.toString()}`);
  }

  /**
   * Get blog List
   * @param fetchQueryValues contains page and search query value
   */
  getBlogList(fetchQueryValues, catagory): Observable<any> {
    const params = new URLSearchParams();
    params.append('search', fetchQueryValues?.search);
    params.append('page', fetchQueryValues?.page);
    params.append('catagory', catagory);
    return this.httpGet(`${admin.scsAcademy}?${params.toString()}`);
  }

  /**
   * reorder scs category
   */
  reorderBlogList(data: { blogCategory: string[] }) {
    return this.httpPost(user.blogCategory, data);
  }

  /**
   * Delete blog using its ID
   * @param id unique id of the blog
   * @returns
   */
  deleteBlog(id): Observable<any> {
    return this.httpDelete(`${admin.scsAcademy}/${id}`);
  }

  /**
   * Get blog using its ID
   * @param id unique id of the blob
   * @returns
   */
  getBlogById(id): Observable<any> {
    return this.httpGet(`${admin.scsAcademy}/${id}`);
  }

  /**
   * Gets Nda field comment count
   */
  getNdaTabFieldComment(ndaDetails) {
    const params = new URLSearchParams();
    params.append('ndaTab', ndaDetails.ndaTabName);
    params.append('isDynamic', ndaDetails.isDynamic);
    return this.httpGet(`${client.nda.commentCount}/${ndaDetails.ndaId}?${params.toString()}`);
  }

  /**
   * Get Nda List using Firm Id
   * @param firmId Firm Id
   * @returns
   */
  getNdaByFirmId(firmId, projectId, clientId): Observable<any> {
    return this.httpGet(`${client.nda.nda}/${firmId}?projectId=${projectId}&clientId=${clientId}`);
  }

  /**
   * Delete Documents
   * @param projectId Unique ID of the project
   * @returns
   */
  deleteSowDocuments(sowId: string): Observable<any> {
    return this.httpDelete(`${client.sow.sow}/${sowId}/document`);
  }

  /**
   * Delete Documents
   * @param projectId Unique ID of the project
   * @param values Signature data
   * @returns
   */
  deleteNDADocuments(ndaId: string): Observable<any> {
    return this.httpDelete(`${client.nda.nda}/${ndaId}/document`);
  }

  /**
   * Determines if the logged in user is a super admin user.
   * @returns Boolean value indicating the currently logged in user is super admin or not.
   */
  determineSuperAdmin(): Observable<boolean> {
    if (!this.currentUserInformation?.role) {
      return this.checkToken('validateLogin').pipe(
        map((response) => {
          return response?.user?.role === CONSTANTS.role.admin;
        })
      );
    } else {
      return of(this.currentUserInformation?.role === CONSTANTS.role.admin);
    }
  }

  /**
   * Gets list of meeting natures for the dropdown
   * @returns
   */
  getMeetingNaturesForDropDown(isWorkspace: boolean, isOverAllMeeting = false, isFilter = false): Observable<any> {
    const params = new URLSearchParams();
    params.append('isWorkspace', JSON.stringify(isWorkspace));
    params.append('isOverAllMeeting', JSON.stringify(isOverAllMeeting));
    params.append('isFilter', JSON.stringify(isFilter));
    return this.httpGet(`${user.firmMeetingNatures}?${params.toString()}`);
  }

  /**
   * Get list of meeting natures in admin settings
   * @returns
   */
  getMeetingNatures(): Observable<any> {
    return this.httpGet(`${admin.meetingNatures}`);
  }

  /**
   * Adds a new meeting nature
   * @param dataToBeAdded
   * @returns
   */
  addMeetingNature(dataToBeAdded): Observable<any> {
    return this.httpPost(`${admin.meetingNatures}`, dataToBeAdded);
  }

  /**
   * Update a meeting nature
   * @param dataToUpdate
   * @returns
   */
  updateMeetingNature(dataToUpdate): Observable<any> {
    return this.httpPut(`${admin.meetingNatures}/${dataToUpdate._id}`, dataToUpdate);
  }

  /**
   * Deletes one/many meeting natures
   * @param dataToDelete
   * @returns
   */
  deleteMeetingNatures(dataToDelete = []): Observable<any> {
    const params = new URLSearchParams();
    params.append('ids', dataToDelete.join(','));
    return this.httpDelete(`${admin.meetingNatures}?${params.toString()}`);
  }

  createAnnouncement(projectId, data): Observable<any> {
    return this.httpPost(`${client.announcement}/${projectId}`, data);
  }

  updateAnnouncement(projectId, data): Observable<any> {
    return this.httpPut(`${client.announcement}/${projectId}`, data);
  }

  getAnnouncement(projectId): Observable<any> {
    return this.httpGet(`${client.announcement}/${projectId}`);
  }

  deleteAnnouncement(projectId, id): Observable<any> {
    return this.httpDelete(`${client.announcement}/${projectId}?id=${id}`);
  }

  getActionNatures(): Observable<GetActionNature> {
    return this.httpGet(`${admin.actionNature}`);
  }

  addActionNature(dataToBeAdded): Observable<AddActionNature> {
    return this.httpPost(`${admin.actionNature}`, dataToBeAdded);
  }

  updateActionNature(dataToUpdate): Observable<any> {
    return this.httpPost(`${admin.actionNature}`, dataToUpdate);
  }

  deleteActionNatures(dataToDelete = []): Observable<any> {
    const params = new URLSearchParams();
    params.append('ids', dataToDelete.join(','));
    return this.httpDelete(`${admin.actionNature}?${params.toString()}`);
  }

  getConsultingFirmPartners(firmId): Observable<any> {
    return this.httpGet(`firm/${firmId}/partners`);
  }

  /**
   * create MSA
   */
  createMsa(msaDetails?: SelectedFirmData): Observable<any> {
    return this.httpPost(client.msa.msa, msaDetails);
  }

  /**
   * get MSA details
   */
  getMSADetails(msaId: string) {
    const url = `${client.msa.msa}/${msaId}`;
    return this.httpGet(url);
  }

  getMsaPermission(ndaId: string) {
    const url = `${client.msa.reset}/${ndaId}`;
    return this.httpGet(url);
  }

  restMsa(ndaId: string) {
    const url = `${client.msa.reset}/${ndaId}`;
    return this.httpPut(url);
  }

  /**
   *  update MSA layout
   */
  updateMSALayout(msaId, values): Observable<any> {
    const url = `${client.msa.layout}/${msaId}`;
    return this.httpPut(url, values);
  }

  /**
   *  Fetch client details
   */
  getCfClient(fetchQueryValues): Observable<any> {
    const params = new URLSearchParams();
    params.append('search', fetchQueryValues?.search);
    params.append('page', fetchQueryValues?.page);
    params.append('type', fetchQueryValues?.type);
    params.append('limit', fetchQueryValues?.limit);
    const url = `${auth.firm}/${user.client}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   * Saves client admin PDF settings
   */
  savePdfSettingsForClient(updatedPdfSettings: PdfSettings) {
    return this.httpPost(`${client.msa.pdf}`, updatedPdfSettings);
  }

  /**
   * Gets list of NDA done in user's client company
   * @param paginationValues Pagination Options
   * @param search Search text
   * @returns List of NDAs for Company
   */
  getNdaListByCompany(paginationValues: PaginationOptions, category: string, search?: string, fromLib: boolean = false) {
    const params = new URLSearchParams();
    params.append('page', `${paginationValues.page}`);
    params.append('limit', `${paginationValues.limit}`);
    if (search) params.append('search', search);
    params.append('fromLib', fromLib?.toString())
    params.append('category', category);
    const url = `${client.nda.companyNdaLayoutList}?${params.toString()}`;
    return this.httpGet(url);
  }

  getFirmProfileDetails(firmId, isConsultingPanel, tabName) {
    const params = new URLSearchParams();
    params.append('type', tabName);
    if (isConsultingPanel) params.append('consultingFirmId', 'true');
    const url = `${auth.firm}/general-info/${firmId}?${params.toString()}`;
    return this.httpGet(url);
  }

  getCurrencyList() {
    const url = client.currency.list;
    return this.httpGet(url);
  }

  updateClientCurrency(currency) {
    const url = `${client.currency.list}/${currency}`;
    return this.httpPut(url);
  }
  // To create client Admin from super admin
  addClientUser(values: object): Observable<any> {
    return this.httpPost(client.inviteUser, values);
  }

  /**
   * Create Detailed Fees
   * @param projectId selected project id
   * @param values contains firm id and fee details
   * @returns
   */
  createSowProjectDetailedFee(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.sow.termsAndConditionDetailedFee}/${projectId}`, values);
  }

  /**
   * Get Detailed Fees
   * @param projectId selected project id
   * @param feeId selected fee id
   * @param firmId selected firm id
   * @returns
   */
  getSowProjectDetailedFee(projectId: string, firmId?: string, feeId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    if (!isEmpty(feeId)) params.append('feeId', feeId);
    return this.httpGet(`${client.sow.termsAndConditionDetailedFee}/${projectId}?${params.toString()}`);
  }

  /**
   * Update Detailed Fees
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  updateSowProjectDetailedFee(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.termsAndConditionDetailedFee}/${projectId}`, values);
  }

  /**
   * Update Detailed Fees
   * @param projectId selected project id
   * @param values contains firm id and milestone details
   * @returns
   */
  updateSowProjectDetailedFeeStatus(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.sow.termsAndConditionDetailedFee}/status/${projectId}`, values);
  }

  /**
   * Delete Detailed Fees
   * @param projectId selected project id
   * @param milestoneId selected milestone id
   * @param firmId selected firm id
   * @returns
   */
  deleteSowProjectDetailedFees(projectId: string, feeId: string, firmId?: string): Observable<any> {
    const params = new URLSearchParams();
    if (!isEmpty(firmId)) params.append('firmId', firmId);

    params.append('feeId', feeId);
    return this.httpDelete(`${client.sow.termsAndConditionDetailedFee}/${projectId}?${params.toString()}`);
  }

  /**
   * get currency from management list
   */
  getAllCurrencyList() {
    return this.httpGet(client.currency.management);
  }

  /**
   * get project currency
   */
  getProjectCurrency(projectId: string): Observable<any> {
    return this.httpGet(`${client.currency.project}/${projectId}`);
  }

  /**
   * Activate or deactivate legal entity
   * @param firmId Unique firmId
   * @param legalEntityId Unique legal entity Id
   */
  activateOrDeactivateLegalEntity(firmId: string, legalEntityId: string) {
    const url = admin.activateOrDeactivateLegalEntity.replace('{firmId}', firmId).replace('{legalEntityId}', legalEntityId);
    return this.httpPut(`${url}`);
  }

  /**
   *  Get Message Preferences of loggedIn user
   */
  getMessagePreferences() {
    return this.httpGet(client.messagePreferences);
  }

  /**
   *  Updates Message Preferences of loggedIn user
   * @param messagePreferences message preferences of user
   */
  updateMessagePreferences(updatePreferences: UpdatePreferences) {
    return this.httpPut(client.messagePreferences, updatePreferences);
  }
  // To get risk in client
  getClientRisk(): Observable<any> {
    return this.httpGet(client.risk);
  }
  // To add risk in client
  saveClientRisk(values): Observable<any> {
    return this.httpPost(client.risk, values);
  }

  // To delete risk in client
  deleteClientrisk(values): Observable<any> {
    return this.httpPut(client.risk, values);
  }

  getSettingDetails(): Observable<any> {
    return this.httpGet(client.uploadSettings);
  }

  updateSettingDetails(fileLimit, id): Observable<any> {
    return this.httpPost(client.uploadSettings, { fileLimit, id });
  }

  getChatRoomByMembers(receiverId) {
    return this.httpPost(chatRoutes.conversationRoom, { receiverId });
  }

  getUploadExportLimit(): Observable<any> {
    return this.httpGet(client.exportFileSettings);
  }

  updateUploadExportLimit(fileLimit, exportLimit, cgnLimit, id): Observable<any> {
    return this.httpPost(client.exportFileSettings, { fileLimit, exportLimit, cgnLimit, id });
  }

  getTaskCategories(): Observable<any> {
    return this.httpGet(client.taskCategories);
  }

  getProviderPerformanceList(data): Observable<any> {
    const params = new URLSearchParams();
    params.append('search', data?.search);
    params.append('page', data?.page);
    params.append('type', data?.type);
    params.append('limit', data?.limit);
    params.append('sortBy', data?.sortBy);
    params.append('sortOrder', data?.sortOrder);
    if (!isEmpty(get(data, 'filter.providerName', []))) {
      _map(data.filter.providerName, (provider) => {
        if (provider !== undefined) params.append('providerId', provider);
      });
    }
    if (!isEmpty(get(data, 'filter.relationshipManager', []))) {
      _map(data.filter.relationshipManager, (user) => {
        if (user !== undefined) params.append('relationshipManager', user);
      });
    }
    params.append('meetingStartDate', get(data, 'filter.startDate', ''));
    params.append('meetingEndDate', get(data, 'filter.endDate', ''));
    params.append('globalRisk', get(data, 'filter.globalRisk', ''));
    params.append('overdueActions', get(data, 'filter,overdueActions', ''));
    return this.httpGet(`${client.providerPerformance}?${params?.toString()}`);
  }

  /**
   * This function will responsible to Trigger FE api for get registered partners of firm
   * @param values
   */
  getFirmUsersList(values): Observable<any> {
    const params = new URLSearchParams();
    if (values?.providerId) {
      params.append('providerId', values.providerId);
    }
    params.append('search', values?.search);
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    if (values?.isActive) {
      params.append('isActive', values?.isActive);
    }
    const url = `${user.firmUsersListWithPagination.replace(':id', values.providerId)}?${params.toString()}`;
    return this.httpGet(url);
  }

  /**
   *  To add or update brief meeting for project
   * */
  addBriefing(projectId: string, values): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', projectId);
    return this.httpPost(`${client.briefing}?${params.toString()}`, values);
  }

  /**
   *   To add or update brief meeting for project
   */
  getBriefingData(projectId: string, values): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', projectId);
    params.append('search', values?.search);
    params.append('page', values?.page);
    params.append('limit', values?.limit);
    params.append('sortOrder', values.sortOrder);
    params.append('sortBy', values.sortBy);
    return this.httpGet(`${client.briefing}?${params.toString()}`);
  }

  /**
   *   To send invite for brief meeting to firm
   */
  sendInviteOrUpdateData(projectId: string, value): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', projectId);
    return this.httpPut(`${client.briefing}?${params.toString()}`, value);
  }

  deleteBriefing(projectId: string, briefingId: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('projectId', projectId);
    params.append('briefingId', briefingId);
    return this.httpDelete(`${client.briefing}?${params.toString()}`);
  }

  /**To generate sow pdf */
  generateSowPdf(projectId: string, value): Observable<any> {
    return this.httpPut(`${client.sow.pdf}/${projectId}`, value);
  }
  addSelectionTeamMembers(projectId: string, values): Observable<any> {
    return this.httpPost(`${client.selectionDetails}/${projectId}/team`, values);
  }

  deleteProposalEvaluator(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.selectionDetails}/${projectId}/team`, values);
  }

  updateProposalLeadEvaluator(projectId: string, values): Observable<any> {
    return this.httpPut(`${client.selectionDetails}/LeadChange/${projectId}`, values);
  }

  enableProjectManagement(bodyData: { projectId: string; firmId: string; sowId: string; step: number }): Observable<any> {
    return this.httpPut(client.enableProjectManagement, bodyData);
  }

  markExportedVersionAsFinal(contractId: string, exportedId: string, type: string): Observable<any> {
    const params = new URLSearchParams();
    params.append('exportedId', exportedId);
    params.append('type', type);
    return this.httpPut(`${client.sow.markExportHistoryAsFinal}${contractId}?${params?.toString()}`);
  }

  sendHelp(values: { message: string }): Observable<any> {
    return this.httpPost(client.sendHelp, values);
  }

  markProjectAsCompleted(projectId: string): Observable<any> {
    return this.httpPut(`${client.ProjectPerformance.markAsCompleted}/${projectId}`);
  }

  // To get demography list for company project
  getPartnersList(): Observable<any> {
    return this.httpGet(client.meetingsPartner);
  }

  changeOnboardingStatus(consultingFirmId: string, status: string, legalentityId: string) {
    return this.httpPut(client.updateOnboardingStatus.replace('{firmId}', consultingFirmId), { status, legalentityId });
  }

  updatePMChangesTitle(changeId: string, title: string) {
    return this.httpPut(client.projectManagement.changeTitle.replace('{id}', changeId), { title });
  }

  getHistoryByFieldKey(changeId, fieldKey) {
    const params = new URLSearchParams();
    params.append('fieldKey', fieldKey);
    return this.httpGet(`${client.projectManagement.latestChange.replace('{changeId}', changeId)}?${params?.toString()}`);
  }

  updateHistoryByFieldKey(changeId, fieldKey, payload) {
    const params = new URLSearchParams();
    params.append('fieldKey', fieldKey);
    return this.httpPut(`${client.projectManagement.latestChange.replace('{changeId}', changeId)}?${params?.toString()}`, payload);
  }

  getHistoryBySectionId(changeId, sectionId, section) {
    const params = new URLSearchParams();
    params.append('section', section);
    params.append('sectionId', sectionId);
    return this.httpGet(`${client.projectManagement.latestChangeBySectionId.replace('{changeId}', changeId)}?${params?.toString()}`);
  }

  updateMilestonAndInvoicingLatestChange(changeId, fieldKey, sectionId, payload) {
    const params = new URLSearchParams();
    params.append('fieldKey', fieldKey);
    params.append('sectionId', sectionId);
    params.append('changeId', changeId);
    params.append('payload', payload);
    return this.httpPut(`${client.projectManagement.milestonAndInvoicingLatestChange.replace('{changeId}', changeId).replace('{sectionId}', sectionId)}?${params?.toString()}`, payload);
  }

  updateInvoicingLatestChange(changeId, fieldKey, sectionId, payload) {
    const params = new URLSearchParams();
    params.append('fieldKey', fieldKey);
    params.append('sectionId', sectionId);
    params.append('changeId', changeId);
    params.append('payload', payload);
    return this.httpPut(`${client.projectManagement.invoicingLatestChange.replace('{changeId}', changeId).replace('{sectionId}', sectionId)}?${params?.toString()}`, payload);
  }

  getOpenedTaskCount() {
    return this.httpGet(user.openedTask)
  }

  getHeaderModules() {
    return this.httpGet(user.headerModule)
  }

  proposalSelection(projectId: string): Observable<any> {
    return this.httpGet(client.proposalAndSelection.proposalSelection.replace('${projectId}', projectId));
  }
}
