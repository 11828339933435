<div
  class="table-section table-with-white-bg"
  [ngClass]="tableSelector"
  [class.with-pagination]="isPagination && !tableData?.isLoading && !isEmpty(tableData.data) && tableData.totalCount > 10"
>
  <table *ngIf="!tableData.isLoading && !isEmpty(tableData.data)" mat-table [dataSource]="tableData.data">
    <conpulse-web-message-popup #messagePopupComponentMenu="menuInOtherComponent"></conpulse-web-message-popup>
    <!-- Define table columns dynamically -->
    <ng-container *ngFor="let column of tableData.columns" [matColumnDef]="column.columnDef">
      <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'text-center': column.type == 'viewOnboardingData' || column.type == 'acceptRejectAction' }">
        {{ column.header | i18next }}
        <ng-container *ngIf="column.sort">
          <ng-container *ngIf="tableData.sortBy === column.columnDef; else notSorted">
            <ng-container *ngIf="tableData.sortOrder === 1; else desc">
              <img class="pointer filter-one" (click)="sort(column.columnDef)" src="assets/images/client-sort-ascending.svg" alt="asc" />
            </ng-container>
            <ng-template #desc>
              <img class="pointer filter-one" (click)="sort(column.columnDef)" src="assets/images/client-sort-decending.svg" alt="desc" />
            </ng-template>
          </ng-container>
          <ng-template #notSorted>
            <img class="pointer filter-one" (click)="sort(column.columnDef)" src="assets/images/sort.svg" alt="without sort" />
          </ng-template>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let row; let rowIndex = index" [ngClass]="column.cssSelector">
        <ng-container [ngSwitch]="column.type">
          <!-- COMMON TYPES CAN BE USED IN MULTIPLE TABLE -->
          <ng-container *ngSwitchCase="'view'" [ngTemplateOutlet]="viewRef"></ng-container>
          <ng-container *ngSwitchCase="'edit'" [ngTemplateOutlet]="editRef"></ng-container>
          <ng-container *ngSwitchCase="'icon'" [ngTemplateOutlet]="onlyIconRef"></ng-container>
          <ng-container *ngSwitchCase="'objectTransform'" [ngTemplateOutlet]="objectTransFormRef"></ng-container>
          <ng-container *ngSwitchCase="'acceptRejectAction'" [ngTemplateOutlet]="acceptRejectActionRef"></ng-container>
          <ng-container *ngSwitchCase="'status'" [ngTemplateOutlet]="statusRef"></ng-container>
          <ng-container *ngSwitchCase="'date'" [ngTemplateOutlet]="dateRef"></ng-container>
          <ng-container *ngSwitchCase="'titleCaseText'" [ngTemplateOutlet]="titleCaseRef"></ng-container>
          <ng-container *ngSwitchCase="'delete'" [ngTemplateOutlet]="deleteRef"></ng-container>
          <ng-container *ngSwitchCase="'editDelete'" [ngTemplateOutlet]="editDeleteRef"></ng-container>
          <ng-container *ngSwitchCase="'radio'" [ngTemplateOutlet]="radioRef"></ng-container>
          <ng-container *ngSwitchCase="'userName'" [ngTemplateOutlet]="userNameRef"></ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultRef"></ng-container>

          <!--  TABLE -->
          <ng-container *ngSwitchCase="'deleteInvoice'" [ngTemplateOutlet]="deleteInvoiceRef"></ng-container>
          <ng-container *ngSwitchCase="'entityApprovalStatus'" [ngTemplateOutlet]="entityApprovalStatusRef"></ng-container>
          <ng-container *ngSwitchCase="'photoName'" [ngTemplateOutlet]="photoAndNameRef"></ng-container>
          <ng-container *ngSwitchCase="'invoiceApprovalStatus'" [ngTemplateOutlet]="invoiceApprovalStatusRef"></ng-container>
          <ng-container *ngSwitchCase="'invoiceApprovalStatusProjectManagement'" [ngTemplateOutlet]="invoiceApprovalStatusProjectManagementRef"></ng-container>
          <ng-container *ngSwitchCase="'invoiceDoc'" [ngTemplateOutlet]="invoiceDocRef"></ng-container>
          <ng-container *ngSwitchCase="'invoiceDocProjectManagement'" [ngTemplateOutlet]="invoiceDocProjectManagementRef"></ng-container>
          <ng-container *ngSwitchCase="'paymentStatus'" [ngTemplateOutlet]="paymentStatusRef"></ng-container>
          <ng-container *ngSwitchCase="'paymentStatusProjectManagement'" [ngTemplateOutlet]="paymentStatusProjectManagementRef"></ng-container>
          <ng-container *ngSwitchCase="'currencyActions'" [ngTemplateOutlet]="editCurrencyRef"></ng-container>
          <ng-container *ngSwitchCase="'CurrencySymbol'" [ngTemplateOutlet]="currencySymbolRef"></ng-container>
          <ng-container *ngSwitchCase="'textWithEdit'" [ngTemplateOutlet]="textWithEditRef"></ng-container>
          <ng-container *ngSwitchCase="'viewOnboardingData'" [ngTemplateOutlet]="viewOnboardingDataRef"></ng-container>
          <ng-container *ngSwitchCase="'riskLevel'" [ngTemplateOutlet]="riskLevelRef"></ng-container>
          <ng-container *ngSwitchCase="'riskArchive'" [ngTemplateOutlet]="riskArchiveRef"></ng-container>
          <ng-container *ngSwitchCase="'riskUnArchive'" [ngTemplateOutlet]="riskUnArchiveRef"></ng-container>
          <ng-container *ngSwitchCase="'riskAction'" [ngTemplateOutlet]="riskActionRef"></ng-container>
          <ng-container *ngSwitchCase="'kysApprovalStatus'" [ngTemplateOutlet]="kysApprovalStatusRef"></ng-container>
        </ng-container>

        <!-- Onboarding legal entity approval status -->
        <ng-template #entityApprovalStatusRef>
          <!-- TODO: Add i18next -->
          <div *ngIf="row[column.key] === 'start'" class="tag tag-started">
            {{ 'Started' | i18next }}
          </div>
          <div *ngIf="row[column.key] === 'onGoing'" class="tag tag-update-required">
            {{ 'Update Required' | i18next }}
          </div>
          <div *ngIf="row[column.key] === 'change-request'" class="tag tag-changes-submitted">
            {{ 'Changes Submitted' | i18next }}
          </div>
          <div *ngIf="row[column.key] === 'done'" class="tag tag-approved">
            {{ 'Done' | i18next }}
          </div>
        </ng-template>

        <!-- kys legal entity approval status -->
        <ng-template #kysApprovalStatusRef>
          <div *ngIf="row[column.key] === 'onGoing'" class="tag tag-update-required">
            {{ 'Ongoing' | i18next }}
          </div>
          <div *ngIf="row[column.key] === 'done'" class="tag tag-approved">
            {{ 'Done' | i18next }}
          </div>
        </ng-template>

        <!-- Regular text -->
        <ng-template #defaultRef>
          {{ row[column.key] || '-' }}
        </ng-template>

        <!-- Title case text -->
        <ng-template #titleCaseRef>
          {{ (row[column.key]) || '-' }}
        </ng-template>

        <!-- Currency with text -->
        <ng-template #currencySymbolRef> {{ row[column.key] || '-' }}&nbsp;(&nbsp;{{ row['currencySymbol'] }}&nbsp;) </ng-template>

        <!-- Date -->
        <ng-template #dateRef>
          {{ (row[column.key] | date) || '-' }}
        </ng-template>

        <!-- Photo with Name -->
        <ng-template #photoAndNameRef>
          <div class="d-flex align-items-center">
            <ng-container *ngIf="row[column.key]">
              <span *ngIf="!row[column?.photoKey]; else profileImage" class="alphabet mr-1" [ngClass]="getClass(rowIndex)">
                {{ row[column.key]?.charAt(0)?.toUpperCase() }}
              </span>
              <ng-template #profileImage>
                <span>
                  <img class="profile-image mr-1" src="{{ row[column?.photoKey] }}" />
                </span>
              </ng-template>
            </ng-container>
            {{ row[column.key] || '-' }}
          </div>
        </ng-template>

        <ng-template #userNameRef>
          <div [matMenuTriggerFor]="messagePopupComponentMenu?.popMenu"
          (click)="passReceiverIdToMessagePopup(row[column.key]?._id)"
          [ngClass]="{
            disabled: currentUserInfo?._id === row[column.key]?._id || !row[column.key]?._id
          }">
            <span *ngIf="!row[column?.key]?.picture && row[column.key]?.name?.length; else profileImage" class="alphabet mr-1" [ngClass]="getClass(rowIndex)">
              {{ row[column.key]?.name.charAt(0)?.toUpperCase() }}
            </span>
            <ng-template #profileImage>
              <span *ngIf="row[column?.key]?.picture?.length">
                <img class="profile-image mr-1" src="{{ row[column?.key]?.picture }}" />
              </span>
            </ng-template>
            <span [ngClass]="{ 'text-underline': currentUserInfo?._id !== row[column.key]?._id }">{{ row[column.key]?.name || '-' }}</span>
          </div>
        </ng-template>

        <!-- Show Icon of the received mat-icon name -->
        <ng-template #onlyIconRef>
          <div>
            <i class="material-icons delete m-0 f-20">{{ row[column.key] || '-' }}</i>
          </div>
        </ng-template>

        <!-- TODO: To make this generic: Now specific to Project  -->
        <!-- View Action -->
        <ng-template #viewRef>
          <div class="mr-2 cursor">
            <mat-icon [matTooltip]="column.tip" matTooltipPosition="below" class="mat-20 view-actions m-0" (click)="viewAction(row[column.key])">
              visibility
            </mat-icon>
          </div>
        </ng-template>

        <ng-template #objectTransFormRef>
          {{ row[column?.key] | objectKey: column?.object }}
        </ng-template>

        <ng-template #viewOnboardingDataRef>
          <div class="mr-2 cursor text-center">
            <mat-icon
              [matTooltip]="column.tip | i18next"
              matTooltipPosition="below"
              class="mat-20 m-0 view-actions"
              (click)="viewOnboardingData(row[column.key.clientId], row[column.key.entityId])"
            >
              visibility
            </mat-icon>
          </div>
        </ng-template>

        <ng-template #riskLevelRef>
          <div [ngSwitch]="row[column.key]">
            <ng-container *ngSwitchCase="1">
              <span class="red-dot"></span>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <span class="amber-dot"></span>
            </ng-container>
            <ng-container *ngSwitchCase="3">
              <span class="green-dot"></span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span class="grey-dot"></span>
            </ng-container>
          </div>
        </ng-template>

        <ng-template #riskDeleteRef>
          <div class="restore mr-2 cursor" *ngIf="!row['isMandatory']">
            <i class="material-icons delete menu-delete-icon-btn" [matTooltip]="column.tip | i18next" (click)="deleteRow(row)"> delete </i>
          </div>
        </ng-template>

        <ng-template #riskActionRef>
          <div class="d-flex">
            <div class="mr-2 cursor" *ngIf="isActiveFirm; else onlyView">
              <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions" [matMenuTriggerFor]="actionMenus">more_vert</mat-icon>
              <mat-menu #actionMenus="matMenu">
                <button mat-menu-item (click)="viewAction(row)"><mat-icon class="mat-20">visibility</mat-icon>View</button>
                <ng-container *permission="{ modules: [clientModules.PROVIDER_PROFILE_L_E], access: access.edit }">
                  <button mat-menu-item (click)="editAction(row)"><mat-icon class="mat-20">edit</mat-icon>Edit</button>
                  <button mat-menu-item (click)="archiveRow(row, true)" *ngIf="!row['isMandatory']"><mat-icon class="mat-20">archive</mat-icon>Archive</button>
                </ng-container>
                <ng-container *permission="{ modules: [clientModules.PROVIDER_PROFILE_L_E], access: access.delete }">
                  <button mat-menu-item (click)="deleteRow(row)" *ngIf="!row['isMandatory']">
                    <mat-icon class="mat-20 menu-delete-icon-btn">delete</mat-icon>
                    Delete
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <ng-template #onlyView>
              <mat-icon [matTooltip]="column.tip" matTooltipPosition="below" class="mat-20 view-actions m-0" (click)="viewAction(row)"> visibility </mat-icon>
            </ng-template>
          </div>
        </ng-template>

        <ng-template #riskArchiveRef>
          <div class="restore mr-2 cursor" *ngIf="!row['isMandatory']">
            <mat-icon class="mat-20" [matTooltip]="column.tip | i18next" (click)="archiveRow(row, true)"> archive </mat-icon>
          </div>
        </ng-template>

        <!-- Regular text -->
        <ng-template #radioRef>
          {{ row[column.key] ? 'Yes' : 'No' }}
        </ng-template>

        <ng-template #deleteRef>
          <div class="restore mr-2 cursor">
            <mat-icon class="mat-20 delete menu-delete-icon-btn" [matTooltip]="column.tip | i18next" (click)="deleteRow(row[column.key])"> delete </mat-icon>
          </div>
        </ng-template>

        <ng-template #riskUnArchiveRef>
          <ng-container *permission="{ modules: [clientModules.PROVIDER_PROFILE_L_E], access: access.edit }; else onlyView">
            <div class="restore mr-2 cursor" *ngIf="isActiveFirm; else onlyView">
              <mat-icon class="mat-20 view-actions" [matTooltip]="column.tip | i18next" (click)="archiveRow(row, false)"> unarchive </mat-icon>
            </div>
          </ng-container>
          <ng-template #onlyView>
            <div class="restore mr-2 cursor pointer">
              <mat-icon [matTooltip]="'View'" matTooltipPosition="below" class="mat-20 view-actions" (click)="viewAction(row)"> visibility </mat-icon>
            </div>
          </ng-template>
        </ng-template>

        <!-- Edit Action -->
        <ng-template #editRef>
          <div class="d-flex align-items-center justify-content-center">
            <mat-icon
              class="p-1 pointer border-radius-full mat-20 view-action"
              matTooltip="{{ 'Edit' | i18next }}"
              matTooltipPosition="below"
              matTooltipClass="below"
              (click)="editAction(row)"
            >
              edit
            </mat-icon>
          </div>
        </ng-template>

        <!-- Edit Action -->
        <ng-template #editDeleteRef>
          <div class="d-flex align-items-center justify-content-center">
            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions" [matMenuTriggerFor]="editDeleteRefMenu">more_vert</mat-icon>
            <mat-menu #editDeleteRefMenu="matMenu">
              <button mat-menu-item (click)="editAction(row)"><mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}</button>
              <button mat-menu-item class="delete-action" (click)="deleteRow(row[column.key])" *ngIf="!row['isMandatory']">
                <mat-icon>delete</mat-icon>{{ 'Delete' | i18next }}
              </button>
            </mat-menu>
          </div>
        </ng-template>

        <!-- Delete Project Management invoice -->
        <ng-template #deleteInvoiceRef>
          <div *ngIf="row['paymentStatus'] !== 'paid'" class="cursor">
            <i
              [matTooltip]="'Delete Invoice Milestone' | i18next"
              matTooltipPosition="below"
              class="material-icons menu-delete-icon-btn"
              (click)="delete.emit(row)"
            >
              delete
            </i>
          </div>
        </ng-template>
        <!-- Invoice approval status -->
        <ng-template #invoiceApprovalStatusRef>
          <!-- TODO: Add i18next -->
          <div *ngIf="!row['isRequested'] && isEmpty(row['approvedBy'])" class="tag tag-open">
            {{ 'Pending' | i18next }}
          </div>
          <div *ngIf="row['isRequested'] && isEmpty(row['approvedBy'])" class="tag tag-pending">
            {{ 'Requested' }}
          </div>
          <div *ngIf="row['approvedBy']" class="tag tag-approved">
            {{ 'Approved' | i18next }}
          </div>
        </ng-template>

        <ng-template #invoiceApprovalStatusProjectManagementRef>
          <!-- TODO: Add i18next -->
          <!-- Request, Approve (Buttons) -> Pending, Approved,  -->
          <div *ngIf="!row['isRequested'] && isEmpty(row['approvedBy'])" class="invoice-status w-50 statusOpen">
            <div *ngIf="isProjectManagementFirmPartner && row['actualAmount']; else pendingStatus">
              <button
                mat-stroked-button
                color="warn"
                class="edit-button ellipse-button"
                (click)="requestToApproveInvoice.emit(row)"
                matTooltip="{{ 'Request to approve' | i18next }}"
                matTooltipPosition="above"
              >
                {{ 'Request' | i18next }}
              </button>
            </div>
            <ng-template #pendingStatus>
              {{ 'Pending' | i18next }}
            </ng-template>
          </div>
          <div
            *ngIf="isProjectManagementClientProjectLeader && row['isRequested'] && isEmpty(row['approvedBy']); else ApprovalRequestedStatus"
            class="invoice-status w-50"
          >
            <!-- Approve Button should be there -->
            <button mat-stroked-button color="warn" class="edit-button ellipse-button" (click)="approveInvoice.emit(row)">
              {{ 'Approve' | i18next }}
            </button>
          </div>
          <ng-template #ApprovalRequestedStatus>
            <div class="invoice-status tag tag-pending" *ngIf="row['isRequested'] && isEmpty(row['approvedBy'])">
              {{ 'Requested' }}
            </div>
          </ng-template>
          <div *ngIf="row['approvedBy']" class="invoice-status tag tag-approved">
            {{ 'Approved' }}
          </div>
        </ng-template>
        <ng-template #invoiceDocProjectManagementRef>
          <div class="d-flex align-items-center justify-content-between">
            <button
              mat-flat-button
              [disabled]="(row['actualAmount'] ? 'View' : ('Upload' | i18next)) === ('Upload' | i18next) ? !isProjectManagementFirmPartner : false"
              color="secondary"
              class="mr-2 medium"
              (click)="uploadInvoicing.emit(row)"
            >
              {{ row['actualAmount'] ? 'View' : ('Upload' | i18next) }}
            </button>
          </div>
          <ng-template #emptyContentRef>
            <p>-</p>
          </ng-template>
        </ng-template>

        <ng-template #paymentStatusRef>
          <div *ngIf="row['paymentStatus'] === 'paid'; else notPaid">
            <span class="tag tag-paid"> {{ row['paymentStatus'] | titlecase | i18next }}</span>
          </div>
          <ng-template #notPaid>
            <div *ngIf="row['paymentStatus'] !== 'paid' && row['approvedBy']; else emptyStatus">
              <span class="tag tag-due">{{ row['paymentStatus'] || 'due' | titlecase | i18next }}</span>
            </div>
            <ng-template #emptyStatus>
              {{ '-' }}
            </ng-template>
          </ng-template>
        </ng-template>

        <ng-template #paymentStatusProjectManagementRef>
          <div *ngIf="row['paymentStatus'] === 'paid'; else notPaid">
            <span class="tag tag-paid">
              {{ (row['paymentStatus'] | titlecase) || '-' }}
            </span>
          </div>
          <ng-template #notPaid>
            <div
              *ngIf="
                row['paymentStatus'] !== 'paid' && row['approvedBy'] && (isProjectManagementClientProjectLeader || isProjectProcurementLeader);
                else emptyStatus
              "
            >
              <button mat-stroked-button color="warn" class="edit-button ellipse-button" (click)="updatePaymentStatus.emit(row)">
                {{ 'Update Payment' | i18next }}
              </button>
            </div>
            <ng-template #emptyStatus>
              {{ '-' }}
            </ng-template>
          </ng-template>
        </ng-template>

        <ng-template #invoiceDocRef>
          <div *ngIf="row[column.key]; else emptyContentRef" class="d-flex invoice-document align-items-center justify-content-between">
            <p class="text-truncate">{{ row[column.key]?.documentName }}</p>
            <i
              [matTooltip]="'Download Project Invoice' | i18next"
              matTooltipPosition="below"
              class="material-icons delete"
              (click)="downloadDocument(row[column.key], 'projectInvoice')"
            >
              download
            </i>
          </div>
          <ng-template #emptyContentRef>
            <p>-</p>
          </ng-template>
        </ng-template>

        <ng-template #editCurrencyRef>
          <ng-container *ngIf="currencyEditId === row['_id']; else editCurrencyRef">
            <button
              class="mr-4"
              color="secondary"
              mat-button
              (click)="currencyEditId = null; row['conversionValue'] = currencyEditValue"
              [disabled]="isCurrencyUpdating"
            >
              {{ 'cancel' | i18next }}
            </button>
            <button
              cdkFocusInitial
              color="primary"
              [disabled]="(row['conversionValue'] && row['conversionValue'] === currencyEditValue) || isCurrencyUpdating"
              (click)="updateCurrencyDetails(row[column.key], row['conversionValue'])"
              mat-stroked-button
            >
              {{ 'Update' | i18next }}
            </button>
          </ng-container>
          <ng-template #editCurrencyRef>
            <div class="d-flex align-items-center justify-content-end">
              <div class="cursor mr-1" *ngIf="currencyEditId === null && row['showEdit']">
                <i
                  [matTooltip]="'Edit Currency Conversion' | i18next"
                  matTooltipPosition="below"
                  class="material-icons delete"
                  (click)="editCurrency(row[column.key], row['conversionValue'])"
                >
                  edit
                </i>
              </div>
              <div class="cursor">
                <i
                  [matTooltip]="'Delete Currency Conversion' | i18next"
                  matTooltipPosition="below"
                  class="material-icons menu-delete-icon-btn"
                  (click)="deleteRow(row[column.key])"
                >
                  delete
                </i>
              </div>
            </div>
          </ng-template>
        </ng-template>
        <ng-template #textWithEditRef>
          <ng-container *ngIf="currencyEditId === row['_id']; else onlyTextRef">
            <mat-form-field class="mb-0">
              <input matInput type="number" name="conversion" [(ngModel)]="row[column.key]" #conversion="ngModel" required />
              <mat-error *ngIf="conversion.hasError('required')">{{ 'Required' | i18next }}</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-template #onlyTextRef>
            {{ row[column.key] || '-' }}
          </ng-template>
        </ng-template>

        <ng-template #statusRef>
          <span class="tag" [ngClass]="column.statusClass[row[column.key]]">
            {{ row[column.key] | objectKey: column?.statusArray }}
          </span>
        </ng-template>

        <ng-template #acceptRejectActionRef>
          <div class="d-flex align-items-center justify-content-center text-center accept-reject-icons">
            <ng-container *ngIf="row?.status === column?.pendingStatusKey">
              <button mat-icon-button class="action-icon cursor approve-icon mr-1 status-button" (click)="approveOrRejectRequest(row, true)">
                <mat-icon>check</mat-icon>
              </button>
              <button mat-icon-button class="action-icon cursor reject-icon status-button" (click)="approveOrRejectRequest(row)">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Render table columns -->
    <tr mat-header-row *matHeaderRowDef="tableData.displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableData.displayedColumns"></tr>
  </table>
  <ng-container *ngIf="tableData.isLoading">
    <div class="loader">
      <div *ngFor="let i of [1, 2, 3, 4, 5]; let j = index; let first = first" class="skeleton-table">
        <span class="">&nbsp;</span>
        <span class="">&nbsp;</span>
        <span class="skeleton-loader skeleton-title w-25">&nbsp;</span>
        <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
        <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
        <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
        <span class="skeleton-loader skeleton-title w-12">&nbsp;</span>
      </div>
    </div>
  </ng-container>
  <div class="bg-white p-12 text-center border-radius-6 h-100" *ngIf="!tableData.isLoading && isEmpty(tableData.data)">
    <div class="d-flex align-items-center justify-content-center flex-column h-100">
      <img class="vector-image" [src]="notDataFoundImage" alt="no data image" />
      <p class="nodata-title font-Jakarta m-t-20">{{ 'No Records' | i18next }}</p>
      <p class="nodata-summary mw-400 m-t-12">{{ "SORRY! There Isn't Any Record To Display!" | i18next }}</p>
    </div>
  </div>
</div>
<div class="m-t-12" *ngIf="isPagination && !tableData?.isLoading && !isEmpty(tableData.data) && tableData.totalCount > 10">
  <mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    [length]="tableData.totalCount"
    [pageSize]="tableData.limit"
    showFirstLastButtons
    [pageIndex]="tableData.page"
    (page)="handlePageEvent($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
